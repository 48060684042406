import { useMemo } from "react";


import styles from './MessagesView.module.scss';
import { Flex } from "antd";
import { formatDateTime } from "../../lib/format";
import { useAuth } from "../../hooks/auth";

function MessageContext({ message, contextObjects }) {
  const object = useMemo(() => contextObjects?.find(o => o.id === message.on), [message, contextObjects]);

  const [type, label] = useMemo(() => {
    switch (true) {
      case message.on.startsWith('task-'):
        return ['task', 'la tâche'];
      case message.on.startsWith('prj-'):
        return ['project', 'le projet'];
      case message.on.startsWith('agcy-'):
        return ['agency', 'l\'agence'];
      case message.on.startsWith('slot-'):
        return ['slot', 'le créneau'];
      default:
        return [null, null];
    }
  }, [message]);

  return <div className={styles.messageContext}>
    <Flex gap="small">
      Concernant {label} {object?.name}
    </Flex>
  </div>
}

export default function Message({ message, showContext, contextObjects, owner }) {
  const lines = useMemo(() => message.content.split('\n'), [message.content]);
  return <div className={styles.message + ' ' + (owner ? styles.messageOwned : '')}>
    <Flex gap="small" align={"flex-start"}>
      <div>
        <Flex gap={5} align='center' justify="space-between">
          <span className={styles.messageAuthor}>{message.by_name || message.by}</span>
          <span className={styles.messageDate}>{formatDateTime(message.date)}</span>
        </Flex>
        {showContext && <MessageContext message={message} contextObjects={contextObjects} />}
        <span className={styles.messageContent}>{lines.map((ln, i) => <div key={i}>{ln}</div>)}</span>
      </div>
    </Flex>
  </div>
}

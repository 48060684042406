import { DatePicker, Divider, Flex, Form, Input, Rate, Segmented } from "antd"

import SlotStatusLabel from "../slots/SlotStatusLabel"
import { TaskInput } from "../tasks/TaskInput"
import { useEffect, useMemo } from "react"
import TaskDurationUnitLabel from "../tasks/TaskDurationLabel"


export default function PlanningResourceSlotForm({ form, slot, slots, tasks, projects }) {
  const type = Form.useWatch("type", { form })
  const task_id = Form.useWatch("task_id", { form })
  const task = useMemo(() => tasks.find(t => t.id === task_id), [task_id, tasks])
  const project = useMemo(() => projects?.find(p => p.id === task?.project_id), [task, projects])

  const slotsForTask = useMemo(() => slots.filter(s => s.task_id === task_id && s.status !== "cancelled"), [slots, task_id])

  const durationPlanned = useMemo(() => {
    return slotsForTask.reduce((acc, s) => {
      return acc + s.duration
    }, 0)
  }, [slotsForTask])

  const durationInHours = useMemo(() => {
    if (!task) {
      return 0
    }
    return task?.unit === 'J' ? Math.ceil(task.duration * 7) : task.duration
  }, [task])

  const remainingDuration = Math.max(0, durationInHours - durationPlanned)

  const remainingDurationInUnit = task?.unit === 'J' ? Math.round(10 * remainingDuration / 7) / 10 : remainingDuration

  useEffect(() => {
    if (!slot) {
      return
    }
    if (!slot.duration) {
      let hours = remainingDuration
      form.setFieldValue('duration', Math.min(7, hours || 7))
    }
  }, [form, remainingDuration, slot])
  const fullDay = type === "task"
  const unavailablity = type === "unavailablity" || slot?.status === "unavailable"
  return <Form
    form={form}
    labelCol={{ span: 6 }}>
    {task ? <>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
          {/* <SlotStatusLabel status={slot?.status} /> */}
        </Flex>
        <div className="form-text" style={{ fontWeight: 'bold' }}>
          {project?.name}
        </div>
        {task?.description && <div className="form-text" style={{ maxHeight: 200, overflow: 'auto' }}>
          {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
        </div>}
        <div>
          {task?.duration && <div className="form-text">
            Durée estimée: <b>{task.duration} <TaskDurationUnitLabel value={task.unit} plural={task.duration > 1} /></b>
          </div>}
          {task?.duration && <div className="form-text">
            Durée restante à planifier: <b>{remainingDurationInUnit} <TaskDurationUnitLabel value={task.unit} plural={remainingDurationInUnit > 1} /></b>
          </div>}
        </div>
        {/* <div>
          <div className="form-text">
            Créneaux déja planifiés pour cette tache:
          </div>
        </div> */}
      </Form.Item>
      <Divider />
    </> : null}
    {slot?.status !== "unavailable" && <Form.Item label="Type" name="type">
      <Segmented options={[
        { value: 'task', label: 'Tâche' },
        { value: 'time', label: 'Planification' },
        ...(slot.task_id ? [] : [{ value: 'unavailablity', label: 'Indisponibilité' }]),
      ]} />
    </Form.Item>}
    {!fullDay && <Form.Item label="Date" name="dates" rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
      <DatePicker.RangePicker format={"DD/MM/YYYY HH:mm"} showTime={{ format: 'HH:mm', minuteStep: 15 }} />
    </Form.Item>}
    {fullDay && <>
      <Form.Item label="Date" name={["dates", 0]} rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
        <DatePicker format={"DD/MM/YYYY"} />
      </Form.Item>
    </>}
    {!unavailablity && <Form.Item label={"Tâche"} name="task_id" rules={[{ required: true, message: "Veuillez spécifier une tâche affectée" }]}>
      <TaskInput tasks={tasks} />
    </Form.Item>}
    {fullDay && <>
      <Form.Item label="Durée estimée" name="duration" rules={[{ required: true, message: "Veuillez spécifier une durée" }]}>
        <Input type="number" suffix="Heures" style={{ maxWidth: 130 }} />
      </Form.Item>
    </>
    }

    {/* <Divider />

    <Form.Item label="Répétition" name="repetition" valuePropName="repeat_duration">
      <Input type="number" suffix="Semaines" />
    </Form.Item> */}
  </Form>
}

import { useNavigate, useParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { resourceTypes, useResource, useResourceForm } from '../../hooks/resources';
import { notifyError, notifySuccess } from '../../services/notification';
import { ControlBar } from '../../components/controlbar/ControlBar';
import { formatPrestationUnit, prestationLevels, prestationUnits, usePrestations } from '../../hooks/prestations';
import { PrestationsInput } from '../../components/prestations/PrestationsInput';
import PriceInput from '../../components/inputs/PriceInput';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DayCountInput from '../../components/inputs/DayCountInput';
import { useResourcePrestationForm, useResourcePrestationsForm } from '../../hooks/resource-prestations';
import { Button, Flex, Form, Input, InputNumber, Select, Space, Table, Tooltip, message } from 'antd';
import { formatCurrency } from '../../lib/format';
import { PrestationInput } from '../../components/prestations/PrestationInput';
import { info } from 'sass';
import { InfoCircleTwoTone } from '@ant-design/icons';

const EditableContext = createContext(null);

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';


function EditableRow({ index, ...props }) {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};


function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputRenderer,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      // rules={[
      //   {
      //     required: true,
      //     message: `${title} is required.`,
      //   },
      // ]}
      >
        {inputRenderer
          ? inputRenderer({ ref: inputRef, save, record })
          : <Input ref={inputRef} onPressEnter={save} onBlur={save} />}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

export default function ResourcePrestations() {

  const { resourceId } = useParams();
  const [prestationList] = usePrestations();
  const [messageApi, messageContextHolder] = message.useMessage();

  const {
    items, addPrestation, deletePrestation, updatePrestation,
    saveResourcePrestations,
  } = useResourcePrestationsForm(resourceId || '');

  const prestationFilter = useMemo(() => {
    const existing = new Set(items.map(p => p.prestation_id))
    return (p) => {
      return !existing.has(p.id)
    }
  }, [items])

  const itemsWithPrestation = useMemo(() => {
    return items.map(p => {
      const prestation = prestationList.find(prestation => prestation.id === p.prestation_id)
      if (!prestation) {
        return p
      }
      return {
        ...p,
        key: p.prestation_id,
        code: prestation.code,
        name: prestation.name,
        unit: prestation.unit,
      }
    })
  }, [items, prestationList])



  const onFinish = () => {
    (async () => {
      try {
        await saveResourcePrestations(items);
        messageApi.success('Enregistré');
      } catch (err) {
        messageApi.error(err);
      }
    })();
  };


  const onAddPrestation = (id) => {
    console.log('onAddPrestation', id)
    const prestation = prestationList.find(p => p.id === id)

    addPrestation({
      prestation_id: prestation.id,
      level: 1,
    })
  }

  const onRemovePrestation = (prestation) => {
    console.log('onRemovePrestation', prestation)
    deletePrestation(prestation)
  }

  const onSaveRow = (row) => {
    updatePrestation(row)
  };

  let columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 120,
      sorter: (a, b) => String(a.name).localeCompare(b.name),
    },
    {
      title: 'Nom',
      dataIndex: 'name',
      sorter: (a, b) => String(a.name).localeCompare(b.name),

    },
    {
      title: 'Compétence',
      dataIndex: 'level',
      width: 130,
      editable: true,
      render: (level) => {
        const { label } = prestationLevels.find(l => l.value === level)
        return <div style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{label}</div>
      },
      inputRenderer: ({ ref, save }) => {
        return (
          <Select options={prestationLevels} ref={ref} onChange={save} />
        )
      }
    },
    {
      title: () => <div>Prix d'achat</div>,
      dataIndex: 'purchase_price',
      width: 120,
      editable: true,
      render: (price, { unit }) => {
        return <div style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{price ? formatCurrency(price) + '/' + formatPrestationUnit(unit) : ' – '}</div>
      },
      inputRenderer: ({ ref, save, record }) => {
        return (
          <InputNumber suffix={"€/" + formatPrestationUnit(record.unit)} ref={ref} onPressEnter={save} onBlur={save} style={{ minWidth: 120 }} />
        )
      }
    },
    {
      title: () => <div>Prix de vente <Tooltip title="Ce tarif est utilisé dans le calcul de la planification en mode forfait"><InfoCircleTwoTone /></Tooltip></div>,
      dataIndex: 'price',
      width: 120,
      editable: true,
      render: (price, { unit }) => {
        return <div style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>{price ? formatCurrency(price) + '/' + formatPrestationUnit(unit) : ' – '} </div>
      },
      inputRenderer: ({ ref, save, record }) => {
        return (
          <InputNumber suffix={"€/" + formatPrestationUnit(record.unit)} ref={ref} onPressEnter={save} onBlur={save} style={{ minWidth: 120 }} />
        )
      }
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 120,
      render: (_, record) => {
        return (
          <button type="button" className="btn btn-sm btn-danger" onClick={() => onRemovePrestation(record)}>Supprimer</button>
        )
      },
    },
  ]

  columns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: onSaveRow,
        inputRenderer: col.inputRenderer,
      }),
    };
  });


  return (
    <Form onFinish={onFinish}>
      {messageContextHolder}
      <fieldset className="box">
        <legend>Prestations</legend>
        <Flex vertical gap="small" >
          <PrestationInput
            value={null}
            onChange={onAddPrestation}
            valueFilter={prestationFilter}
            placeholder="Ajouter une prestation" />

          <Table
            bordered
            rowClassName={() => 'editable-row'}
            dataSource={itemsWithPrestation}
            rowKey="prestation_id"
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            columns={columns} />
        </Flex>


      </fieldset>

      <ControlBar>
        <div></div>
        <div>
          <Button type="primary" htmlType="submit" >Enregistrer</Button>
        </div>
      </ControlBar>

    </Form >
  );
}

/* eslint-disable import/prefer-default-export */
import { useCallback, useEffect, useState } from 'react';
import EventEmitter from '../lib/event-emitter';

const invalidators = new Map();

class Invalidator {
  emitter = new EventEmitter();

  invalidate(id) {
    this.emitter.emit({ id });
  }
}

function getInvalidator(name) {
  if (!invalidators.has(name)) {
    invalidators.set(name, new Invalidator());
  }
  return invalidators.get(name);
}

// export function pushInvalidation(name, id = undefined) {
//   getInvalidator(name).invalidate(id);
// }

export function useInvalidationService(name, id = undefined) {
  const [counter, set] = useState(0);
  useEffect(() => {
    const expectedId = id;
    return getInvalidator(name).emitter.addListener(({ id }) => {
      if ((expectedId && expectedId === id) || !expectedId) {
        set(counter + 1);
      }
    });
  }, [name, id, counter]);
  const invalidate = useCallback(() => {
    // console.log('invalidate', name, id);
    getInvalidator(name).invalidate(id);
  }, [name, id]);
  return [counter, invalidate];
}

// export function useInvalidatorService() {
//   const emitter = new EventEmitter();
//   const counters = new Map();
//   return {
//     invalidate: (id) => {
//       if (id) {
//         counters.set(id, (counters.get(id) || 0) + 1);
//       }
//       counters.set('', (counters.get('') || 0) + 1);
//     },
//     getInvalidationCounter(id = '') {
//       return counters.get(id) || 0;
//     },
//   };
// }

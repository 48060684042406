import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/warehouses');
const { invalidateCache, withCache } = cache();

function mapFromWarehouseModel(input) {
  return { ...input };;
}

function mapToWarehouseModel(input) {
  return { ...input };;
}


export async function listWarehouses() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  }, '')
  return items ? items.map(mapToWarehouseModel) : [];
}

export async function getWarehouse(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Warehouse not found');
    }
    return item;
  }, id)

  return mapToWarehouseModel(item);;
}

export async function createWarehouse(warehouse) {
  const { item } = await api.post('', { item: mapFromWarehouseModel(warehouse) });
  if (item) {
    invalidateCache(item.id)
    return mapToWarehouseModel(item)
  }
  return undefined;
}

export async function updateWarehouse(warehouse) {
  const res = await api.put('', { item: mapFromWarehouseModel(warehouse) });
  invalidateCache(warehouse.id)
  return res ? warehouse : undefined;
}

export async function removeWarehouse(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}


// export async function listWarehouseComments(resId) {
//   const { items } = await api.get(`${resId}/comments`);
//   return items;
// }

// export async function addWarehouseComment(resId, comment) {
//   const { success } = await api.post(`${resId}/comments`, { content: comment });
//   return success;
// }
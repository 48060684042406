
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { taskExpectedLevels, taskPriorities, taskStatuses, taskUnits, useTask, useTaskForm } from '../../hooks/tasks';
import { notifyError, notifySuccess } from '../../services/notification';
import { ControlBar } from '../../components/controlbar/ControlBar';
import { prestationLevel, prestationLevels } from '../../hooks/prestations';
import DayCountInput from '../../components/inputs/DayCountInput';
import PriceInput from '../../components/inputs/PriceInput';
import DateInput from '../../components/inputs/DateInput';
import { ProjectInput, ProjectsInput } from '../../components/projects/ProjectInput';
import { PrestationInput } from '../../components/prestations/PrestationInput';
import { Button, DatePicker, Flex, Form, Input, InputNumber, Progress, Select, Space, Steps, Switch, message } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { TaskInput } from './TaskInput';
import TaskTypeLabel from '../../components/tasks/TaskTypeLabel';
import ChangeLogDrawer from '../../components/changelog/ChangeLogDrawer';
import TaskMessagesView from '../../components/tasks/TaskMessagesView';
import Link from '../../components/link/Link';
import ProjectLabel from '../../components/projects/ProjectLabel';
import { useAuth } from '../../hooks/auth';
import { AgencyInput } from '../../components/agencies/AgencyInput';


export default function TaskForm() {
  const [form] = Form.useForm();
  const { can } = useAuth();
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState({ position: 0 });

  const parent = searchParams.get("parent");
  const type = searchParams.get("type");

  const {
    task, saveTask, deleteTask, isNewTask, changes,
  } = useTask(taskId || '', { parent, type });

  const { task: parentTask } = useTask(parent || undefined);


  useEffect(() => {
    if (task) {
      const values = {
        ...task,
        min_date: task.min_date ? new dayjs(task.min_date) : undefined,
        max_date: task.max_date ? new dayjs(task.max_date) : undefined,
      }
      if (parentTask && parentTask.id) {
        values.project_id = parentTask.project_id;
        values.parent_task_id = parentTask.id;
        values.prestation_id = parentTask.prestation_id;
        // values.previous_task_ids = [parentTask.id];
      }
      form.setFieldsValue(values);

      switch (task.status) {
        case "unplanned":
          setStep({ position: 0 });
          break;
        case "inplanning":
          setStep({ position: 1 });
          break;
        case "planned":
          setStep({ position: 2 });
          break;
        case "blocked":
          setStep({ position: 3, status: "error" });
          break;
        case "canceled":
        case "cancelled":
          setStep({ position: 3, status: "error" });
          break;
        case "complete":
          setStep({ position: 3 });
          break;
        default:
          break;
      }
    }
  }, [form, task, parentTask]);


  if (!task) {
    return <div>Not found</div>;
  }


  const onSubmit = (values) => {
    (async () => {
      try {
        const item = await saveTask({
          ...task,
          ...values,
          min_date: values.min_date ? values.min_date.toISOString() : undefined,
          max_date: values.max_date ? values.max_date.toISOString() : undefined,
        });
        message.success('Enregistré');
        if (item) {
          navigate('/tasks');
        }
      }
      catch (err) {
        message.error(`Erreur: ${err}`);
      }
    })();

  };

  const onDelete = () => {
    if (taskId) {
      (async () => {
        await deleteTask();
        message.success('Supprimé');
        navigate('/tasks');
      })();
    }
  };

  const onCloseTask = () => {
    if (taskId) {
      (async () => {
        const item = await saveTask({
          ...task,
          close: true
        });
        message.success('Clôturé');
        if (item) {
          navigate('/tasks');
        }
      })();
    }
  }

  const editable = can('task.update');
  const generated = task.order_number;

  return (<>
    <Steps
      style={{ marginBottom: 30 }}
      current={step.position}
      items={[
        {
          title: 'A planifier',
        },
        {
          title: 'Planification',
          description: step.position === 1 ? <Progress size="small"
            percent={Math.round(task.planned_percent)}
          /> : null,
        },
        {
          title: 'Planifié',
        },
        {
          title: 'Terminé',
        },
      ]}
    />
    <Flex gap="large">
      <Form
        form={form}
        style={{ flex: 1 }}
        onFinish={onSubmit}
        labelCol={{ span: 6 }}
        wrapperCol={{ flex: 1 }}
        labelWrap
        disabled={!editable}
      >

        <fieldset className="box">
          <legend>Informations</legend>
          <Form.Item label="Nom" name="name" rules={[{ required: true }]}>
            <Input disabled={generated} />
          </Form.Item>
          <Form.Item label="Type">
            <span className="ant-form-text">
              <TaskTypeLabel value={task.type} disabled={generated} />
            </span>
          </Form.Item>
          <Form.Item label="Prestation" name="prestation_id" rules={[{ required: true }]}>
            <PrestationInput disabled={false && generated} />
          </Form.Item>
          {task.parent_task_id ?
            <Form.Item label="Tâche parente" name="parent_task_id">
              <TaskInput allowClear={true}
                projectId={form.getFieldValue("project_id")}
                disabled
                variant="borderless" />
            </Form.Item>
            : null}
          <Form.Item label="Compétence" name="expected_level"
            rules={[{ required: true }]}
            help={
              <div className="form-text">
                Niveau de compétence attendu
                <ul>
                  <li><b>Basique:</b> requiert des connaissances de bases pour être effectuée</li>
                  <li><b>Avancé:</b> requiert des connaissances approfondies de la prestation</li>
                  <li><b>Expert:</b> requiert une expertise très avancé de la prestation</li>
                </ul>
              </div>
            }>
            <Select options={prestationLevels} disabled={generated} />
          </Form.Item>
          <Form.Item label="Agence" name="agency_id" rules={[{ required: false }]}>
            <AgencyInput disabled={generated} allowClear={true} />
          </Form.Item>
          <Form.Item label="Affaire" name="project_id" rules={[{ required: true }]}>
            {isNewTask ? <ProjectInput disabled={generated} /> : <ProjectLabel id={task.project_id} />}
          </Form.Item>
          {task.order_id ?
            <Form.Item label="Commande">
              <Link to={`/orders/${task.order_id}`}>{task.order_number} {task.order_item_number ? ` / ${task.order_item_number}` : null}</Link>
            </Form.Item> : null}
          <Form.Item label="Tâches précédentes" name="previous_task_ids" help={"Taches devant etre réalisé avant celle-ci"}>
            <TaskInput allowClear={true} projectId={form.getFieldValue("project_id")} multiple />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input.TextArea disabled={generated} />
          </Form.Item>

          <Form.Item label="Coût" name="cost">
            <InputNumber suffix="€" min={0} disabled={generated} />
          </Form.Item>

          {/* <Form.Item label="Durée" name="duration">
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item label="Unité" name="unit">
          <Select defaultValue="d" style={{ maxWidth: 90 }}>
            <Select.Option value="d">jours</Select.Option>
            <Select.Option value="h">heures</Select.Option>
          </Select>
        </Form.Item> */}

          <Form.Item label="Durée">
            <Space.Compact>
              <Form.Item
                name="duration"
                noStyle
              >
                <InputNumber min={0} disabled={generated} />
              </Form.Item>
              <Form.Item label="Unité" name="unit" noStyle>
                <Select defaultValue="d" style={{ maxWidth: 90 }} options={taskUnits} disabled={generated} ></Select>
              </Form.Item>
            </Space.Compact>
          </Form.Item>

        </fieldset>


        <fieldset className="box">
          <legend>Planification</legend>
          {/*         
        <div className="mb-3">
          <label htmlFor="inputPreviousTasks" className="form-label">Tâches précédentes</label>
          <input
            type="text"
            id="inputPreviousTasks"
            aria-describedby="previousTasksHelp"
            {...register('previousTasks', { className: "form-control" })}
          />
          {displayError('previousTasks')}
        </div>

        <div className="mb-3">
          <label htmlFor="inputRequirements" className="form-label">Prérequis</label>
          <input
            type="text"
            id="inputRequirements"
            aria-describedby="requirementsHelp"
            {...register('requirements', { className: "form-control" })}
          />
          {displayError('requirements')}
        </div> */}

          <Form.Item label="Date minimum" name="min_date">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>

          <Form.Item label="Date maximum" name="max_date">
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>


          <Form.Item label="Priorité" name="priority">
            <Select options={taskPriorities} />
          </Form.Item>


        </fieldset>



      </Form >
      {task.id ?
        <fieldset className='' style={{ maxWidth: 300 }}>
          <TaskMessagesView taskId={task.id} />
        </fieldset> : null}
    </Flex>

    <ControlBar>
      <Flex align='center' gap="small">
        {can('task.delete') && <DeleteButton onDelete={onDelete} />}
        {false && task?.status !== "complete" &&
          <Button
            onClick={() => onCloseTask()}
            danger
          >Clôturer</Button>}
      </Flex>
      <div></div>
      <Space>
        <ChangeLogDrawer changes={changes} />
        {editable && <Button type="primary" size='large'
          onClick={() => form.submit()}>Enregistrer</Button>}
      </Space>
    </ControlBar>
  </>);
}

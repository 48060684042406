import { mdiAccountBox, mdiAccountHardHatOutline, mdiAlphaT, mdiAnimationOutline, mdiChatQuestion, mdiCheck, mdiCurrencyEur, mdiCurrencyEurOff, mdiCursorText, mdiDrawing, mdiMagnify, mdiPlus, mdiProgressWrench, mdiSelectGroup, mdiStoreOutline, mdiTextSearchVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { AutoComplete, Button, Divider, Flex, Input, Popover, Select, Space, Tag, Tooltip } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { indexBy } from "../../lib/utils";
import { usePersistentState } from "../../hooks/state";
import { sanitizeString } from "../../hooks/search";
import ProjectShortCode from "../projects/ProjectShortCode";
import { projectInvoicingModes, projectTypes } from "../../hooks/projects";
import { set } from "date-fns";


function asIconAndColor(type, iconAndColorProps) {
  switch (type) {
    case "agency":
      return { icon: mdiStoreOutline, color: "blue" };
    case "project":
      return { icon: mdiSelectGroup, color: "green" };
    case "projectType":
      return { icon: mdiAlphaT, tagColor: "cyan", color: "#08979C" };
    case "projectInvoicingMode":
      return { icon: mdiCurrencyEur, color: "magenta" };
    case "resource":
      return { icon: mdiAccountHardHatOutline, color: "purple" };
    case "task":
      return { icon: mdiDrawing, color: "orange" };
    case "prestation":
      return { icon: mdiProgressWrench, color: "#d48806" };
    case "order":
      return { icon: mdiAnimationOutline, color: "lime" };
    case "thirdparty":
      return { icon: mdiAccountBox, color: "volcano" };
    case "text":
      return { icon: mdiMagnify, color: "geekblue" };
    default:
      const res = (iconAndColorProps && iconAndColorProps(type)) || {};
      return { icon: null, color: "grey", ...res };
  }
}


function TagRenderer({ label, type, closable, iconAndColorProps, onClose }) {
  const { icon, color, tagColor } = asIconAndColor(type, iconAndColorProps);
  return <Tag
    color={tagColor || color}
    // onMouseDown={onPreventMouseDown}
    closable={closable}
    onClose={onClose}
    style={{ marginRight: 3 }}
    icon={icon ? <Icon path={icon} size={0.6} style={{ marginRight: 3, marginTop: -2 }} /> : null}
  >
    {label}
  </Tag>
}


function OptionRenderer({ data, iconAndColorProps }) {
  const { type, label } = data;
  const { icon, color, optionColor } = asIconAndColor(type, iconAndColorProps);
  return <span style={{ color }}>
    {icon && <Icon path={icon} size={0.6} color={optionColor || color} style={{ marginRight: 3, marginTop: -2 }} />}
    {label}
  </span>
}

function SavedFilters({ name, values, onSelect }) {
  const [filters, setFilters] = usePersistentState(name + ".savedFilters", []);
  const [label, setLabel] = useState("");

  const saveFilter = (name) => {

    // Check if already exists and update
    for (const filter of filters) {
      if (filter.label === label) {
        filter.values = values;
        setFilters([...filters]);
        return;
      }
    }

    setFilters([...filters, {
      id: +Date.now(),
      label: label,
      values
    }]);
    setLabel("");

  }

  // Disable options for now
  const options = useMemo(() => {
    return [] // filters.map((filter) => ({ value: filter.label }));
  }, [filters]);


  return <>
    <Flex style={{
      padding: 5,
    }}>
      {(filters || []).map((filter) => {
        return <Tag
          style={{ cursor: 'pointer', marginRight: 3 }}
          key={filter.id}
          color="cyan"
          closable
          onClick={() => onSelect(filter.values)}
          onClose={() => {
            setFilters(filters.filter((f) => f !== filter));
          }}
        >
          {filter.label}
        </Tag>
        //    <Tooltip key={filter.id}
        //   title={
        //     <Button size="small" onClick={() => saveFilter(filter.id)}>
        //       Sauvegarder
        //     </Button>
        //   }>


        // </Tooltip>
      })}
      {
        values && values.length > 0 ? <Space.Compact>
          <AutoComplete size="small" value={label} onChange={(v) => setLabel(v)}
            onKeyUp={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            options={options}
            // prefix={<Icon path={mdiPlus} size={0.6} color={"grey"} style={{ marginRight: 3 }} />}
            placeholder="Sauvegarder..."
            style={{
              minWidth: 150,
            }}
          />
          <Button type="primary" size="small" onClick={() => saveFilter()} disabled={!values || !values.length || !label}
            icon={<Icon path={mdiCheck} size={0.6} />}></Button>
        </Space.Compact>
          : null
      }

    </Flex >
  </>
}

const typesToFilter = {
  agency: "agencies",
  project: "projects",
  projectType: "projectTypes",
  projectInvoicingMode: "projectInvoicingModes",
  resource: "resources",
  task: "tasks",
  prestation: "prestations",
  order: "orders",
  thirdparty: "thirdparties",
  text: "text"
};



export default function Search({
  agencies = [],
  projects = [],
  resources = [],
  tasks = [],
  prestations = [],
  orders = [],
  thirdparties = [],

  options = [],

  values: inputValues = {},

  enableProjectTypes = false,
  enableProjectInvoicingModes = false,

  onChange,

  dropdownRender,
  iconAndColorProps,

  name = ""
}) {

  const [values, setValues] = useState([]);

  useEffect(() => {
    if (JSON.stringify(inputValues?.values || [])
      !== JSON.stringify(values)) {
      setValues(inputValues.values || []);
    }
  }, [inputValues, values]);


  const [searchIdx, setSearchIdx] = useState({});


  const [computedOptions, idx] = useMemo(() => {
    const agenciesOptions = agencies.map((o) => ({ value: o.id || o.value, label: o.name || o.label, type: "agency" }));
    const projectsOptions = projects.map((o) => ({
      value: o.id || o.value,
      label: (o.code ? `${ProjectShortCode({ value: o.code })} - ${o.name}` : o.name) || o.label, searchString: o.code, type: "project"
    }));
    const resourcesOptions = resources.map((o) => ({ value: o.id || o.value, label: o.name || o.label, searchString: o.code, type: "resource" }));
    const tasksOptions = tasks.map((o) => ({ value: o.id || o.value, label: `${o.name || o.label}`, type: "task" }));
    const prestationsOptions = prestations.map((o) => ({ value: o.id || o.value, label: (o.code ? (o.code + ' - ' + o.name) : o.name) || o.label, searchString: o.code, type: "prestation" }));
    const ordersOptions = orders.map((o) => ({ value: o.id || o.value, label: o.name || o.label, type: "order" }));
    const thirdpartiesOptions = thirdparties.map((o) => ({ value: o.id || o.value, label: o.name || o.label, type: "thirdparty" }));

    const projectTypesOptions = enableProjectTypes ? projectTypes.map((o) => ({ value: "projectType-" + o.code, label: o.code + ' - ' + o.label, type: "projectType" })) : [];
    const projectInvoicingModesOptions = enableProjectInvoicingModes ? projectInvoicingModes.map((o) => ({ value: "projectInvoicingMode-" + o.code, label: o.code + ' - ' + o.label, type: "projectInvoicingMode" })) : [];


    const computedOptions = [
      ...agenciesOptions,
      ...projectsOptions,
      ...projectTypesOptions,
      ...projectInvoicingModesOptions,
      ...resourcesOptions,
      ...tasksOptions,
      ...prestationsOptions,
      ...ordersOptions,
      ...thirdpartiesOptions,
      ...options
    ];

    return [computedOptions, indexBy(computedOptions, "value")];
  }, [agencies, projects, resources, tasks, prestations, orders, thirdparties, options]);




  const onChangeHandler = (values) => {

    const filters = values.reduce((filters, value) => {
      const { type } = idx[value] || { type: 'text' };
      const filter = typesToFilter[type] || type;
      if (!filters[filter]) {
        filters[filter] = [];
      }
      filters[filter].push(value);
      return filters;
    }, {
      values,
      agencies: [],
      projects: [],
      projectTypes: [],
      projectInvoicingModes: [],
      resources: [],
      tasks: [],
      prestations: [],
      orders: [],
      thirdparties: [],
      text: []
    });
    setSearchIdx(null);
    onChange(filters);
  }



  const optionSearchStrings = useMemo(() => {
    return computedOptions.map((o) => sanitizeString((o.label ?? '') + (o.searchString ?? ''), true).split(' '));
  }, [computedOptions]);

  const onSearchChange = useCallback((search) => {
    if (!search) {
      setSearchIdx(null);
    }
    const s = sanitizeString(search, true).split(' ');
    const idx = {}
    for (let i = 0; i < computedOptions.length; i++) {
      const opt = optionSearchStrings[i];
      let cov = 0;
      for (let ii = 0; ii < s.length; ii++) {
        const ss = s[ii];
        let lcov = 0, nfounds = 0;
        for (let iii = 0; iii < opt.length; iii++) {
          if (opt[iii].startsWith(ss)) {
            lcov += (ss.length / opt[iii].length) * 1 / (iii + 1);
            nfounds++;
          }
        }
        if (lcov > 0) {
          cov += lcov / nfounds;
        } else {
          cov = 0;
          break;
        }
      }
      idx[computedOptions[i].value] = cov;
    }
    setSearchIdx(idx);
  }, [computedOptions, optionSearchStrings]);

  const filterOption = useCallback((input, option) => {
    return !searchIdx || searchIdx[option.value] > 0;
  }, [searchIdx]);

  const filterSort = useCallback((optionA, optionB) => {
    return searchIdx ? (searchIdx[optionB.value] || 0) - (searchIdx[optionA.value] || 0) : 0;
  }, [searchIdx]);

  if (name) {
    dropdownRender = (menu) => {
      return <>
        {menu}
        <div style={{ borderTop: '1px solid #eee', marginTop: '5px' }}>
          <SavedFilters name={name} values={values} onSelect={(values) => {
            onChangeHandler(values);
          }} />
        </div>
        {/* {dropdownRender ? dropdownRender(menu) : menu} */}
      </>
    }
  }

  return <Select
    style={{ width: '100%' }}
    mode="tags"

    optionRender={({ data }) => <OptionRenderer data={data} iconAndColorProps={iconAndColorProps} />}
    tagRender={({ label, value, closable, onClose }) => TagRenderer({ label, type: idx[value]?.type || 'text', value, closable, onClose, iconAndColorProps })}
    placeholder="Rechercher..."
    options={computedOptions}
    allowClear
    value={values}
    filterOption={filterOption}
    filterSort={filterSort}
    onChange={onChangeHandler}
    onSearch={onSearchChange}
    dropdownRender={dropdownRender}
  />
}
import { useNavigate } from "react-router-dom";

export default function Link({ children, to, ...props }) {
  const navigate = useNavigate();
  return <a
    href={to}
    onClick={(e) => {
      e.preventDefault();
      navigate(to)
    }}
    {...props}
  >{children}</a>
}
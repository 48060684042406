import { useEffect, useState } from 'react';
import { addLoaderListener, isLoading } from '../services/loader';

export function useLoadingState() {
  const [loading, setLoading] = useState(false);
  useEffect(() => addLoaderListener(() => {
    setLoading(isLoading());
  }), []);
  return loading;
}

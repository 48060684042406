import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import PickingPoolInput from "../../components/pickingpools/PickingPoolInput";
import clsx from "clsx";
import { usePickingPool, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import { Flex } from "antd";
import { useEffect } from "react";
import PickingPoolSwitcher from "../../components/app/PickingPoolSwitcher";

function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}

export default function Orders() {

  // const { pickingPool } = usePickingPool(pickingPoolId || '');

  return (
    <>
      <Outlet />
    </>
  )
}
/* eslint-disable import/prefer-default-export */


export function cache(enabled = true) {
  const cache = new Map();
  const keyTags = new Map();
  return {
    invalidateCache: (id) => {
      if (id === undefined) {
        cache.clear();
        keyTags.clear();
        return;
      }
      if (typeof id === 'object') {
        // Invalidate by tags
        for (const [k, t] of keyTags.entries()) {
          for (const [tag, value] of Object.entries(id)) {
            if (t[tag] === value) {
              cache.delete(k);
              break;
            }
          }
        }
      } else {
        for (const [k, t] of keyTags.entries()) {
          if (t.id === id) {
            cache.delete(k);
            keyTags.delete(k);
            break;
          }
        }
      }
      id = JSON.stringify(id);
      cache.delete(id);
      cache.delete(JSON.stringify('')); // List
    },
    withCache: async (updater, id = '') => {
      const k = JSON.stringify(id);
      if (!enabled || !cache.has(k)) {
        const r = await updater();
        cache.set(k, r);
        if (typeof id === 'object') {
          keyTags.set(k, id)
        }
      }
      return cache.get(k);
    },
  };
}

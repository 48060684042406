import clsx from "clsx";
import { useMemo } from "react";


function asISODate(date) {
  if (!date) {
    return null
  }
  const d = new Date(date)
  if (isNaN(d.getTime())) {
    return null
  }
  return d.toISOString().substring(0, 10)
}

export default function DateInput({ value, min, max, className, placeholder, ...props }) {
  const localValue = useMemo(() => {
    return asISODate(value)
  }, [value])

  const localMin = useMemo(() => {
    return asISODate(min)
  }, [min])

  const localMax = useMemo(() => {
    return asISODate(max)
  }, [max])

  return <input type="date"
    // style={{ maxWidth: '9em' }}
    className={clsx("form-control", className)}
    placeholder={placeholder || "JJ/MM/AAAA"}
    value={localValue}
    min={localMin}
    max={localMax}
    {...props} />
}
import { Flex } from "antd";
import Document from "./Document";


export default function DocumentList({ documents }) {
  return (
    <Flex vertical>
      {documents?.map((document) => (
        <Document key={document.id} doc={document} />
      ))}
    </Flex>
  );

}


const {
  REACT_APP_USER_POOL_CLIENT_ID,
  REACT_APP_USER_POOL_ID,
  REACT_APP_API_ENDPOINT,
  REACT_APP_ENV,
} = process.env;

// console.log(REACT_APP_ENV)

const awsconfig = {
  // aws_project_region: 'eu-west-3',
  // Cognito
  // aws_cognito_identity_pool_id: 'eu-west-3:dba2b45a-d604-4dea-9924-ed37a72d4161',
  // aws_cognito_region: 'eu-west-3',
  // aws_user_pools_id: 'eu-west-3_ZQCKcjbKn',
  // aws_user_pools_web_client_id: '6odl0o7ooifqs7of8v3dsm4e81',
  // oauth: {
  //   // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  // },
  Auth: {
    Cognito: {
      userPoolClientId: REACT_APP_USER_POOL_CLIENT_ID,
      userPoolId: REACT_APP_USER_POOL_ID,
    }
  },
  API: {
    REST: {
      'chronos': {
        endpoint: REACT_APP_API_ENDPOINT,
      },
    }
  },
  Analytics: {
    disabled: true,
  },
};

export default awsconfig;

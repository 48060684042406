import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  arrayMove,
} from '@dnd-kit/sortable';
import {
  createShipment, getShipment, getShipmentOrders, invalidateShipment, listShipments, removeShipment, updateShipment,
  updateShipmentOrders,
} from '../services/api/shipments';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { indexBy } from '../lib/utils';
import { useDelayedRenderingState, useNoRenderingState } from './state';
import { set } from 'date-fns';



const INVALIDATION_KEY = Symbol('shipments');


function getDefaults() {
  return {
    name: "",
    capacities: {},
    orders: [],
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getShipment(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useShipments({ warehouseId, onlyActive }) {
  const [shipments, setShipments] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (!warehouseId) {
      setComplete(true);
      setShipments([]);
      return;
    }
    (async () => {
      try {
        setComplete(false);
        const shipments = await loaderWrap(listShipments({ warehouseId, onlyActive }));
        setShipments(shipments);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, warehouseId, onlyActive]);

  const refresh = useCallback(() => {
    invalidateShipment();
    invalidate();
  }, [invalidate]);

  return [shipments, refresh, complete];
}


export function useShipmentsLoader(warehouseId = null) {
  const [activeShipments, refreshActiveShipments, complete] = useShipments({ warehouseId, onlyActive: true });
  const [toLoad, setToLoad] = useDelayedRenderingState([]);
  const [limitExceeded, setLimitExceeded] = useState(false);

  const [extraLoading, setExtraLoading] = useState(false);
  // const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [extraShipments, setExtraShipments] = useState([]);

  const activeShipmentsIdx = useMemo(() => indexBy(activeShipments, 'id'), [activeShipments]);

  useEffect(() => {
    console.log('extra shipments loader', { toLoad, complete, extraLoading, limitExceeded });
    if (!complete) return;
    if (extraLoading) return;
    if (!toLoad.length) return;
    if (limitExceeded) return;
    const pendingIds = Array.from(new Set(toLoad)).filter((id) => {
      return !extraShipments.find(p => p.id === id) && !activeShipmentsIdx[id];
    }); // Dedup
    if (!pendingIds.length) return;
    if (pendingIds.length > 100) {
      console.warn('shipment loader limit exceeded', pendingIds);
      setLimitExceeded(true);
      return;
    }
    console.log('load extra shipments', pendingIds);

    const ids = pendingIds.splice(0, 15); // Load 10 at a time
    setExtraLoading(true);

    setToLoad(pendingIds);

    // Loading
    (async () => {
      let loadedIds = ids.filter(id => {
        if (!id) return false;
        if (extraShipments.find(p => p.id === id)) return false;
        return true;
      })

      const shipments = (await loaderWrap(Promise.all(loadedIds.map(async id => {
        return await getItem(id)
      }))))

      setExtraShipments([...extraShipments, ...shipments]);

    })().finally(() => {
      setExtraLoading(false);
    });

  }, [toLoad, complete, extraLoading]);

  const refresh = useCallback(() => {
    // invalidate();
    refreshActiveShipments();
    setExtraShipments([]); // Clear extra shipments
    setToLoad(extraShipments.map(p => p.id)); // Reload extra shipments
  }, [refreshActiveShipments, setExtraShipments, setToLoad, extraShipments]);

  const shipments = useMemo(() => {
    return [
      ...activeShipments,
      ...extraShipments,
    ];
  }, [activeShipments, extraShipments]);

  const idx = useMemo(() => indexBy(shipments, 'id'), [shipments]);

  const getShipment = useCallback((id, autoLoad = true) => {
    if (!id) return null;

    if (autoLoad && !idx[id]) {
      // Add to load
      setToLoad((prev) => {
        const v = [...prev, id];
        return v
      });

      return null
    }
    return idx[id];
  }, [idx, setToLoad]);

  // console.log(loaderID, 'exit');
  return {
    shipments,
    refresh,
    complete,
    getShipment,
  };
}

export function useShipment(id) {
  const [shipment, setShipment] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setShipment(await getItem(id));
    })();
  }, [id, counter]);

  const saveShipment = useCallback(async (item) => {
    if (!item) {
      throw new Error('No shipment');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateShipment(item));
    } else {
      out = await loaderWrap(createShipment(item));
    }
    setShipment(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteShipment = useCallback(async () => {
    await loaderWrap(removeShipment(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    shipment,
    reloadShipment: () => invalidate(id),
    isNewShipment: !shipment?.id,
    setShipment,
    saveShipment,
    deleteShipment,
  };
}





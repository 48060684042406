import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createAgency, getAgency, listAgencies, removeAgency, updateAgency,
} from '../services/api/agencies';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';
import { add } from 'date-fns';
import { useAuth } from './auth';
import { useSetting } from './settings';

const INVALIDATION_KEY = Symbol('agencies');

const { invalidateCache, withCache } = cache();

function getDefaults() {
  return {
    code: "",
    name: "",
    description: "",
    address: "",
    phone: "",

  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getAgency(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useAgencies() {
  const [agencies, setAgencies] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listAgencies()));
        const terms = await loaderWrap(listAgencies());
        setAgencies(terms);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [agencies, refresh, complete];
}

export function useAgency(id) {
  const [agency, setAgency] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setAgency(await getItem(id));
    })();
  }, [id, counter]);

  const saveAgency = useCallback(async (item) => {
    if (!item) {
      throw new Error('No agency');
    }

    item = {
      ...item,
    }

    let out;
    if (item.id) {
      out = await loaderWrap(updateAgency(item));
    } else {
      out = await loaderWrap(createAgency(item));
    }
    setAgency(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteAgency = useCallback(async () => {
    await loaderWrap(removeAgency(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    agency,
    reloadAgency: () => invalidate(id),
    isNewAgency: !agency?.id,
    setAgency,
    saveAgency,
    deleteAgency,
  };
}

export function agencyValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    description: [isRequired],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

export function useSelectedAgencyID() {
  const [selectedAgencyID, setSelectedAgencyID] = useSetting('agency.selected', null);
  const [loaded, setLoaded] = useState(false);
  const { defaultAgencyId } = useAuth()

  const selectAgency = useCallback((id) => {
    setSelectedAgencyID(id)
  }, [setSelectedAgencyID]);


  // Load
  useEffect(() => {
    (async () => {
      if (defaultAgencyId) {
        selectAgency(defaultAgencyId);
      } else if (!loaded) {
        if (selectedAgencyID) {
          selectAgency(selectedAgencyID);
        }
        setLoaded(true);
      }
    })();
  }, [loaded, selectAgency, defaultAgencyId, selectedAgencyID]);

  return [
    selectedAgencyID,
    selectAgency,
  ]
}


export function useSelectedAgency() {
  const [selectedAgencyID] = useSelectedAgencyID();
  const [selectedAgency, setSelectedAgency] = useState(null);

  useEffect(() => {
    (async () => {
      if (selectedAgencyID) {
        const a = await getAgency(selectedAgencyID)
        setSelectedAgency(a);
      } else {
        setSelectedAgency(null);
      }
    })()
  }, [selectedAgencyID])

  return [
    selectedAgency,
  ]
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import App from './App';

import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import 'dayjs/locale/fr';

import { ConfigProvider, Form } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';


import awsconfig from './amplifyconfiguration';


dayjs.locale('fr');
dayjs.extend(minMax)
dayjs.extend(isBetween)
dayjs.extend(weekday)
dayjs.extend(isoWeek)
dayjs.extend(quarterOfYear)
dayjs.extend(utc)

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={frFR}
      theme={{
        components: {
          Segmented: {
            trackBg: '#D9F1FC'
            // itemSelectedColor: 'white',
            // itemSelectedBg: '#1677ff',
          },
        }
      }}
    // form={{
    //   labelCol: { span: 6 },
    //   wrapperCol: { flex: 1 },
    //   labelWrap: true,
    //   // components: (props) => <Form
    //   //   labelCol={{ span: 6 }}
    //   //   wrapperCol={{ flex: 1 }}
    //   //   labelWrap
    //   //   {...props}
    //   // />
    // }} 
    >
      <App />
    </ConfigProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

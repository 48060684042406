
import { useMemo } from "react";

import { Select } from "antd";
import { useTasks } from "../../hooks/tasks";

export function TaskInput({ value, name, placeholder, valueFilter, onChange,
  projectId,
  allowClear, multiple, disabled, variant }) {
  const [items] = useTasks()


  const filteredItems = useMemo(() => {
    const localItems = items.filter((item) => item.project_id === projectId)
    if (valueFilter) {
      return localItems.filter(valueFilter)
    }
    return localItems || []
  }, [items, valueFilter])

  return <Select
    showSearch
    placeholder={placeholder || 'Sélectionner une tache'}
    optionFilterProp="children"
    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
    }
    fieldNames={{
      label: "name",
      value: "id",
    }}
    value={value}
    onChange={onChange}
    options={filteredItems}
    disabled={disabled}
    allowClear={allowClear}
    variant={variant}
    mode={multiple ? "multiple" : undefined}
  />
}
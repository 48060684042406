

export const projectPalette = {
  color: '#389e0d',
  backgroundColor: ['#f6ffed', '#FAFFF5'],

}
export const taskPalette = {
  color: '#389e0d',
  backgroundColor: ['#f6ffed', '#FAFFF5'],
}
export const resourcePalette = {
  colors: '#1890ff',
  backgroundColor: ['#e6f7ff', '#e6f7ff'],
}
export const agencyPalette = {
  color: '#0958d9',
  backgroundColor: ['#e6f4ff', '#ebf8ff'],
}
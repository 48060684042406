import { DatePicker, Form, Input, Segmented, Space, TimePicker } from "antd";
import { PrestationInput } from "../../components/prestations/PrestationInput";
import { ResourceForPrestationInput } from "../../components/resources/ResourceForPrestationInput";
import { listSlots } from "../../services/api/slots";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import { formatTime } from "../../lib/format";
import Icon from "@mdi/react";
import { mdiArrowRight } from "@mdi/js";


export default function ShipmentDeliveryForm({ form, delivery }) {
  const prestationId = Form.useWatch("prestation_id", { form })
  const resourceId = Form.useWatch("resource_id", { form })
  const when = Form.useWatch("when", { form })
  const deliveryDate = Form.useWatch("delivery_date", { form })
  const [slots, setSlots] = useState(null)
  useEffect(() => {
    if (prestationId && resourceId) {
      (async () => {
        const slots = await listSlots({ resource_id: resourceId })
        setSlots(slots)
      })()
    } else {
      setSlots(null)
    }
  }, [prestationId, resourceId])

  const slotsAtThisDate = useMemo(() => {
    if (!slots) return null
    return slots.filter(slot => dayjs(slot.start).isSame(deliveryDate, 'day'))
  }, [slots, deliveryDate])

  // const slotsAtThisDate = slots?.filter(slot => dayjs(slot.start).isSame(when, 'day'))

  const isDisabledDate = (current) => {
    current = current.hour(when === 'morning' ? 10 : 14)
    if (!slots) return false
    for (const slot of slots) {
      if (current.isBetween(slot.start, slot.end)) return true
    }
    return false
  }

  const style = {
    border: `1px solid red`,
    // borderRadius: '50%',
  };

  const cellRender = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div className="ant-picker-cell-inner" style={isDisabledDate(current) ? style : {}}>
        {current.date()}
      </div>
    );
  };

  return (
    <Form form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ flex: 1 }}
      labelWrap >

      <Form.Item label="Type" name="prestation_id" rules={[{ required: true, message: "Veuillez spécifier une prestation" }]}>
        <PrestationInput valueFilter={(p) => p.type === 'delivery'} />
      </Form.Item>

      <Form.Item label={"Affecté à"} name="resource_id" rules={[{ required: true, message: "Veuillez spécifier une ressource" }]}>
        <ResourceForPrestationInput
          prestationId={prestationId}
        // prestationId={}
        // agencyId={pickingOrders?.agency_id}
        // expectedLevel={task?.expected_level}
        // busyResources={busyResources} 
        />
      </Form.Item>
      <Form.Item label={"Créneau horaire"} >
        <Space direction="vertical">
          <Form.Item name="when" noStyle>
            <Segmented options={[
              { value: 'morning', label: 'Matin' },
              { value: 'afternoon', label: 'Après-midi' },
              // { value: 'custom', label: 'Personnalisé' }
            ]} />
          </Form.Item>
          {when === 'custom' &&
            <Form.Item name="custom_when" noStyle rules={[{ required: true, message: "Veuillez spécifier une plage horaire" }]}>
              <TimePicker.RangePicker format={"HH:mm"} minuteStep={15} />
            </Form.Item>
          }
        </Space>
      </Form.Item>
      <Form.Item label="Date" name="delivery_date" rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
        <DatePicker format={"DD/MM/YYYY"} cellRender={cellRender} allowClear={false} />
      </Form.Item>
      {slotsAtThisDate?.length ?
        <Form.Item label="⚠️ Créneaux déjà utilisés" >
          {slotsAtThisDate?.map(slot =>
            <div key={slot.id}>{formatTime(slot.start)} <Icon path={mdiArrowRight} size={0.5} /> {formatTime(slot.end)}</div>
          )}
        </Form.Item> : null}
      <Form.Item label="Numéro de suivi" name="tracking_number">
        <Input />
      </Form.Item>
    </Form>
  );
}
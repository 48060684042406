
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function Planning() {
  return (<>
    {/* <ul className="nav nav-pills subnav mb-4">
      <li className="nav-item">
        <NavLink to="projects" className={navLinkActive("nav-link", "active")}>Affaires</NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="resources" className={navLinkActive("nav-link", "active")}>Resources</NavLink>
      </li>
      
    </ul> */}
    <Outlet />
  </>);
}

import { useMemo } from "react"
import { slotCancelReasons } from "../../hooks/slots"
import { Tag } from "antd"


export default function SlotCancelReasonLabel({ value }) {
  const item = useMemo(() => {
    return slotCancelReasons.find((s) => s.value === value) || { label: 'Inconnu' }
  }, [value])
  return <>{item.label}</>
}
import { useMemo } from "react";
import { prestationsTypes } from "../../hooks/prestations"
import PrestationIcon from "./PrestationIcon";


export default function PrestationTypeLabel({ type }) {
  const value = useMemo(() => {
    return prestationsTypes.find(s => s.value === type) || { label: 'Inconnu', color: 'secondary' }
  }, [type])

  if (!value) {
    return <span className="">Inconnu</span>
  }
  return <span className={``}>
    <PrestationIcon type={type} size={0.8} />{' '}
    {value.label}
  </span>
}
import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/settings');
const { invalidateCache, withCache } = cache();

function mapFromSettingModel({ key, value }) {
  return {
    key,
    value: JSON.stringify(value),
  };
}

function mapToSettingModel(input) {
  if (!input) {
    return { value: undefined, exists: false };
  }
  let value = input.value;
  if (value !== '') {
    value = JSON.parse(value);
  }
  return { key: input.key, value, exists: !!input.exists };;
}


export async function listSettings() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  }, '')
  return items ? items.map(mapToSettingModel) : [];
}

export async function getSetting(key) {
  const item = await withCache(async () => {
    const { item } = await api.get(key);
    if (!item) {
      return null
    }
    return item;
  }, key)

  return mapToSettingModel(item);;
}

export async function setSetting(setting) {
  const res = await api.put('', { item: mapFromSettingModel(setting) });
  invalidateCache(setting.id)
  return res ? setting : undefined;
}

export async function removeSetting(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}

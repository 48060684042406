import { Badge, Flex, Space } from "antd";
import { slotStatuses } from "../../hooks/slots";
import ResourceIcon from "../resources/ResourceIcon";

// import Style from "./ResourceSlotEventItem.module.scss";
import PrestationIcon from "../prestations/PrestationIcon";
import clsx from "clsx";

import Style from "./EventItem.module.scss";
import { formatTime, formatDuration } from "../../lib/format";


export default function TaskSlotEventItem({
  schedulerData,
  event,
  bgColor,
  isStart,
  isEnd,
  mustAddCssClass,
  mustBeHeight,
  agendaMaxEventWidth,
  errors,
  resourcesIdx,
}) {
  const slot = resourcesIdx[event.id] || {}
  const user = resourcesIdx[slot.resource_id] || {}
  const eventTitle = schedulerData.behaviors.getEventTextFunc(schedulerData, event);
  const roundCls = isStart ? (isEnd ? 'round-all' : 'round-head') : (isEnd ? 'round-tail' : 'round-none');
  const isSameDay = event.start.slice(0, 10) === event.end.slice(0, 10)

  if (isSameDay) {
    mustAddCssClass += ' day-once'
  }

  let errorType;
  for (let i = 0; i < errors?.length; i++) {
    const e = errors[i]
    if (e.level === 'error') {
      errorType = 'error'
      break
    }
    if (e.level === 'warning') {
      errorType = 'warning'
    }
  }


  slotStatuses.forEach((status) => {
    if (slot.status === status.id) {
      bgColor = status.color
    }
  })

  return <div className={clsx(roundCls, mustAddCssClass, {
    [Style['event-error']]: errorType === 'error',
    [Style['event-warning']]: errorType === 'warning',
    [Style['event-canceled']]: slot.status === 'canceled',
  })} key={event.id}
    style={{ height: mustBeHeight, color: bgColor }}>
    <div className={Style["event-light-inner"]} style={{ lineHeight: `${mustBeHeight}px` }}>
      {/* <PrestationIcon type={user.type} size={0.6} /> */}
      <Flex gap={3} align="center" justify="">
        <div>
          {isStart && slot.type === 'time' && <span className={Style['event-time']}>{formatTime(event.start)}</span>}
          {isStart && slot.type === 'task' && <span className={Style['event-time']}>{slot.duration}h</span>}
        </div>

        <span style={{ textDecoration: 'none' }}>{eventTitle}</span>
      </Flex>
      {/* {errorType &&
        <div style={{ position: 'absolute', left: -2, top: -8 }}>
          <Badge dot={true} status={errorType} />
        </div>} */}
    </div>
  </div>

}
class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
  }
}

export function validate(value, ...rules) {
  for (let i = 0; i < rules.length; i += 1) {
    try {
      rules[i](value);
    } catch (e) {
      if (e instanceof ValidationError) {
        return e.message;
      }
      throw e;
    }
  }
  return undefined;
}

export function validateAll(values, rules) {
  const errors = {};
  Object.keys(rules).forEach((key) => {
    const error = validate(values[key], ...rules[key] || []);
    if (error) {
      errors[key] = error;
    }
  });
  return errors;
}

export function isRequired(v) {
  if (!v) {
    throw new ValidationError('Ce champ est requis');
  }
}

export function isRegex(rx, message) {
  return (v) => {
    if (!rx.test(v)) {
      throw new ValidationError(message || 'Format invalide');
    }
  };
}

export function isNumber(v) {
  if (Number.isNaN(+v)) {
    throw new ValidationError(`Ce n'est pas un nombre`);
  }
}

export function isEmail(v) {
  return isRegex(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Is not a valid email')(v);
}

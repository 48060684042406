import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/agencies');
const { invalidateCache, withCache } = cache();

function mapFromAgencyModel(input) {
  return { ...input };
}

function mapToAgencyModel(input) {
  return { ...input };
}

export async function listAgencies() {
  const items = await withCache(async () => {
    const { items } = await api.get();
    return items;
  })
  return items ? items.map(mapToAgencyModel) : [];
}

export async function getAgency(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Agency not found');
    }
    return item;
  }, id)
  return mapToAgencyModel(item);;
}

export async function createAgency(agency) {
  const { item } = await api.post('', { item: mapFromAgencyModel(agency) });
  if (item) {
    invalidateCache(item.id)
    return mapToAgencyModel(item)
  }
  return undefined;
}

export async function updateAgency(agency) {
  const res = await api.put('', { item: mapFromAgencyModel(agency) });
  invalidateCache(agency.id)
  return res ? agency : undefined;
}

export async function removeAgency(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}

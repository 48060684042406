import { Tag } from "antd";


export default function DeliveryStatus({ value }) {
  const status = {
    'pending': ['En attente', 'default'],
    'confirmed': ['Confirmé', 'green'],
    'shipped': ['Expédié', 'blue'],
    'delivered': ['Livré', 'green'],
  };
  const stat = status[value] || [];
  const label = stat[0] || 'Inconnu';
  const color = stat[1] || 'red';
  return <Tag color={color}>{label}</Tag>
}


import { useMemo, useState } from "react";
import { indexBy } from "../../lib/utils";
import { useResources } from "../../hooks/resources";
import { Badge, Divider, Flex, Rate, Select, Space, Switch } from "antd";
import { useResourcePrestations } from "../../hooks/resource-prestations";
import { mdiCalendarRemoveOutline, mdiStar, mdiStarHalf, mdiStarHalfFull, mdiStarOffOutline, mdiStoreOffOutline, mdiTrendingDown } from "@mdi/js";
import Icon from "@mdi/react";
import { formatCurrency } from "../../lib/format";

function StatusIndicator({ data, showLabel = false }) {
  const [status, icon] = useMemo(() => {
    if (!data) {
      return 'default'
    }
    const { busy, level, underLevel, outOfAgency } = data
    switch (true) {
      case busy:
        return ['error', <Icon path={mdiCalendarRemoveOutline} size={0.7} color="#ff4d4f" />]
      case underLevel:
        return ['warning', <Icon path={mdiStarHalfFull} size={0.7} color="#faad14" />]
      case outOfAgency:
        return ['warning', <Icon path={mdiStoreOffOutline} size={0.7} color="#faad14" />]
      case level === undefined:
        return ['default', <Icon path={mdiStarOffOutline} size={0.7} color="rgba(0, 0, 0, 0.25)" />]
      default:
        return ['success', <Badge status="success" />]
    }
  }, [data])

  return <Space>
    {/* <Badge status={status} /> */}
    {icon}
  </Space>
}

function Option({ data, showLevel, showPrice, ...props }) {
  const { name, level, price } = data

  return (
    <Flex gap={"small"} align="center">
      <StatusIndicator data={data} />
      <span style={{ color: data.color, flex: 1 }}>
        {name}
      </span>
      {showPrice && price && <span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
        {formatCurrency(price)}
      </span>}
      {showLevel &&// data.underLevel && level !== undefined &&
        <Rate value={Math.ceil(level * 3 / 5)} disabled style={{ fontSize: '0.8em' }} count={3} />
      }
    </Flex>
  )
}


export function ResourceForPrestationInput({ value, name, placeholder, style,
  prestationId, busyResources = [], agencyId, expectedLevel, showPrice,
  onChange }) {

  const [resources] = useResources()
  const [resPrestations] = useResourcePrestations({ prestationId })

  const [displayOptions, setDisplayOptions] = useState({
    showBusy: true,
    showOutOfAgency: true,
    showUnderLevel: true,
    showNoLevel: false,
  })

  const { showBusy, showOutOfAgency, showUnderLevel, showNoLevel } = displayOptions

  const items = useMemo(() => {
    const idx = indexBy(resPrestations, "resource_id")
    const items = resources
      .map((r) => {
        const busy = busyResources.includes(r.id)
        const { level, price } = idx[r.id] || {}
        let score = 0;
        if (!busy) {
          score += 100
        }
        if (agencyId && r.agency_id === agencyId) {
          score += 10
        }
        score += level || 0

        const underLevel = level < expectedLevel
        const outOfAgency = agencyId && r.agency

        return {
          ...r,
          label: <Space>
            <StatusIndicator data={{ busy, level, underLevel, outOfAgency }} />
            {r.name}
          </Space>,
          // prestation: idx[r.id],
          level,
          underLevel: level < expectedLevel,
          outOfAgency: agencyId && r.agency_id !== agencyId,
          busy,
          score,
          price,
        }
      })
      .filter((r) => {
        if (value && r.id === value) {
          return true
        }
        if (!showBusy && r.busy) {
          return false
        }
        if (!showOutOfAgency && r.outOfAgency) {
          return false
        }
        if (!showUnderLevel && r.underLevel) {
          return false
        }
        if (!showNoLevel && r.level === undefined) {
          return false
        }

        return true
      })
    return items || []
  }, [resources, resPrestations, agencyId, busyResources, expectedLevel, value, displayOptions])


  const changeHandler = (value) => {
    onChange && onChange({ target: { value } })
  }

  return <Select
    showSearch
    // style={{ width: 200 }}
    placeholder={placeholder || 'Sélectionner une ressource'}
    optionFilterProp="children"
    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.score > optionB?.score ? -1 : 1)
    }
    fieldNames={{
      // label: "name",
      value: "id",
    }}
    optionRender={(option) => <Option data={option.data} showLevel={expectedLevel > 0} showPrice={showPrice} />}
    dropdownRender={(menu) => (
      <>
        {menu}
        <Flex align="center" gap="small" justify="end">
          <Switch checked={showNoLevel}
            size="small"
            // checkedChildren="Afficher"
            // unCheckedChildren="Masquer"
            onChange={value => setDisplayOptions({ ...displayOptions, showNoLevel: value })} />
          Voir toutes les ressources
        </Flex>
      </>
    )}
    value={value}
    onChange={changeHandler}
    options={items}
  />


}
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  getOrder, listOrders
} from '../services/api/orders';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';
import { indexBy } from '../lib/utils';


const INVALIDATION_KEY = Symbol('orders');



export const orderStatuses = [
  {
    label: 'À faire',
    value: 'pending',
    color: 'default'
  },
  {
    label: "En cours",
    value: 'inprogress',
    color: '#1677ff'
  },
  {
    label: "Terminé",
    value: 'terminated',
    color: '#52C41A'
  },
  {
    label: "Archivée",
    value: 'archived',
    color: 'orange'
  },
];

export const orderItemStatuses = [
  {
    label: 'À faire',
    value: 'pending',
    color: 'default'
  },
  {
    label: "En cours",
    value: 'partial',
    color: '#1677ff'
  },
  {
    label: "Terminé",
    value: 'complete',
    color: '#52C41A'
  },
];

export const orderShippingModes = [
  { label: 'Aquadoc', value: 'AQUAD' },
  { label: 'Dropshipping', value: 'AQUAFR' },
  { label: 'Baptiste Concolato', value: 'BC' },
  { label: 'Bastien Venuti', value: 'BV' },
  { label: 'Charlotte Blin', value: 'CHB' },
  { label: 'Christian Valery', value: 'CV' },
  { label: 'Chrono Export', value: 'CHROEX' },
  { label: 'Commande Express', value: 'EXPRES' },
  { label: 'Damien Julian', value: 'DJ' },
  { label: 'Demande Du Client', value: 'DEMAND' },
  { label: 'Didier Reverte', value: 'DR' },
  { label: 'Enlevement Client', value: 'ENLEV' },
  { label: 'Fabienne Galleazzi', value: 'FAG' },
  { label: 'Floriane Peitavy', value: 'FPEITA' },
  { label: 'Fret Aérien Palette', value: 'FRETA' },
  { label: 'Fret Container St Thibery', value: 'FRETC' },
  { label: 'Fret Maritime Groupage', value: 'FRETG' },
  { label: 'Gautier Peralta', value: 'GP' },
  { label: 'Jeremie Haute', value: 'JEH' },
  { label: 'Jeremy Humbert', value: 'JH' },
  { label: 'Jonathan Faure', value: 'JF' },
  { label: 'Jonathan Julien', value: 'JJ' },
  { label: 'Fournisseur Livre Aquadoc', value: 'FOURN' },
  { label: 'Livraison Agence Lezignan', value: '005' },
  { label: 'Livraison Agence Livron', value: '010' },
  { label: 'Livraison Agence St Maximin', value: '002' },
  { label: 'Livraison Agence Vauvert', value: '003' },
  { label: 'Livraison Agence Villasavary', value: '006' },
  { label: 'Livraison Direct Client', value: 'DIRECT' },
  { label: 'Livraison Poste Coils < 30kg', value: 'POSTE' },
  { label: 'Livraison DPD Coils > 30kg', value: 'DPD' },
  { label: 'Livraison Porteur < 1T', value: 'PORTEU' },
  { label: 'Livraison Semi Remorque', value: 'SEMI' },
  { label: 'Livraison Service Technique', value: 'TECH' },
  { label: 'Livraison Sous Traitant', value: 'LIVST' },
  { label: 'Sebastien Dalpra', value: 'SD' },
  { label: 'Transporteurs Leroy', value: 'TRANSP' },
  { label: 'Xemein Etchamendy', value: 'XETCH' },
];

export const orderPaymentModesIdx = indexBy(orderShippingModes, 'value');





function getDefaults() {
  return {
    code: "",
    name: "",
    capacity: 0,
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getOrder(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useOrders(dateFrom, dateTo) {
  const [orders, setOrders] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!dateFrom || !dateTo) {
          return;
        }
        setComplete(false);
        const orders = await loaderWrap(listOrders(dateFrom, dateTo));
        setOrders(orders);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, +dateFrom, +dateTo]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [orders, refresh, complete];
}

export function useOrder(id) {
  const [order, setOrder] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setOrder(await getItem(id));
    })();
  }, [id, counter]);


  return {
    order,
    reloadOrder: () => invalidate(id),
    isNewOrder: !order?.id,
    setOrder,
  };
}
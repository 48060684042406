import { useMemo } from "react"
import { taskTypes } from "../../hooks/tasks"
import { Tag } from "antd"


export default function TaskTypeLabel({ value }) {
  const { label, color } = useMemo(() => {
    return taskTypes.find(s => s.value === value) || { label: value }
  }, [value])
  return <Tag color={color || 'default'}>{label}</Tag>
  // return <span className={`badge bg-${status.color || 'secondary'}`}>{status.label}</span>
}

import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import PickingPoolInput from "../../components/pickingpools/PickingPoolInput";
import clsx from "clsx";
import { usePickingPool, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import { Flex, Space } from "antd";
import { useEffect } from "react";
import PickingPoolSwitcher from "../../components/app/PickingPoolSwitcher";
import PickingPoolRequired from "../../components/app/PickingPoolRequired";
import Icon from "@mdi/react";
import { mdiPackageVariant, mdiPackageVariantClosedCheck, mdiTruckFastOutline } from "@mdi/js";

function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}

export default function Shipping() {

  const [pickingPoolId] = useSelectedPickingPoolID();

  if (!pickingPoolId) return <PickingPoolRequired />


  return <>
    <nav>
      <Flex>
        <ul className="nav nav-pills subnav mb-4">
          <li className="nav-item">
            <NavLink to={"list"} className={navLinkActive("nav-link", "active")}>
              <Space align="center"><Icon path={mdiPackageVariantClosedCheck} size={0.8} /> Expéditions</Space>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={"topick"} className={navLinkActive("nav-link", "active")}>
              <Space align="center"><Icon path={mdiPackageVariant} size={0.8} /> Préparation</Space>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={"deliveries"} className={navLinkActive("nav-link", "active")}>
              <Space align="center"><Icon path={mdiTruckFastOutline} size={0.8} /> Livraisons</Space>
            </NavLink>
          </li>
        </ul>
      </Flex>
    </nav>
    <Outlet />
  </>
}

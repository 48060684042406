import { mdiAccountCowboyHatOutline, mdiAccountHardHat, mdiAccountInjuryOutline, mdiHardHat } from "@mdi/js";
import Icon from "@mdi/react";


export default function ResourceIcon({ type, size = 1 }) {
  switch (type) {
    case 'internal':
      return <Icon path={mdiAccountHardHat} size={size} />
    case 'external':
      return <Icon path={mdiAccountCowboyHatOutline} size={size} />
    case 'customer':
      return <Icon path={mdiAccountInjuryOutline} size={size} />
    default:
      return <Icon path={mdiHardHat} size={size} />
  }

}

import { Divider, Input, Flex, Button, Timeline, ConfigProvider } from 'antd';
import { formatDateTime } from '../../lib/format';


import { ArrowUpOutlined, MessageOutlined } from '@ant-design/icons';



import styles from './MessagesView.module.scss';
import { useCallback, useMemo, useState } from 'react';
import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import Avatar from '../avatar/Avatar';
import { useAuth } from '../../hooks/auth';
import Message from './Message';
import MessagesList from './MessagesList';





export default function MessagesView({ messages, onSend }) {
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState('');
  const { can } = useAuth();
  const revertedMessages = useMemo(() => messages.slice().reverse(), [messages]);
  const onSendHandler = () => {
    setSending(true);
    (async () => {
      await onSend(message)
      setMessage('')
      setSending(false)
    })()
  }
  return <div className={styles.messagesView}>
    {revertedMessages && revertedMessages.length ? <MessagesList messages={revertedMessages} /> : null}
    {can('message.create') &&
      <Flex gap="small">
        <Input.TextArea
          prefix={
            <MessageOutlined />
          }
          variant='filled'
          placeholder='Ajouter un commentaire...'
          value={message}
          disabled={sending}
          onChange={(e) => setMessage(e.target.value)}
          autoSize={{
            minRows: 1,
            maxRows: 5,
          }} />
        {!message.trim() ? null :
          <Button type="primary" onClick={() => onSendHandler()}
            loading={sending}
            title='Envoyer'
            disabled={!message.trim()}
            icon={<ArrowUpOutlined />}
          ></Button>
        }
      </Flex>
    }
  </div>
}

import { mdiPackageVariant, mdiTruckFastOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Space } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';


function navLinkActive(baseCls, activeClass) {
  return ({ isActive }) =>
    clsx(baseCls, isActive && activeClass)

}


export default function OrderItem() {
  const { orderId } = useParams();
  return (<>
    {orderId && orderId !== '*' ? (
      <ul className="nav nav-pills subnav mb-4">
        <li className="nav-item">
          <NavLink to="details" className={navLinkActive("nav-link", "active")}>Détail</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="pickings" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiPackageVariant} size={0.8} /> Préparations</Space>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="shipments" className={navLinkActive("nav-link", "active")}>Expéditions</NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink to="deliveries" className={navLinkActive("nav-link", "active")}>
            <Space align="center"><Icon path={mdiTruckFastOutline} size={0.8} /> Livraisons</Space>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to="slotaway" className={navLinkActive("nav-link", "active")}>Indisponibilités</NavLink>
        </li> */}
      </ul>) : null}
    <Outlet />
  </>);
}


import { withApi } from './api';

const api = withApi('chronos', '/calendar', false);

function authHeader(token) {
  return { 'Authorization': 'Sign ' + token };
}

export function getCalendarData(accessKey, slotId, token) {
  return api.get(`${accessKey}/${slotId}`, authHeader(token));
}

export function setCalendarData(accessKey, slotId, token, data) {
  return api.put(`${accessKey}/${slotId}`, data, authHeader(token));
}

export function createUpload(accessKey, slotId, token, originalName, contentType) {
  return api.post(`${accessKey}/${slotId}/files`, {
    filename: originalName,
    contentType,
  }, authHeader(token));
}

export function getFileUrl(accessKey, slotId, token, fileId) {
  return api.get(`${accessKey}/${slotId}/files/${fileId}`, authHeader(token));
}
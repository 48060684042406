import { useParams } from "react-router-dom";
import SlotsTable from "../../components/slots/SlotsTable";
import { useTask } from "../../hooks/tasks";




export default function TaskPlanning() {
  const { taskId } = useParams();
  const { task } = useTask(taskId);

  if (!task) return null;

  return (<>
    <SlotsTable
      task_id={taskId}
      cost={task.cost}
      duration={task.duration}
      className="box" />
  </>
  );
}
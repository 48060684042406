import { useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';

let forcedPerms = null;

// forcedPerms = `shipping,planning.show
// shipping,agency.list
// shipping,agency.view
// shipping,project.show
// shipping,project.list
// shipping,project.view
// shipping,prestation.list
// shipping,prestation.view
// shipping,resource.list
// shipping,resource.view
// shipping,slot.list
// shipping,slot.view
// shipping,slot.create
// shipping,task.list
// shipping,task.view
// shipping,task.create
// shipping,message.list
// shipping,message.view
// shipping,message.create
// shipping,message.update
// shipping,message.delete
// shipping,changelog.list
// shipping,changelog.view
// shipping,item.show
// shipping,item.list
// shipping,item.view
// shipping,order.show
// shipping,order.list
// shipping,order.view
// shipping,warehouse.list
// shipping,warehouse.view
// shipping,pickingpool.list
// shipping,pickingpool.view
// shipping,pickingpool.update
// shipping,picking.show
// shipping,picking.list
// shipping,picking.view
// shipping,picking.create
// shipping,picking.update
// shipping,picking.delete
// shipping,shipment.list
// shipping,shipment.view
// shipping,shipment.create
// shipping,shipment.update
// shipping,shipment.delete
// shipping,delivery.list
// shipping,delivery.view
// shipping,delivery.create
// shipping,delivery.update
// shipping,delivery.delete
// shipping,import.create`



export function useAuth() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState({ permissions: new Set() });
  // const [permissions, setPermissions] = useState(new Set());

  useEffect(() => {
    (async () => {
      const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};
      const claims = idToken?.payload;

      // console.log(idToken.payload)
      if (!claims) {
        return;
      }

      const permsStr = forcedPerms
        ? forcedPerms.replace(/^[^,]+,(.+)$/gm, '$1').replace(/\n+/g, ',') :
        claims['permissions'];


      const permissions = new Set(permsStr?.split(',') || []);
      // console.log(permissions)

      setData({
        givenName: claims.given_name,
        familyName: claims.family_name,
        fullName: [claims.given_name, claims.family_name].join(" "),
        email: claims.email,
        role: claims['custom:Role'] || null,
        permissions,
        defaultAgencyId: claims['custom:AgencyID'] || null,
        defaultPoolId: claims['custom:PoolID'] || null,
        commercialCodes: claims['custom:CommercialCodes']?.split(',') || [],
      })

    })()
    // console.log(await getCurrentUser())
  }, [user.userId]);


  return {
    user,
    ...data,
    signOut,
    can: (p) => data.permissions.has(p),
  };
}

// export function useRolesList() {
//   const [r, setRoles] = useState([]);
//   const { role } = useAuth();

//   useEffect(() => {
//     const rmap = {
//       'aquadoc:admin': ['aquadoc:admin', 'org:admin', 'user'],
//       'org:admin': ['org:admin', 'user'],
//       user: ['user'],
//     };
//     const s = new Set(rmap[role]);
//     setRoles(roles().filter(({ value }) => s.has(value)));
//   }, [role]);
//   return r;
// }

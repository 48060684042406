
import Link from "../link/Link";

import { useTask } from "../../hooks/tasks";


export default function TaskLabel({ id, clickable = true }) {
  const { task } = useTask(id);
  if (id === 'unavailable') {
    return <span>-</span>
  }
  if (!clickable) {
    return task?.name
  }
  return <Link to={"/tasks/" + id}>{task?.name}</Link>;
}
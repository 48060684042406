

import DeliveryLabel from "../deliveries/DeliveryLabel";

import PrestationLabel from "../prestations/PrestationLabel";
import ProjectLabel from "../projects/ProjectLabel";
import ResourceLabel from "../resources/ResourceLabel";
import ShipmentLabel from "../shipments/ShipmentLabel";
import TaskLabel from "../tasks/TaskLabel";

export default function RefLabel({ value, clickable = true }) {
  const [type, _] = value.split("-");
  switch (type) {
    case 'prj':
      return <ProjectLabel id={value} clickable={clickable} />
    case "task":
      return <TaskLabel id={value} clickable={clickable} />
    case "res":
      return <ResourceLabel id={value} clickable={clickable} />
    case "pres":
      return <PrestationLabel id={value} clickable={clickable} />
    case "shipment":
      return <ShipmentLabel id={value} clickable={clickable} />
    // case "order":
    //   return <OrderLabel id={value} clickable={clickable} />
    case "delivery":
      return <DeliveryLabel id={value} clickable={clickable} />
    default:
      return value;
  }
}
import { Tag } from "antd";


export default function ShipmentStatus({ value }) {
  const status = {
    'pending': ['En attente', 'default'],
    'preparing': ['En préparation', 'blue'],
    'ready': ['Prête', 'orange'],
    'shipped': ['Expédié', 'green'],
  };
  const stat = status[value] || [];
  const label = stat[0] || 'Non affectée';
  const color = stat[1] || 'red';
  return <Tag color={color}>{label}</Tag>
}
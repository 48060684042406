import { Affix, Button, Divider, Flex, Popover } from "antd";
import { Breadcrumbs } from "../breadcrumb/Breadcrumbs";
import { routes } from "../../routes";
import { useEffect, useRef, useState } from "react";

import Style from "./AppTop.module.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { fetchAuthSession } from "aws-amplify/auth";
import AgencySwitcher from "./AgencySwitcher";
import { useSelectedAgency } from "../../hooks/agencies";
import PickingPoolSwitcher from "./PickingPoolSwitcher";
import Icon from "@mdi/react";
import { mdiPagePrevious, mdiStoreOutline, mdiTrayFull } from "@mdi/js";
import { useAuth } from "../../hooks/auth";

export default function AppTop() {
  const [sticked, setSticked] = useState(false);
  // const [userName, setUserName] = useState("");
  // const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { user, signOut, fullName, defaultAgencyId, defaultPoolId } = useAuth()
  const [agency] = useSelectedAgency()


  // useEffect(() => {
  //   (async () => {
  //     const { idToken } = (await fetchAuthSession()).tokens ?? {};
  //     const claims = idToken?.payload;

  //     if (claims) {
  //       setUserName([claims.given_name, claims.family_name].join(" "));
  //     }
  //   })()
  //   // console.log(await getCurrentUser())
  // }, [user.userId]);


  return (
    <Affix
      // offsetTop={sticked ? 0 : 0} 
      onChange={(affixed) => setSticked(affixed)}
      target={() => document.getElementById("app-main")}>
      <div className={Style.top + (sticked ? " " + Style.sticked : "")}>
        <Flex align="center" justify="space-between" >

          <Breadcrumbs routes={routes} />

          <Popover content={
            <Flex vertical gap="middle">
              {!defaultAgencyId ?
                <Flex align="center" gap="small">
                  <Icon path={mdiStoreOutline} size={1} />
                  <AgencySwitcher />
                </Flex>
                : null}
              {!defaultPoolId ?
                <Flex align="center" gap="small">
                  <Icon path={mdiTrayFull} size={1} />
                  <PickingPoolSwitcher />
                </Flex>
                : null}
              <Button danger href="/" onClick={(e) => {
                e.preventDefault();
                signOut();
              }}>Déconnexion</Button>
            </Flex>
          }>
            <Flex align="flex-end" justify="center" vertical gap={0}>
              <div className={Style.username}>{fullName}</div>
              {agency ?
                <div className={Style.agency}>{agency?.name}</div>
                : null}
            </Flex>
          </Popover>
        </Flex>
      </div>
    </Affix >
  );
}
import { useMemo } from "react"
import { slotStatuses } from "../../hooks/slots"
import { Tag } from "antd"


export default function SlotStatusLabel({ status }) {

  const item = useMemo(() => {
    return slotStatuses.find((s) => s.value === status) || { label: 'Inconnu', color: 'grey' }
  }, [status])


  return <Tag color={item.color}>{item.label}</Tag>
}
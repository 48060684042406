
import { useProject } from "../../hooks/projects";
import Link from "../link/Link";
import ProjectShortCode from "./ProjectShortCode";


export default function ProjectLabel({ id, clickable = true }) {
  const { project } = useProject(id);

  const { code, name } = project || {};
  let label = <>
    {code && <><ProjectShortCode value={code} /> - </>}
    {name}
  </>;

  if (!clickable) {
    return label;
  }
  return <Link to={"/projects/" + id}>{label}</Link>;
}
import { Tag } from "antd";
import { pickingPriorities } from "../../hooks/pickingorders";
import { useMemo } from "react";



export default function PickingPriority({ value }) {
  value = value || 1;
  const { color, label } = useMemo(() =>
    pickingPriorities.find(p => p.value === value) || { color: 'red', label: 'Inconnu' }, [value])
  return <Tag color={color}>{label}</Tag>
}
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import dayjs from 'dayjs'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export function startWorkTime(date) {
  return new dayjs(date).set('hour', 8).set('minute', 0).set('second', 0).set('millisecond', 0)
}

export function endWorkTime(date) {
  return new dayjs(date).set('hour', 18).set('minute', 0).set('second', 0).set('millisecond', 0)
}

export function roundToStartWorkTimeIfNeeded(date) {
  date = new dayjs(date)
  const start = startWorkTime(date)
  if (date.isBefore(start)) {
    return start
  }
  return date
}

export function roundToEndWorkTimeIfNeeded(date) {
  date = new dayjs(date)
  const end = endWorkTime(date)
  if (date.isAfter(end)) {
    return end
  }
  return date
}


import { Timeline } from "antd";
import { useMemo } from "react";
import { formatCurrency, formatDate, formatDateTime } from "../../lib/format";
import TaskStatusLabel from "../tasks/TaskStatusLabel";
import SlotStatusLabel from "../slots/SlotStatusLabel";

function asColor(type) {
  switch (type) {
    case "create":
      return "green";
    case "update":
      return "blue";
    case "delete":
      return "red";
    default:
      return "grey";
  }
}

function asTypeLabel(type) {
  switch (type) {
    case "+":
      return <span style={{ color: "green" }}>+</span>;
    case "-":
      return <span style={{ color: "red" }}>-</span>;
    case "~":
    case "*":
      return <span style={{ color: "blue" }}>~</span>;
    default:
      return "";
  }
}

function asFieldLabelValue({ on, field, value }) {
  const [type] = on.split("-")
  switch (type + "." + field) {
    // Agency
    case "agency.name":
      return { label: "Agence", value }

    // Project
    case "prj.name":
      return { label: "Projet", value }
    case "prj.description":
      return { label: "Description", value }
    case "prj.agency_id":
      return { label: "Agence", value }
    case "prj.customer_code":
      return { label: "Code client", value }
    case "prj.commentcial_code":
      return { label: "Code commercial", value }
    case "prj.phone":
      return { label: "Téléphone", value }
    case "prj.address":
      return { label: "Adresse", value }
    case "prj.expected_start_date":
      return { label: "Date de début souhaitée", value: formatDate(value) }
    case "prj.expected_end_date":
      return { label: "Date de fin souhaitée", value: formatDate(value) }
    case "prj.margin_rate":
      return { label: "Marge", value: value + "%" }
    case "prj.code":
      return { label: "Code", value }


    // Resource
    case "resource.name":
      return { label: "Ressource", value }

    // Task
    case "task.id":
      return {}
    case "task.name":
      return { label: "Tâche", value }
    case "task.description":
      return { label: "Description", value }
    case "task.project_id":
      return { label: "Projet", value }
    case "task.prestation_id":
      return { label: "Prestation", value }
    case "task.parent_task_id":
      return { label: "Tâche parente", value }
    case "task.expected_level":
      return { label: "Niveau de compétence", value }
    case "task.type":
      return { label: "Type", value }
    case "task.priority":
      return { label: "Priorité", value }
    case "task.unit":
      return { label: "Unité de durée", value }
    case "task.cost_planned":
      return { label: "Coût planifié", value: formatCurrency(value) }
    case "task.planned_percent":
      return { label: "Pourcentage planifié", value: Math.round(value) + "%" }
    case "task.complete_percent":
      return { label: "Pourcentage terminé", value: Math.round(value) + "%" }
    case "task.duration":
      return { label: "Durée", value }
    case "task.duration_planned":
      return { label: "Durée planifiée", value }
    case "task.duration_complete":
      return { label: "Durée terminée", value }
    case "task.status":
      return { label: "Statut", value: <TaskStatusLabel value={value} /> }


    // Slot
    case "slot.id":
      return {}
    case "slot.start":
      return { label: "Début", value: formatDateTime(new Date(value)) }
    case "slot.end":
      return { label: "Fin", value: formatDateTime(new Date(value)) }
    case "slot.resource_id":
      return { label: "Ressource", value }
    case "slot.status":
      return { label: "Statut", value: <SlotStatusLabel status={value} /> }
    case "slot.duration":
      return { label: "Durée", value }
    case "slot.cancel_reason":
      return value ? { label: "Raison d'annulation", value } : {}
    case "slot.task_id":
    case "slot.agency_id":
    case "slot.project_id":
      return {}


    case "prestation.name":
      return { label: "Prestation", value }
    case "resource.capacity":
      return { label: "Capacité", value }
    case "resource.type":
      return { label: "Type", value }
    case "resource.level":
      return { label: "Niveau", value }
    default:
      return { label: field, value }
  }
}

function ChangeField({ on, field, type, value }) {
  const { label, value: val } = asFieldLabelValue({ on, field, value })
  if (typeof val === "object") {
    return null // Ignore
  }
  return label ? <div>
    {asTypeLabel(type)} {label}: <b>{val}</b>
  </div > : null

}

function ChangesDetails({ changes, on }) {
  if (Object.keys(changes || {}).length === 0) {
    return null;
  }

  return (
    <div style={{ paddingLeft: 10 }}>
      {Object.entries(changes).map(([k, { type, value }]) =>
        <ChangeField key={k} on={on} field={k} type={type} value={value} />
      )}
    </div>
  )
}


function ChangeLogItem({ item }) {
  const { label, changes, on } = item

  return (
    <div>
      <div>{label}</div>
      <ChangesDetails changes={changes} on={on} />
    </div>
  )
}

export default function ChangeLog({ changes }) {
  const items = useMemo(() => {
    return (changes || [])
      .sort((a, b) => b.date > a.date ? 1 : -1)
      .map((item) => {
        const { id, action, on, date, full_name } = item

        return {
          key: id,
          position: 'left',
          label: <div>
            <div style={{ fontSize: '0.9em', fontWeight: 500 }}>{formatDateTime(new Date(date))}</div>
            <div style={{ opacity: 0.5, fontWeight: 300, fontStyle: 'italic' }}>&mdash; {full_name}</div>
          </div>,
          children: <ChangeLogItem item={item} />,
          color: asColor(action),
        }
      })

  }, [changes])

  return (
    <div>

      <Timeline
        mode="left"
        items={items}
      />
    </div>
  );
}
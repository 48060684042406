import { DatePicker, Divider, Flex, Form, Input, Rate, Segmented, Switch } from "antd"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { ResourceForPrestationInput } from "../resources/ResourceForPrestationInput"
import SlotStatusLabel from "../slots/SlotStatusLabel"
import { listResourcePrestationsForResource } from "../../services/api/resources-prestations"
import { getPrestation } from "../../services/api/prestations"
import { set } from "date-fns"
import { formatCurrency, formatDateTime } from "../../lib/format"


export default function PlanningTaskSlotForm({ form, slot, slots, task, hideTaskInfo }) {

  const [prestationPrice, setPrestationPrice] = useState(0)
  const [plannable, setPlannable] = useState(0)

  const busyResources = useMemo(() => {
    const start = new dayjs(slot.start)
    const end = new dayjs(slot.end)
    return Array.from(new Set(slots?.filter(s => {
      if (s.id === slot.id) {
        return false; // it's the current slot, ignore it
      }
      if (start.isSameOrAfter(s.end)) {
        return false; // it's before the slot, ignore it
      }
      if (end.isSameOrBefore(s.start)) {
        return false; // it's after the slot, ignore it
      }
      return true;
    })
      .map(s => s.resource_id)))
  }, [slot, slots])

  const isUnit = task?.unit === 'u'
  const type = Form.useWatch("type", { form })
  const start = Form.useWatch(["dates", 0], { form })
  const auto = Form.useWatch("auto", { form })
  const dates = Form.useWatch("dates", { form })
  const fullDay = type === "task"

  const lastResource = useMemo(() => {
    if (!slots) {
      return
    }
    console.log('lastResource', slots)
    // return busyResources[busyResources.length - 1]
  }, [busyResources, slots])

  useEffect(() => {
    if (type !== "time" && isUnit) {
      form.setFieldsValue({ type: "time" })
      form.setFieldsValue({ auto: true })
    }
  }, [type, isUnit, form])

  const ressourceId = Form.useWatch("resource_id", { form })

  useEffect(() => {
    if (!task || !ressourceId) {
      return
    }
    (async () => {
      const basePresta = await getPrestation(task.prestation_id)
      let price = 0
      if (basePresta?.price) {
        price = basePresta.price
      }
      const prestas = await listResourcePrestationsForResource(ressourceId)
      const presta = prestas.find(p => p.prestation_id === task.prestation_id)
      if (presta?.price) {
        price = presta.price
      }


      setPrestationPrice(price)

    })()
  }, [ressourceId, task])

  const costInDays = useMemo(() => {
    if (!prestationPrice) {
      return 0
    }
    const step = 0.5
    const inv = 1 / step
    const { cost, planned_cost, complete_cost } = task.metrics
    const remaining = cost - planned_cost
    return Math.ceil((remaining / prestationPrice) * inv) / inv
  }, [prestationPrice, task]);

  // Plan until the end of the week
  // useEffect(() => {
  //   if (!form || !start || !costInDays) return

  //   let plainDays = Math.floor(costInDays)
  //   // const plainHours = Math.round((costInDays - plainDays) * 8)
  //   let halfDay = costInDays !== plainDays
  //   if (start.locale('fr').hour() > 12) {
  //     halfDay = !halfDay
  //     if (halfDay) {
  //       plainDays += 1
  //     }
  //   }
  //   plainDays = Math.min(plainDays, 6 - start.isoWeekday())


  //   let end = start.clone()

  //   if (plainDays > 0) {
  //     end = end.add(plainDays - 1, 'day')
  //   }

  //   if (halfDay) {
  //     end = end.set('hour', 12)
  //   } else {
  //     end = end.set('hour', 18)
  //   }

  //   setPlannable(plainDays + (halfDay ? 0.5 : 0))
  //   form.setFieldsValue({
  //     dates: [start, end]
  //   })
  // }, [start, form, costInDays])

  // Plan until the end of duration only on the working days
  useEffect(() => {
    if (!form || !start || !costInDays || !auto) return

    const dates = []
    let plainDays = Math.floor(costInDays)
    let halfDay = costInDays !== plainDays
    if (start.locale('fr').hour() > 12) {
      halfDay = !halfDay
      if (halfDay) {
        plainDays += 1
      }
    } else if (halfDay) {
      plainDays += 1
    }

    let d = start.clone()
    let rStart = null
    let rEnd = null

    const isWorkingDay = (d) => d.isoWeekday() < 6
    const commitDate = (rStart, rEnd, half = false) => {
      if (!rStart) return
      if (half) {
        rEnd = rEnd.set('hour', 12)
      } else {
        rEnd = rEnd.set('hour', 18)
      }
      dates.push(rStart, rEnd)
    }

    for (let i = plainDays; i > 0; d = d.add(1, 'day')) {
      if (!isWorkingDay(d)) {
        if (rStart) {
          commitDate(rStart, rEnd)
          rStart = null
          rEnd = null
        }
        continue
      }

      if (!rStart) {
        rStart = d.clone()
        rEnd = rStart.clone()
      } else {
        rEnd = d
      }
      i--
    }

    commitDate(rStart, rEnd, halfDay)


    // if (dates.length === 0) {
    //   dates.push(start, start.clone().add(1, 'day'))
    // }




    // let end = start.clone()

    // if (plainDays > 0) { // If we have at least one plain day
    //   end = end.add(plainDays - 1, 'day')
    // }

    // if (halfDay) {
    //   end = end.set('hour', 12)
    // } else {
    //   end = end.set('hour', 18)
    // }

    setPlannable(plainDays + (halfDay ? 0.5 : 0))
    form.setFieldsValue({
      dates
    })
  }, [start, form, costInDays, auto])

  const datesPairs = useMemo(() => {
    if (!dates || !auto) {
      return []
    }
    const pairs = []
    for (let i = 0; i < dates.length; i += 2) {
      const start = dates[i]
      const end = dates[i + 1]
      pairs.push([start.toDate(), end.toDate()])
    }
    return pairs
  }, [dates, auto])

  if (!task) {
    return null
  }


  const isNew = !slot.id



  return <Form
    form={form}
    labelCol={{ span: 6 }}>
    {task && !hideTaskInfo ? <>
      <Form.Item>
        <Flex justify="space-between" align="center">
          <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
          <SlotStatusLabel status={slot?.status} />
        </Flex>
        {task?.description && <div className="form-text" style={{ maxHeight: 200, overflow: 'auto' }}>
          {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
        </div>}
      </Form.Item>
      <Divider />
    </> : null}

    {task && <Form.Item label="Type" name="type" >
      <Segmented disabled={isUnit} options={[
        { value: 'task', label: 'Tâche' },
        { value: 'time', label: 'Planification' },
      ]} />
    </Form.Item>}
    {isNew && isUnit && <Form.Item label="Plannif auto." name="auto">
      <Switch />
    </Form.Item>}
    {(!fullDay) && <Form.Item label="Date" name="dates" rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
      <DatePicker.RangePicker format={"DD/MM/YYYY HH:mm"}
        showTime={{ format: 'HH:mm', minuteStep: 15 }}
        disabled={auto} />

    </Form.Item>}

    {fullDay && <>
      <Form.Item label="Date" name={["dates", 0]} rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
        <DatePicker format={"DD/MM/YYYY"} />
      </Form.Item>
      <Form.Item label="Durée estimée" name="duration" rules={[{ required: true, message: "Veuillez spécifier une durée" }]}>
        <Input type="number" suffix="Heures" style={{ maxWidth: 130 }} />
      </Form.Item>
    </>
    }

    <Form.Item label={task ? "Affecté à" : "Ressource"} name="resource_id" rules={[{ required: true, message: "Veuillez spécifier une ressource affectée" }]}>
      <ResourceForPrestationInput
        prestationId={task?.prestation_id}
        agencyId={task?.agency_id}
        expectedLevel={task?.expected_level}
        showPrice={isUnit}
        busyResources={busyResources} />
    </Form.Item>
    {(isUnit && auto) && <>
      <Form.Item label="Plannifiable" >
        <b>{costInDays}</b> jour{costInDays > 1 ? 's' : ''} à {formatCurrency(prestationPrice)}
      </Form.Item>



    </>}
    {auto && <Form.Item label="Dates" >
      <ul>
        {datesPairs?.map(([start, end], i) => <li key={i}>{formatDateTime(start)} - {formatDateTime(end)}</li>)}
      </ul>
    </Form.Item>}

  </Form>
}
const DEFAULT_DURATION_MS = 2500;

function open(message, type, duration) {
  console.log(`NOTIF (${type}): `, message);
}

export function notify(message, duration = 0) {
  return open(message, 'info', duration);
}

export function notifyError(message, duration = 0) {
  return open(message, 'error', duration);
}

export function notifySuccess(message, duration = 0) {
  return open(message, 'success', duration);
}

import { Flex, Tag, Tooltip } from "antd";
import ResourceIcon from "../resources/ResourceIcon";


export default function SlotResourceItemRenderer({ name, resource = {}, metrics }) {
  const { duration } = metrics || {};
  const percent = Math.round(100 * duration / (resource.capacity || 0));

  let color = percent > 100 ? 'red' : (
    percent >= 80 ? 'orange' : 'green'
  )

  return (
    <div className="slot-cell">
      <Flex gap="small" align="center" wrap="nowrap" style={{ flex: 1, minWidth: 0, }}>
        <ResourceIcon type={resource.type} size={0.7} />
        <span className="slot-text-truncated" style={{ flex: 1 }}>
          {name}
        </span>
        {false && resource.capacity > 0 ?
          <Tag color={color || 'default'}>
            <Flex align="center" gap={3}>
              <Tooltip title={`${duration} / ${resource.capacity}`}>
                {percent}%
              </Tooltip>
            </Flex>
          </Tag>
          : <Tag color={duration > 0 ? 'blue' : 'default'}>{duration || 0} jrs</Tag>}
      </Flex>
    </div >
  );
}
import { Form } from "antd";
import EditableContext from "./editableContext";


export default function TableEditableRow({ editableContext, index, ...props }) {
  const [form] = Form.useForm();
  const Ctx = editableContext || EditableContext;
  return (
    <Form form={form} component={false}>
      <Ctx.Provider value={form}>
        <tr {...props} />
      </Ctx.Provider>
    </Form>
  );
};


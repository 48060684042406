import { useMemo, useState } from "react";
import { slotStatuses, useSlotForm, useSlots } from "../../hooks/slots";
import { notifyError } from "../../services/notification";
import DateInput from "../inputs/DateInput";
import DayCountInput from "../inputs/DayCountInput";
import { ResourcesInput } from "../resources/ResourcesInput";
import clsx from "clsx";

import Styles from './SlotsTable.module.scss';
import { ResourceInput } from "../resources/ResourceInput";

function countDays(start, end) {
  if (start && end) {
    const startDay = new Date(start);
    const endDay = new Date(end);
    return Math.round((endDay - startDay) / (1000 * 60 * 60 * 24)) + 1;
  }
  return undefined;
}

function SlotRow({ slot, onAdd, onRemove }) {
  // const {
  //   resource, isNewResource,
  //   errors, isValid, handleSubmit,
  //   setResource, saveResource, deleteResource,
  //   register,
  // } = useResourcePrestationForm(slot.id || '');

  const [changed, setChanged] = useState(false);

  const {
    // slot: slotForm,
    values, setValues,
    errors, isValid, handleSubmit,
    saveSlot, deleteSlot,
    register,
  } = useSlotForm(slot, () => {
    // On change
    setChanged(true);
  });


  const dayCount = useMemo(() => {
    return countDays(values.start, values.end);
  }, [values.start, values.end]);

  const onCreate = () => {
    (async () => {
      try {
        const item = await saveSlot(values);
        if (item) {
          onAdd();
        }
        setValues({
          ...slot
        });
      } catch (err) {
        notifyError(err);
      }
    })();
  }

  const onUpdate = () => {
    (async () => {
      try {
        const item = await saveSlot(values);
        if (item) {
          setChanged(false);
        }
      } catch (err) {
        notifyError(err);
      }
    })();
  }

  const onCancel = () => {
    setValues(slot);
    setChanged(false);
  }


  return (
    <tr className={clsx("align-middle", {
      // "table-success": slot.id && !changed,
      "table-warning": slot.id && changed,
      // "table-danger": !isValid && changed,
      "table-secondary": !slot.id,
    })}>
      <td style={{ width: "6em" }}>
        <DateInput max={values.end} {...register("start", { className: "form-control" })} />
      </td>
      <td style={{ width: "6em" }}>
        <DateInput min={values.start} {...register("end", { className: "form-control" })} />
      </td>
      <td className="text-center" style={{ width: "3em" }}>
        {dayCount}j
      </td>
      <td style={{ maxWidth: 180 }}>
        <ResourceInput
          {...register("resource_id", { className: "form-control" })} />

      </td>
      <td>
        <select
          {...register("status", { className: "form-select form-select" })}>
          {slotStatuses.map(({ label, value }) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </td>

      <td style={{ width: 180, textAlign: 'center' }}>
        {(
          slot.id ? (
            changed ? (
              <div class="btn-group">
                <button type="button" className="btn btn-sm btn-light" onClick={() => onCancel()}>Annuler</button>
                <button type="button" className="btn btn-sm btn-primary" onClick={() => onUpdate()} disabled={!isValid}>Enregistrer</button>
              </div>
            ) : (
              <button type="button" className="btn btn-sm btn-danger" onClick={() => (deleteSlot(slot), onRemove())}>Supprimer</button>
            )) : (
            <button type="button" className="btn btn-sm btn-primary" onClick={() => onCreate()} disabled={!isValid}>Ajouter</button>
          )
        )}
      </td>
    </tr>
  )
}




export default function SlotsTable({
  task_id, resource_id,
  cost, duration,
  className }) {
  const [slots, refresh] = useSlots({ task_id, resource_id });

  const defaultSlot = useMemo(() => {
    const lastSlot = slots[slots.length - 1] || {
      start: new Date().toISOString().substring(0, 10),
      end: new Date().toISOString().substring(0, 10),
    };

    return {
      status: 'pre-planned',
      start: lastSlot.start,
      end: lastSlot.start,
      task_id: task_id,
      resource_id: lastSlot.resource_id,
    }
  }, [task_id, slots]);

  const displayedSlots = useMemo(() => {
    return slots.sort((a, b) => {
      return a.start.localeCompare(b.start);
    });
  }, [slots]);

  const { totalCost, totalDuration } = useMemo(() => {
    let totalCost = 0;
    let totalDuration = 0;
    for (const slot of slots) {
      totalCost += slot.cost || 0;
      totalDuration += countDays(slot.start, slot.end);;
    }
    return {
      totalCost,
      totalDuration,
    }
  }, [slots]);

  const onAdd = () => {
    refresh();
  }

  const onRemove = () => {
    refresh();
  }



  return (
    <>
      <div className={Styles.kpis}>
        <div className={Styles.kpi}>
          <div className={Styles.kpiValue}>{totalCost}€</div>
          <div className={Styles.kpiLabel}>Coût total</div>
        </div>
        <div className={Styles.kpi}>
          <div className={Styles.kpiValue}>{duration - totalDuration}j</div>
          <div className={Styles.kpiLabel}>Reste à planifier</div>
        </div>
        <div className={Styles.kpi}>
          <div className={Styles.kpiValue}>{totalDuration}j</div>
          <div className={Styles.kpiLabel}>Durée totale</div>
        </div>
      </div>
      <table className={clsx("table table-striped table-hover", className)}>
        <thead>
          <tr>
            <th>Début</th>
            <th>Fin</th>
            <th>Durée</th>
            <th>Ressource</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {displayedSlots.map((e) => (
            <SlotRow key={e.id} slot={e} onRemove={onRemove} />
          ))}
        </tbody>
        <tfoot className="table-group-divider">
          <SlotRow slot={defaultSlot} onAdd={onAdd} />
        </tfoot>
      </table>
    </>
  );
}
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createDelivery, getDelivery, invalidateDelivery, listDeliveries, removeDelivery, updateDelivery,

} from '../services/api/deliveries';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { invalid } from 'moment';



const INVALIDATION_KEY = Symbol('deliveries');


function getDefaults() {
  return {
    name: "",
    capacities: {},
    orders: [],
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return getDefaults();
  }
  try {
    return {
      ...getDefaults(),
      ...(await loaderWrap(getDelivery(id))),
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useDeliveries({ warehouseId, orderId, onlyActive }) {
  const [deliveries, setDeliveries] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if (!warehouseId && !orderId) {
      setDeliveries([]);
      return;
    }
    (async () => {
      try {
        setComplete(false);
        const deliveries = await loaderWrap(listDeliveries({ warehouseId, orderId, onlyActive }));
        setDeliveries(deliveries);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, warehouseId, orderId, onlyActive]);

  const refresh = useCallback(() => {
    invalidateDelivery();
    invalidate();
  }, [invalidate]);

  return [deliveries, refresh, complete];
}

export function useDelivery(id) {
  const [delivery, setDelivery] = useState();

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      setDelivery(await getItem(id));
    })();
  }, [id, counter]);

  const saveDelivery = useCallback(async (item) => {
    if (!item) {
      throw new Error('No delivery');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateDelivery(item));
    } else {
      out = await loaderWrap(createDelivery(item));
    }
    setDelivery(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteDelivery = useCallback(async () => {
    await loaderWrap(removeDelivery(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    delivery,
    reloadDelivery: () => invalidate(id),
    isNewDelivery: !delivery?.id,
    setDelivery,
    saveDelivery,
    deleteDelivery,
  };
}





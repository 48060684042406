
import React from 'react';
import { Outlet } from 'react-router-dom';




export default function Tasks() {
  return (<>
    <Outlet />
  </>);
}

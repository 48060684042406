import {
  mdiDrawing,
  mdiPackageVariantClosed, mdiSelect,
  mdiTruckDeliveryOutline,

} from '@mdi/js';
import Icon from '@mdi/react';
import { Flex, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';

function icon(value) {
  switch (value) {
    case 'task':
      return mdiDrawing;
    case 'delivery':
      return mdiTruckDeliveryOutline;
    case 'shipment':
      return mdiPackageVariantClosed;
    default:
      return mdiSelect;
  }
}

export function ID({ value, variant }) {
  const [type, id] = (value || '').split('-');


  const { label, labelShort } = useMemo(() => {
    return {
      label: id,
      labelShort: id?.slice(0, 5),
    };
  }, [id]);

  switch (variant) {
    case 'text':
      return <span title={label}>{labelShort}</span>;
    case 'tag':
    default:
      return (
        <span className="id" title={label}>
          <Icon path={icon(type)} size={0.5} style={{ marginRight: '0.2em', marginTop: '-0.2em' }} />
          <span>{labelShort}</span>
        </span>
      );
  }
}

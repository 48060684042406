import { useMemo } from "react";
import { indexBy } from "../../lib/utils";
import { Flex, Table } from "antd";
import { WarningOutlined } from "@ant-design/icons";



export default function ShipmentMissingProducts({ items, order }) {
  const missingItems = useMemo(() => {
    const lines = indexBy(order.items, 'line_number');
    const missingItems = items?.filter(i => i.expected_quantity > i.picked_quantity);
    return missingItems?.map(item => ({
      ...(lines[item.line_number] || {}),
      ...item
    }));
  }, [items, order])
  return <Flex vertical gap="small">
    <div style={{
      fontSize: '1.2em',
      fontWeight: 'bold',
      color: 'red'
    }}><WarningOutlined /> Produits manquants</div>
    <Table
      dataSource={missingItems}
      rowKey="line_number"
      size="small"

      columns={[
        {
          title: 'Réf',
          dataIndex: 'item_code',
          key: 'item_code',
        },
        {
          title: 'Nom',
          dataIndex: 'label',
          key: 'label',
        },
        {
          title: 'Attendu',
          dataIndex: 'expected_quantity',
          key: 'expected_quantity',
        },
        {
          title: 'Préparé',
          dataIndex: 'picked_quantity',
          key: 'picked_quantity',
        },
        {
          title: 'Manquant',
          key: 'missing',
          render: (item) => <b>{item.expected_quantity - item.picked_quantity}</b>
        }
      ]}
    />

  </Flex>
}
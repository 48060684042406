import { useMemo } from "react"
import { taskStatuses } from "../../hooks/tasks"
import { Badge, Flex, Progress, Select, Space, Tag } from "antd"
import { indexBy } from "../../lib/utils"

const taskStatusesIdx = indexBy(taskStatuses, 'value')

export default function TaskStatusLabel({ value, progress, dot, editable, onChange, allowedStatuses = [] }) {

  const { label, color } = useMemo(() => {
    return taskStatusesIdx[value] || { label: value }
  }, [value])

  const options = useMemo(() => {
    return taskStatuses.filter((s) => !s.hidden && (!allowedStatuses?.length || allowedStatuses.includes(s.value)))
  }, [allowedStatuses])

  if (editable) {
    return <Select
      variant="borderless"
      // mode="tags"
      size="small"
      fieldNames={{
        label: "label",
        value: "value",
      }}
      style={{ color: '#fff', minWidth: 120 }}
      optionRender={(option) => (
        <span style={{ color: option.data.color }}>
          {option.data.label}
        </span>
      )}
      // maxCount={1}
      maxTagCount={1}
      labelRender={(props) => {
        const { color } = taskStatusesIdx[props.value]
        return (
          <Tag color={color || 'default'} style={{ cursor: 'pointer' }}>
            <Flex align="center" gap={3}>
              {label}
              {progress !== undefined ? <Progress
                type="circle"
                strokeColor={"#ffffffee"}
                trailColor="#ffffff55"
                strokeWidth={15}
                percent={progress}
                size={14}
              /> : null}
            </Flex>
          </Tag>
        );
      }}
      value={value}
      onChange={onChange}
      options={options}
    />
  }

  if (dot) {
    if (color === 'default') {
      return <Badge status="default" dot title={label} />
    }
    return <Badge color={color || 'default'} dot title={label} />
  }

  return <Tag color={color || 'default'}>
    <Flex align="center" gap={3}>
      {label}
      {progress !== undefined ? <Progress
        type="circle"
        strokeColor={"#ffffffee"}
        trailColor="#ffffff55"
        strokeWidth={15}
        percent={progress}
        size={14}
      /> : null}
    </Flex>
  </Tag>
}

import { Select } from "antd";
import { usePickingPools, useSelectedPickingPool, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";


export default function PickingPoolSwitcher({ variant }) {
  const [pickingpools] = usePickingPools();
  const [pickingPoolID, setPickingPoolId] = useSelectedPickingPoolID();


  const pickingPoolOptions = useMemo(() => {
    const out = [
    ];
    if (!pickingpools) {
      return out;
    }
    return [...out, ...pickingpools.map((o) => ({ value: o.id, label: o.name }))]
  }, [pickingpools]);

  const onChange = (value) => {
    setPickingPoolId(value);
    setTimeout(() => window.location.reload(), 100)
  }


  return (
    <Select
      placeholder="Choisir un pool de préparation..."
      variant={variant}
      allowClear={true}
      value={pickingPoolID || ''}
      options={pickingPoolOptions}
      onChange={onChange}
      style={{ width: 200 }}
    />
  );
}
import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/items');

const { invalidateCache, withCache } = cache();

function mapFromItemModel(input) {
  return { ...input };;
}

function mapToItemModel(input) {
  return { ...input };;
}

export async function listItems({ agency_id } = {}) {
  const items = await withCache(async () => {
    const { items } = await api.get(null, {}, {
      agency_id: agency_id || ''
    });
    return items;
  }, '')
  return items ? items.map(mapToItemModel) : [];
}

export async function getStocks() {
  const items = await withCache(async () => {
    const { items } = await api.get('stocks');
    return items;
  }, 'stocks')
  return items || {};
}

export async function getItem(id) {
  let item = await withCache(async () => {
    const res = await api.get(id);
    if (!res.item) {
      throw new Error('Item not found');
    }
    return res;
  }, id)
  item = mapToItemModel(item.item);
  return { item: item };
}

export function invalidateItemsCache() {
  invalidateCache()
}

import { mdiAccount, mdiCheck, mdiClock, mdiMapMarker, mdiPhone } from '@mdi/js';
import Styles from './Event.module.scss';
import { formatDateTime } from '../../lib/format';
import { Affix, Badge, Button, Drawer, Flex, Modal, Radio, Space } from 'antd';
import Icon from '@mdi/react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { setCalendarData } from '../../services/api/calendar';
import { loaderWrap } from '../../services/loader';
import { slotCancelReasons } from '../../hooks/slots';
import useWindowDimensions from '../../hooks/window';
import { CheckCircleOutlined, CloseOutlined } from '@ant-design/icons';
import EventConfirm from './EventConfirm';


function linkToMaps(address) {
  return "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent(address)
}


export default function EventInfo({ project, task, slot, signature, resource, me, onChange }) {
  const { accessKey, slotId, token } = useParams();

  const [showCancelModal, setShowCancelModal] = useState(false)
  const [cancelReason, setCancelReason] = useState(null)

  const [showConfirmModal, setShowConfirmModal] = useState(false)

  const mine = me?.id === resource?.id
  const onCancel = () => {
    setShowCancelModal(false)
    loaderWrap(setCalendarData(accessKey, slotId, token, {
      cancel: {
        reason: cancelReason
      }
    }))
  }

  const onConfirm = () => {
    setShowConfirmModal(false)
    onChange()
    setTimeout(() => {
      window.close()
    }, 200)
  }

  // Fix SVG signature
  signature = (signature || '').replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg" version="1.1"')

  return <><div>
    <p className={Styles.description}>
      {task?.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
    </p>
    <div className={Styles["task-param"]}>
      <Flex align="center" gap="small">
        <div className={Styles['task-param-icon']}>
          <Icon path={mdiAccount} />
        </div>  {mine ? 'Moi' : resource?.name}
      </Flex>
    </div>
    {project.address && <div className={Styles["task-param"]}>
      <Flex align="center" gap="small">
        <div className={Styles['task-param-icon']}>
          <Icon path={mdiMapMarker} />
        </div>
        <a href={linkToMaps(project.address)} target="_blank" rel="noreferrer">{project?.address}</a>
      </Flex>
    </div>}
    {project.phone && <div className={Styles["task-param"]}>
      <Flex align="center" gap="small">
        <div className={Styles['task-param-icon']}>
          <Icon path={mdiPhone} />
        </div>
        <a href={"tel:" + project.phone}>{project?.phone}</a>
      </Flex>
    </div>}
    <div className={Styles["task-param"]}>
      <Flex align="center" gap="small">
        <div className={Styles['task-param-icon']}>
          <Icon path={mdiClock} />
        </div>  {formatDateTime(slot?.start)} - {formatDateTime(slot?.end)}
      </Flex>
    </div>
    {slot.status === 'done' && signature && (
      <div className={Styles['task-signature']}>
        <Badge.Ribbon text={<Space>
          <CheckCircleOutlined />
          Signature client</Space>} color='green'>
          <img src={"data:image/svg+xml;utf8," + (signature)} alt="signature" style={{
            width: '100%',
            height: 'auto',
          }} />
        </Badge.Ribbon>

      </div>

    )}
    {slot.status !== 'done' && slot.status !== 'canceled' &&
      <div className={Styles['control-bar']}>
        <Button type="primary" size='large' danger onClick={() => setShowCancelModal(true)}>Annuler</Button>
        <Button type="primary" size='large' onClick={() => setShowConfirmModal(true)} >Confirmer</Button>
      </div>
    }

  </div>
    <Drawer
      title="Indiquez la raison de l'annulation"
      placement='bottom'
      height="95vh"
      open={showCancelModal}
      closable={false}
      destroyOnClose={true}
      onClose={() => setShowCancelModal(false)}
      footer={<Flex justify="space-between" >
        <Button size='large' key="close" onClick={() => setShowCancelModal(false)} icon={<CloseOutlined />} />
        <Button size='large' key="confirm" type="primary" onClick={onCancel} disabled={!cancelReason} >Valider</Button>
      </Flex>}
    >

      <Radio.Group
        size='large'
        value={cancelReason}
        onChange={(e) => {
          setCancelReason(e.target.value)
        }}>
        <Space direction="vertical">
          {slotCancelReasons.map(({ value, label }) =>
            <Radio key={value} value={value}>{label}</Radio>)}
        </Space>
      </Radio.Group>

    </Drawer>
    <Drawer
      placement='bottom'
      title="Confirmation"
      closable={false}
      open={showConfirmModal}
      height="95vh"
      // size='large'
      onClose={() => setShowConfirmModal(false)}
    >
      {showConfirmModal
        ? <EventConfirm
          onComplete={onConfirm}
          onCancel={() => setShowConfirmModal(false)}
          slot={slot}
        />
        : null}
    </Drawer>

  </>
}
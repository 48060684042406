import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  createAgency, getAgency, getStocks, listItems, removeAgency, updateAgency,
} from '../services/api/items';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';
import { cache } from '../services/cache';
import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useForm } from './form';
import { add } from 'date-fns';
import { get } from 'aws-amplify/api';

const INVALIDATION_KEY = Symbol('items');




export function useItems() {
  const [items, setItems] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listItems()));
        const terms = await loaderWrap(listItems());
        setItems(terms);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);

  return [items, refresh, complete];
}


export function useItemsStocks() {
  const [stocks, setStocks] = useState({});
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);
        // const terms = await withCache(async () => loaderWrap(listItems()));
        const stocks = await loaderWrap(getStocks());
        setStocks(stocks);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter]);

  const refresh = useCallback(() => {
    invalidate();
  }, [invalidate]);



  return {
    getVirtualStock: useCallback((warehouseCode, itemCode) => {
      return stocks[warehouseCode]?.[itemCode]?.[1] || 0;
    }, [stocks]),
    getPhysicalStock: useCallback((warehouseCode, itemCode) => {
      return stocks[warehouseCode]?.[itemCode]?.[0] || 0;
    }, [stocks]),
    refresh,
    complete,
  }
}
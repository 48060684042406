

export function indexBy(list, field) {
  return (list || []).reduce((idx, item) => {
    const key = String(typeof field === 'function' ? field(item) : item[field])
    idx[key] = item
    return idx
  }, {})
}

export function indexMultipleBy(list, field) {
  return (list || []).reduce((idx, item) => {
    const key = String(typeof field === 'function' ? field(item) : item[field])
    idx[key] = idx[key] || []
    idx[key].push(item)
    return idx
  }, {})
}
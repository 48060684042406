import { cache } from '../cache';
import { withApi } from './api';

const api = withApi('chronos', '/deliveries');
const { invalidateCache, withCache } = cache();

function mapFromDeliveryModel(input) {
  return { ...input };;
}

function mapToDeliveryModel(input) {
  return { ...input };;
}


export async function listDeliveries({ warehouseId, orderId, onlyActive } = {}) {
  const params = {
    order_id: orderId || '',
    warehouse_id: warehouseId || '',
    active_only: onlyActive || '',
  };
  const items = await withCache(async () => {
    const { items } = await api.get('', {}, params);
    return items;
  }, params)
  return items ? items.map(mapToDeliveryModel) : [];
}

export async function getDelivery(id) {
  const item = await withCache(async () => {
    const { item } = await api.get(id);
    if (!item) {
      throw new Error('Delivery not found');
    }
    return item;
  }, id)

  return mapToDeliveryModel(item);;
}

export async function createDelivery(delivery) {
  const { item } = await api.post('', { item: mapFromDeliveryModel(delivery) });
  if (item) {
    invalidateCache(item.id)
    return mapToDeliveryModel(item)
  }
  return undefined;
}

export async function updateDelivery(delivery) {
  const res = await api.put('', { item: mapFromDeliveryModel(delivery) });
  invalidateCache(delivery.id)
  return res ? delivery : undefined;
}

export async function updateDeliveryOrders(id, orders) {
  const res = await api.put(id + "/orders", { orders });
  invalidateCache(id)
  return
}



export async function removeDelivery(id) {
  const res = await api.delete(id);
  invalidateCache(id)
  return !!res;
}



export function invalidateDelivery(id) {
  invalidateCache(id);
}
import Icon from '@mdi/react';
import { NavLink } from 'react-router-dom';
import {
  mdiSelectGroup,
  mdiHome,
  mdiAccountHardHatOutline,
  mdiProgressWrench,
  mdiCalendarMonthOutline,
  mdiDrawing,
  mdiOfficeBuildingOutline,
  mdiStoreOutline,
  mdiNut,
  mdiAnimationOutline,
  mdiWarehouse,
  mdiFileImportOutline,
  mdiFormatListBulleted,
  mdiPackageVariantClosed,
  mdiSortBoolDescendingVariant,
  mdiTrayFull,

} from '@mdi/js';

import { Divider, Tag } from 'antd';
import logo from '../../assets/logo.png';
import rigouste from '../../assets/rigouste.png';

import './AppSidebar.scss';
import { useSelectedAgencyID } from '../../hooks/agencies';
import { useSelectedPickingPool, useSelectedPickingPoolID } from '../../hooks/pickingpools';
import { useAuth } from '../../hooks/auth';

export default function AppSidebar() {

  const [agencyId] = useSelectedAgencyID();
  const [pickingPool] = useSelectedPickingPool();
  const { can, email } = useAuth();

  const showRigouste = false && email === 'tv.aquadoc@gmail.com';
  const linkClass = (baseClasses = '') => ({ isActive }) => baseClasses + (isActive ? ' is-active' : '');
  return (
    <section className="AppSidebar app-sidebar">
      <hgroup className="logo">
        <img src={showRigouste ? rigouste : logo} alt="logo" />
        <h1 onClick={() => window.location.reload()}>
          Chronos
        </h1>
        {showRigouste && <h2>
          by Rigouste
        </h2>}
      </hgroup>
      {process.env.REACT_APP_ENV !== 'production' && (<p>
        <Tag color="#f50">{process.env.REACT_APP_ENV}</Tag>
      </p>)}
      <nav className="menu">
        {can('order.show') &&
          <NavLink to="/orders" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiAnimationOutline} size={1} />
            </span>
            <span className="text">Commandes</span>
          </NavLink>}
        {(can('picking.show') || can('shipment.show')) && pickingPool?.is_main ?
          <NavLink to="/shipments" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiPackageVariantClosed} size={1} />
            </span>
            <span className="text">Expéditions</span>
          </NavLink>
          : null}
        {can('planning.show') &&
          <NavLink to="/planning" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiCalendarMonthOutline} size={1} />
            </span>
            <span className="text">Planning</span>
          </NavLink>
        }
        {can('project.show') &&
          <NavLink to="/projects" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiSelectGroup} size={1} />
            </span>
            <span className="text">Affaires</span>
          </NavLink>
        }
        {can('task.show') &&
          <NavLink to="/tasks" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiDrawing} size={1} />
            </span>
            <span className="text">Tâches</span>
          </NavLink>
        }
      </nav>
      <Divider />
      <nav className='menu' >
        {can('agency.show') && !agencyId && <NavLink to="/agencies" className={linkClass()}>
          <span className="icon">
            <Icon path={mdiStoreOutline} size={1} />
          </span>
          <span className="text">Agences</span>
        </NavLink>}
        {can('resource.show') &&
          <NavLink to="/resources" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiAccountHardHatOutline} size={1} />
            </span>
            <span className="text">Ressources</span>
          </NavLink>
        }
        {can('prestation.show') &&
          <NavLink to="/prestations" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiProgressWrench} size={1} />
            </span>
            <span className="text">Prestations</span>
          </NavLink>
        }
        {can('item.show') &&
          <NavLink to="/items" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiNut} size={1} />
            </span>
            <span className="text">Produits</span>
          </NavLink>
        }
        {can('warehouse.show') &&
          <NavLink to="/warehouses" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiWarehouse} size={1} />
            </span>
            <span className="text">Dépôts</span>
          </NavLink>
        }
        {can('pickingpool.show') &&
          <NavLink to="/pickingpools" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiTrayFull} size={1} />
            </span>
            <span className="text">Pools</span>
          </NavLink>
        }
        {/* {can('import.create') &&
          <NavLink to="/imports" className={linkClass()}>
            <span className="icon">
              <Icon path={mdiFileImportOutline} size={1} />
            </span>
            <span className="text">Imports</span>
          </NavLink>
        } */}
      </nav>
    </section >
  );
}

import { useCallback, useMemo, useState } from 'react';
import { usePersistentState } from './state';

export function removeAccents(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const sanCache = new Map();
export function sanitizeString(str, withCache = false, split = null) {
  if (withCache && sanCache.has(str)) {
    return sanCache.get(str);
  }
  const v = removeAccents((str || '').trim().toLowerCase());
  if (withCache) {
    sanCache.set(str, v);
  }
  return v;
}


export function useSearcher(list, filter) {
  const [search, setSearch] = useState('');

  const filtered = useMemo(() => {
    const s = sanitizeString(search);
    if (!s) {
      return list;
    }
    const l = list.filter((item) => filter(item, s, sanitizeString));
    return l
  }, [list, search, filter]);

  return {
    list: filtered,
    search,
    setSearch,
  };
}


export function useExtendedPersistentSearcher(key, list, filter) {
  const [search, setSearch] = usePersistentState(key + ".search", { values: [] });

  const filtered = useMemo(() => {

    const words = sanitizeString((search?.text || []).join(' ')).split(/\s+/)

    const fulltextSearch = (str) => {
      const text = sanitizeString(str);
      for (let i = 0; i < words.length; i++)
        if (!text.includes(words[i])) return false;
      return true;
    }
    const l = list.filter((item) => filter(item, search, fulltextSearch));
    return l
  }, [list, search, filter]);

  return {
    list: filtered,
    search,
    setSearch,
    addSearch: useCallback((type, value) => {
      const values = (search.values || [])
      if (!values.includes(value)) {
        setSearch({
          ...search,
          [type]: [...search[type] || [], value],
          values: [...values, value]
        });
      }
    }, [search, setSearch]),
  };
}

export function createSearchOptions(list, fn) {
  if (!list) return []
  const m = new Map()
  for (let i = 0; i < list.length; i++) {
    const item = fn(list[i])
    if (item && (item.id || item.value) && !m.has(item.id)) {
      m.set((item.id || item.value), item)
    }
  }
  return Array.from(m.values())
}


export function useSearchOptionsMemo(list, fn, deps = []) {
  return useMemo(() => createSearchOptions(list, fn), [list, fn, ...deps])
}
import { DatePicker, Divider, Form, Input, Modal, Segmented, Space, Switch } from "antd"
import { useEffect, useState } from "react"
import TaskTypeLabel from "../../components/tasks/TaskTypeLabel"
import { PrestationInput } from "../../components/prestations/PrestationInput"
import { AgencyInput } from "../../components/agencies/AgencyInput"
import { ProjectInput } from "../../components/projects/ProjectInput"
import { ResourceInput } from "../../components/resources/ResourceInput"
import TaskTypeInput from "../../components/tasks/TaskTypeInput"
import { ResourceForPrestationInput } from "../../components/resources/ResourceForPrestationInput"
import { getProject } from "../../services/api/projects"
import { endWorkTime, roundToEndWorkTimeIfNeeded, roundToStartWorkTimeIfNeeded, startWorkTime } from "../../components/planning/utils"
import { duration } from "moment"


export default function PlanningNewTaskModal({ onSubmit, onClose }) {

  const [showSlot, setShowSlot] = useState(false)
  const [project, setProject] = useState(null)
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  const onOkHandler = () => {
    form
      .validateFields()
      .then(async ({ task, slot }) => {

        task = {
          ...task,
          agency_id: project?.agency_id,
        }

        if (showSlot) {
          let start, end;
          if (slot.type === 'task') {
            start = startWorkTime(slot.dates[0])
            end = endWorkTime(slot.dates[0])
          } else {
            start = roundToStartWorkTimeIfNeeded(slot.dates[0])
            end = roundToEndWorkTimeIfNeeded(slot.dates[1])
          }

          if (end.isBefore(start)) {
            start = end
          }

          slot = {
            ...slot,
            agency_id: task.agency_id,
            project_id: task.project_id,
            start: start.toISOString(),
            end: end.toISOString()
          }
        } else {
          slot = null
        }

        setLoading(true)
        try {
          await onSubmit(task, slot)
        } finally {
          setLoading(false)
          onClose()
        }
      })
  }



  const slotType = Form.useWatch(["slot", "type"], { form })
  const prestationId = Form.useWatch(["task", "prestation_id"], { form })
  const project_id = Form.useWatch(["task", "project_id"], { form })
  const fullDay = slotType === "task"

  useEffect(() => {
    form.setFieldsValue({
      task: {
        type: "task",

        // name: "test",
        // project_id: "prj-AF0100018297PRC00",
        // prestation_id: "pre-RD2GULAAPJ",
      },
      slot: {
        type: "task",
        // resource_id: "res-D2JM6OAD4V",
        duration: 1
      }
    })
    // form.setFieldValue(["task", "type"], "task")
    // form.setFieldValue(["slot", "type"], "task")
  }, [form])

  useEffect(() => {
    if (project_id) {
      (async () => {
        const { item } = await getProject(project_id)
        setProject(item)
      })()
    }
  }, [project_id])



  return <Modal
    title="Nouvelle tâche"
    centered
    loading={loading}
    open
    destroyOnClose={true}
    onOk={() => onOkHandler()}
    onCancel={() => onClose()}
  >
    <Form form={form}

      labelCol={{ span: 5 }}>
      <Divider orientation="left" >Tâche</Divider>
      <Form.Item label="Nom" name={['task', 'name']} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Type" name={['task', 'type']}>
        <TaskTypeInput />
      </Form.Item>


      <Form.Item label="Affaire" name={['task', 'project_id']} rules={[{ required: true }]}>
        <ProjectInput />
      </Form.Item>

      <Form.Item label="Description" name={['task', 'description']}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Prestation" name={['task', 'prestation_id']} rules={[{ required: true }]}>
        <PrestationInput />
      </Form.Item>


      <Divider orientation="left">
        <Space>
          Créneau
          <Switch size="small" checked={showSlot} onChange={(v) => setShowSlot(v)} />
        </Space>
      </Divider>

      {showSlot && <>
        <Form.Item label="Type" name={["slot", "type"]}>
          <Segmented options={[
            { value: 'task', label: 'Tâche' },
            { value: 'time', label: 'Plage horaire' },
          ]} />
        </Form.Item>
        {!fullDay && <Form.Item label="Date" name={["slot", "dates"]} rules={[{ required: true, message: "Veuillez spécifier un intervale de date" }]}>
          <DatePicker.RangePicker format={"DD/MM/YYYY HH:mm"} showTime={{ format: 'HH:mm', minuteStep: 15 }} />
        </Form.Item>}
        {fullDay && <>
          <Form.Item label="Date" name={["slot", "dates", 0]} rules={[{ required: true, message: "Veuillez spécifier une date" }]}>
            <DatePicker format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item label="Durée estimée" name={["slot", "duration"]} rules={[{ required: true, message: "Veuillez spécifier une durée" }]}>
            <Input type="number" suffix="Heures" style={{ maxWidth: 130 }} min={1} />
          </Form.Item>
        </>}
        <Form.Item label="Affecté à" name={["slot", "resource_id"]} rules={[{ required: true, message: "Veuillez spécifier une ressource" }]}>
          <ResourceForPrestationInput
            prestationId={prestationId}
            agencyId={project?.agency_id}
          // expectedLevel={task?.expected_level}
          // busyResources={busyResources}
          />
        </Form.Item>
      </>}
    </Form>
  </Modal>
}

import { useCallback, useRef, useState } from "react";
import { useSetting } from "./settings";


export function usePersistentState(key, initialValue) {
  return useSetting(key, initialValue);
}



export function useNoRenderingState(initialValue) {
  const state = useRef(initialValue);
  const setState = (value) => {
    state.current = value;
  };
  return [state.current, setState];
}

export function useDelayedRenderingState(initialValue, delay = 1) {
  const ref = useRef({ changed: false, data: initialValue, timeout: null });
  const [state, _setState] = useState(initialValue);

  const current = ref.current;

  const setState = useCallback((value) => {
    if (current.timeout) {
      clearTimeout(current.timeout);
    }

    if (typeof value === 'function') {
      value = value(current.data);
    }

    current.data = value;
    current.changed = true;
    current.timeout = setTimeout(() => {
      if (current.changed) {
        _setState(current.data);
        current.changed = false;
      }
    }, delay);
  }, []);

  return [state, setState];
}


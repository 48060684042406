import { useMemo } from "react"
import { taskStatuses } from "../../hooks/tasks"
import { Badge, Flex, Progress, Space, Tag } from "antd"



export default function TaskStatusBadge({ value, progress, children, ...props }) {
  const { label, color } = useMemo(() => {
    return taskStatuses.find(s => s.value === value) || { label: value }
  }, [value])


  if (color === 'default') {
    return <Badge status="default" title={label} {...props}>{children}</Badge>
  }

  return <Badge color={color || 'default'} title={label} {...props} >{children}</Badge>

}

import { useParams } from "react-router-dom";
import { useOrder } from "../../hooks/orders";
import { Flex, Form, Table } from "antd";
import { formatCurrency, formatDate } from "../../lib/format";
import AgencyLabel from "../agencies/AgencyLabel";
import ProjectLabel from "../../components/projects/ProjectLabel";
import { useWarehouses } from "../../hooks/warehouses";
import { useMemo } from "react";
import { indexBy } from "../../lib/utils";
import OrderMessagesView from "../../components/orders/OrderMessagesView";
import OrderStatus from "../../components/orders/OrderStatus";
import OrderItemStatus from "../../components/orders/OrderItemStatus";
import OrderShippingModeLabel from "../../components/orders/OrderShippingModeLabel";


export default function OrderView() {
  const { orderId } = useParams();

  const { order } = useOrder(orderId);
  const [warehouses] = useWarehouses();
  const warehousesById = useMemo(() => indexBy(warehouses, 'id'), [warehouses]);

  return (<>
    <Flex gap="large">
      <div className="box" style={{ flex: 1 }}>
        <Form layout="horizontal">
          <Form.Item label="Numéro de commande">
            {order?.order_number}
          </Form.Item>
          <Form.Item label="Affaire">
            <ProjectLabel id={order?.project_id} />
          </Form.Item>
          <Form.Item label="Date de création">
            {formatDate(order?.date)}
          </Form.Item>
          <Form.Item label="Date de livraison souhaité">
            {formatDate(order?.shipping_date)}
          </Form.Item>
          <Form.Item label="Client">
            {order?.customer_code}
          </Form.Item>
          <Form.Item label="Agence">
            <AgencyLabel id={order?.agency_id} />
          </Form.Item>
          <Form.Item label="Commercial">
            {order?.salesman_code}
          </Form.Item>
          <Form.Item label="Statut">
            <OrderStatus value={order?.status} />
          </Form.Item>
          <Form.Item label="Mode d'expédition">
            <OrderShippingModeLabel value={order?.shipping_mode} />
          </Form.Item>

        </Form>
      </div>
      <OrderMessagesView orderId={orderId} />
    </Flex>
    <div className="box-paddingless">
      <Table dataSource={order?.items} rowKey="line_number"
        pagination={{ position: ['bottomCenter'] }}
        columns={[
          {
            title: 'N° ligne',
            dataIndex: 'line_number',
            key: 'line_number',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <OrderItemStatus value={status} />,
          },
          {
            title: 'Type',
            dataIndex: 'item_type',
            key: 'item_type',
            // filters: Object.entries(orderTypes).map(([value, { label, color }]) => ({ text: label, value })),
            onFilter: (value, record) => record.item_type === value,
            filters: [
              { text: 'Marchandise', value: 'MAR' },
              { text: 'Nommenclature', value: 'NOM' },
              { text: 'Prestation', value: 'PRE' },
            ],
          },
          {
            title: 'Code',
            dataIndex: 'item_code',
            key: 'item_code',
          },
          {
            title: 'Désignation',
            dataIndex: 'label',
            key: 'label',
          },
          {
            title: 'Prix unitaire',
            dataIndex: 'unit_price',
            key: 'unit_price',
            render: (price) => formatCurrency(price),
          },
          {
            title: 'Quantité',
            dataIndex: 'quantity',
            key: 'quantity',
          },
          {
            title: 'Quantité Restante',
            dataIndex: 'remain_quantity',
            key: 'remain_quantity',
            sorter: (a, b) => a.remain_quantity - b.remain_quantity,
          },
          {
            title: 'Unité',
            dataIndex: 'quantity_unit',
            key: 'quantity_unit',
          },
          {
            title: 'Prix total',
            dataIndex: 'total_price',
            key: 'total_price',
            render: (price) => formatCurrency(price),
          },
          {
            title: 'Tâche',
            dataIndex: 'task_id',
            key: 'task_id',
          },
          {
            title: 'Dépot',
            dataIndex: 'warehouse',
            key: 'warehouse',
            render: (warehouse) => {
              return warehousesById['wh-' + warehouse]?.name;
            }
          },
        ]} />
    </div>
  </>);
}
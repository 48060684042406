

export default function ProjectShortCode({ value }) {
  if (!value) {
    return '';
  }
  const rx = /^([A-Z]+\d{2})(0+)(\d+)(\w+\d+)/
  const [m, toptype, zeros, numbers, subtype] = value.match(rx);
  if (!m) {
    return value
  }
  return numbers
}

import { useShipment } from "../../hooks/shipments";
import { ID } from "../id/id";
import Link from "../link/Link";


export default function ShipmentLabel({ id, clickable = false }) {
  // const { shipment } = useShipment(id);

  // const { nidame } = shipment || {};
  const label = <>Expédition n° <ID value={id} variant="text" /></>
  if (!clickable) {
    return label;
  }
  return <Link to={"/shipments/" + id}>{label}</Link>;
}

import { useNavigate, useParams } from 'react-router-dom';
import DeleteButton from '../../components/deletebutton/DeleteButton';
import { prestationsTypes, prestationUnits, usePrestation, usePrestationForm } from '../../hooks/prestations';
import { notifyError, notifySuccess } from '../../services/notification';
import { ControlBar } from '../../components/controlbar/ControlBar';
import PrestationIcon from '../../components/prestations/PrestationIcon';
import PriceInput from '../../components/inputs/PriceInput';
import DayCountInput from '../../components/inputs/DayCountInput';
import { Form, Input, Button, InputNumber, Select, message, Checkbox, Switch } from 'antd';
import { useEffect } from 'react';

// import { SubjectTimeline } from '../../components/dataviz/SubjectTimeline';
// import { ControlBar } from '../../components/controlbar/ControlBar';

export default function PrestationForm() {
  const navigate = useNavigate();
  const { prestationId } = useParams();
  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();
  const {
    prestationForm,
    prestation, isNewPrestation,
    errors, isValid, handleSubmit,
    setPrestation, savePrestation, deletePrestation,
    register,
  } = usePrestationForm(prestationId || '');

  useEffect(() => {
    if (prestation) {
      form.setFieldsValue({
        ...prestation,
      });
    }
  }, [form, prestation]);

  if (!prestation) {
    return <div>Not found</div>;
  }

  const onFinish = (values) => {
    (async () => {
      try {
        const item = await savePrestation({
          ...prestation,
          ...values,
        });
        messageApi.success('Enregistré');
        if (item) {
          navigate('/prestations');
        }
      } catch (err) {
        messageApi.error(`Erreur: ${err}`);
      }
    })();

    // form
    //   .validateFields()
    //   .then(async (values) => {
    //     const item = await savePrestation({
    //       ...prestation,
    //       ...values,
    //     });
    //     messageApi.success('Enregistré');
    //     // notifySuccess('Enregistré');
    //     // if (item) {
    //     //   navigate('/prestations');
    //     // }
    //   })
    //   .catch((err) => {
    //     messageApi.error(`Erreur: ${err}`);
    //     // notifyError(err);
    //   });
  };

  const onDelete = () => {
    if (prestationId) {
      (async () => {
        await deletePrestation();
        notifySuccess('Supprimé');
        setTimeout(() => {
          navigate('/prestations');
        }, 1000);
      })();
    }
  };


  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 6 }}
      wrapperCol={{ flex: 1 }}
      labelWrap
    >
      {messageContextHolder}
      <fieldset className="box">
        <legend>
          {/* <PrestationIcon type={prestationForm.type} /> */}
          <span className='ms-1'>Informations</span>
        </legend>
        <Form.Item label="Nom" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Code" name="code" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Type" name="type" rules={[{ required: true }]}>
          <Select options={prestationsTypes} />
        </Form.Item>
        <Form.Item label="Activé" name="enabled" rules={[{ required: true }]}>
          <Switch />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>


      </fieldset>
      <fieldset className="box">
        <legend>Suivi tarifaire</legend>
        <Form.Item label="Prix de vente" name="price">
          <InputNumber suffix="€" min={0} />
        </Form.Item>
        <Form.Item label="Prix d'achat" name="purchase_price">
          <InputNumber suffix="€" min={0} />
        </Form.Item>
        <Form.Item label="Unité" name="unit">
          <Select defaultValue="d" style={{ maxWidth: 90 }} options={prestationUnits} />
        </Form.Item>
      </fieldset>
      {/* <fieldset className="box">
        <legend>Suivi du temps</legend>
        <Form.Item label="Durée par défaut" name="default_qty" help="Quantitée affectée par défaut">
          <InputNumber suffix="jours" min={0} />
        </Form.Item>
        <Form.Item label="Durée minimum" name="min" help="Durée minimum en jour que peut avoir cette prestation lors de la planification. Par exemple avec un quantité à 2, une prestation ne pourra pas être affecté un vendredi, mais à minima obligatoirement un jeudi.">
          <InputNumber suffix="jours" min={0} max={7} />
        </Form.Item>
        <Form.Item label="Durée maximum" name="max" help="Durée maximum en jour que peut avoir cette prestation lors de la planification.">
          <InputNumber suffix="jours" min={0} max={7} />
        </Form.Item>
        <Form.Item label="Délais d'attente" name="delay" help="Délais d'attente en jour avant de pouvoir enchainer une nouvelle prestation. Par exemple séchage d'une dalle béton.">
          <InputNumber suffix="jours" min={0} />
        </Form.Item>


      </fieldset> */}
      {/* 
      <fieldset className="box">
        <legend>Prestations parentes</legend>

      </fieldset> */}

      <ControlBar>
        <DeleteButton onDelete={onDelete} />
        <div>
          <Button type="primary" size='large'
            onClick={() => form.submit()}>Enregistrer</Button>
        </div>
      </ControlBar>

    </Form>
  );
}

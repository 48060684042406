import { MenuOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table, DatePicker, Tag, Space, Button, Segmented, Input, Flex, Statistic, message, Progress, ConfigProvider, Tooltip, Drawer, Popover, Popconfirm, Select, Badge, Menu } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { orderStatuses, useOrders } from '../../hooks/orders';
import { formatDate } from '../../lib/format';
import { indexBy } from '../../lib/utils';
import { usePickingPoolAllOrders, usePickingPoolOrders, useSelectedPickingPoolID } from '../../hooks/pickingpools';

import { useProjectsLoader } from '../../hooks/projects';
import { useExtendedPersistentSearcher, useSearchOptionsMemo, useSearcher } from '../../hooks/search';
import { differenceInBusinessDays } from 'date-fns';
import { useWarehouses } from '../../hooks/warehouses';
import { useResources } from '../../hooks/resources';
import { useAgencies } from '../../hooks/agencies';
import Icon from '@mdi/react';
import { mdiClockStarFourPointsOutline, mdiPackageVariantClosed, mdiProgressClock, mdiTrayFull, mdiWarehouse } from '@mdi/js';
import Link from '../../components/link/Link';
import { adjustCapacity } from '../../lib/capacity';
import OrderStatus from '../../components/orders/OrderStatus';
import { loaderWrap } from '../../services/loader';
import { updateOrder } from '../../services/api/orders';
import { useItemsStocks } from '../../hooks/items';
import { useAuth } from '../../hooks/auth';
import Search from '../../components/search/Search';
import SearchButton from '../../components/search/SearchButton';
import { usePersistentState } from '../../hooks/state';
import { useShipmentsLoader } from '../../hooks/shipments';
import ShipmentStatus from '../../components/shipments/ShipmentStatus';


const orderTypes = {
  sales: {
    label: 'Négoce',
    color: 'blue',
  },
  prestations: {
    label: 'Prestations',
    color: 'green',
  },
  mixed: {
    label: 'Mixte',
    color: 'purple',
  }
}

const DraggableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

function OrderSelectDates({ dateMin, dateMax, onChange }) {
  const [{ date, type }, setState] = useState({ date: null, type: 'min' })

  useEffect(() => {
    if (dateMin && !new dayjs(dateMin).isSame(date, 'day')) {
      setState({ date: new dayjs(dateMin), type: 'min' })
    } else if (dateMax && !new dayjs(dateMax).isSame(date, 'day')) {
      setState({ date: new dayjs(dateMax), type: 'max' })
    }
  }, [dateMin, dateMax, date])

  const onUpdate = useCallback(({ date, type }) => {
    setState({ date, type })
    if (type === 'min') {
      onChange({ date_min: date, date_max: null })
    } else {
      onChange({ date_min: null, date_max: date })
    }
  }, [onChange])

  return <>
    <Space>
      Préparer
      <Select
        options={[
          { label: 'à partir du', value: 'min' },
          { label: 'pour le', value: 'max' },
        ]}
        value={type}
        onChange={(v) => {
          onUpdate({ date, type: v })
        }}
      />
      <DatePicker value={date} format="DD/MM/YYYY" onChange={(v) => {
        onUpdate({ date: v, type })
      }} />
    </Space>
  </>
}

function OrderSelectDatesPopover({ title, dateMin, dateMax, children, onChange }) {
  const [state, setState] = useState(null)

  const onOpenChange = (open) => {
    if (!open && state) { // On close, save
      onChange({
        date_min: state.date_min,
        date_max: state.date_max
      })
    } else {
      setState(null) // Reset on open
    }
  }

  return <Popover
    title={title}
    onOpenChange={onOpenChange}
    content={<OrderSelectDates
      dateMin={state ? state.date_min : dateMin}
      dateMax={state ? state.date_max : dateMax}
      onChange={setState} />}
  // trigger="click"
  >
    {children}
  </Popover>
}


function ProjectRenderer({ project }) {
  return <Link to={"/projects/" + project.id}>{project.name}</Link>;
}



function WarehouseCapacity({ warehouse, week, count, max }) {
  return <Flex vertical gap={3} style={{ lineHeight: '1.2em', padding: "0.5em" }} >
    <div><b>S{week}</b></div>
    {warehouse ? <Tag>{warehouse?.name}</Tag> : null}
    <div style={{ fontSize: "0.7em", color: "#888" }}><b>{count}</b> / {max}</div>
    <Progress percent={Math.round(100 * count / max)} size="small" status={count > max ? 'exception' : 'normal'} />
  </Flex>
}

export default function OrdersList() {
  const [pickingPoolId] = useSelectedPickingPoolID();
  const { can } = useAuth();
  const { pickingPool,
    orders: pickingPoolOrders,
    orderCompletions,
    saving,
    addOrder, removeOrder: removePickingOrder, updateOrder: updatePickingOrder, moveOrder } = usePickingPoolOrders(pickingPoolId);

  const { getVirtualStock, getPhysicalStock } = useItemsStocks();

  const [filterByWeekWarehouse, setFilterByWeekWarehouse] = useState('');
  const [resources] = useResources();
  // const [changed, setChanged] = useState(false);
  // const [saving, setSaving] = useState(false);
  const [ordersSource, setOrdersSource] = usePersistentState("OrderList.ordersSource", null); // 'orders' | 'pools
  const [dateRange, setDateRange] = usePersistentState('OrderList.dateRange', [
    new dayjs(), new dayjs().add(15, 'days')
  ]);
  const [orders, refreshOrders] = useOrders(dayjs(dateRange[0]).toDate(), dayjs(dateRange[1]).toDate());
  const pickingPoolAllOrders = usePickingPoolAllOrders(pickingPool?.is_main ? Object.keys(pickingPool.capacities)[0] : null);
  const { projects, getProject } = useProjectsLoader();
  const { getShipment } = useShipmentsLoader();
  const [agencies] = useAgencies();
  const [warehouses] = useWarehouses();

  const [selectedOrders, setSelectedOrders] = useState([]);

  const [showPickingPool, setShowPickingPool] = useState(false);

  // const projectsById = useMemo(() => indexBy(projects, 'id'), [projects]);
  const agenciesById = useMemo(() => indexBy(agencies, 'id'), [agencies]);

  const searchPoolOptions = useSearchOptionsMemo(pickingPoolAllOrders, (o) => {
    return {
      value: o.pool_id,
      label: o.pool_name,
      type: "pools"
    }
  });

  const searchWarehouseOptions = useSearchOptionsMemo(warehouses, (w) => {
    return {
      value: w.id,
      label: 'Dépôt / ' + w.name,
      type: "warehouses"
    }
  });

  const salesOptions = useSearchOptionsMemo(resources, (r) => {
    if (!r.commercial_code) return null;
    // console.log(r)
    return {
      value: r.commercial_code,
      label: `${r.name} (${r.commercial_code})`,
    }
  });

  const { list: filteredOrders, search, setSearch, addSearch: addOrderSearch } = useExtendedPersistentSearcher(
    'OrderList',
    ordersSource === 'pools' ? pickingPoolAllOrders : orders,
    useCallback((o, { orders, projects, thirdparties, agencies, pools, resources, warehouses, projectTypes, projectInvoicingModes }, fulltextSearch) => {
      if (pools && pools.length && !pools.includes(o.pool_id)) return false;
      if (orders && orders.length && !orders.includes(o.id)) return false;
      if (projects && projects.length && !projects.includes(o.project_id)) return false;
      // if (projectTypes && projectTypes.length && !projectTypes.includes(project.project_type)) return false;
      // if (projectInvoicingModes && projectInvoicingModes.length && !projectInvoicingModes.includes(projectInvoiceModeByProjectID[project.id])) return false;
      if (thirdparties && thirdparties.length && !thirdparties.includes(o.customer_code)) return false;
      if (agencies && agencies.length && !agencies.includes(o.agency_id)) return false;
      if (resources && resources.length && !resources.includes(o.salesman_code)) return false;
      if (warehouses && warehouses.length && !warehouses.some(warehouse => {
        const code = warehouse.slice(3) // trim 'wh-' prefix
        return o.remaining_items_count_by_warehouse[code] > 0
      })) return false;
      return fulltextSearch(`${o.order_number} ${o.ref_internal} ${getProject(o.project_id)?.name} ${o.salesman_code}`)
    }, [getProject]),
  );

  const displayedOrders = useMemo(() => {
    return filteredOrders.map(o => {
      // let totalOrdered = 0;
      // let totalVirtualStocks = 0;
      // let totalPhysicalStocks = 0;

      // o.items?.forEach(item => {
      //   totalOrdered += item.remain_quantity;
      //   totalVirtualStocks += getVirtualStock(item.item_code);
      //   totalPhysicalStocks += getPhysicalStock(item.item_code);
      // });

      return {
        ...o,
        status: o.status || 'pending',
        type: o.items_count && o.prestations_count ? 'mixed' : o.items_count ? 'sales' : 'prestations',
        // virtualCompletionRate: totalVirtualStocks > 0 ? Math.round(100 * totalOrdered / totalVirtualStocks) : 0,
        // physicalCompletionRate: totalPhysicalStocks > 0 ? Math.round(100 * totalOrdered / totalPhysicalStocks) : 0,
      }
    });
  }, [filteredOrders]);


  const onArchive = async () => {
    loaderWrap(Promise.all(selectedOrders.map(async (id) => {
      const order = orders.find(o => o.id === id);
      if (order) {
        return updateOrder({ ...order, status: 'archived' });
      }
    }))
      .then(() => {
        refreshOrders();
        if (selectedOrders.length === 1) {
          message.success('Commande archivée');
        } else {
          message.success(`${selectedOrders.length} commandes archivées`);
        }
        setSelectedOrders([]);
      }))
  }

  const disabledDate = (current, { from } = {}) => {
    if (from) {
      return Math.abs(current.diff(from, 'month')) > 3;
    }
    return false;
  };

  useEffect(() => {
    if (pickingPool && !pickingPool.is_main) {
      setOrdersSource('orders');
    } else if (pickingPool?.is_main && ordersSource === null) {
      setOrdersSource('pools');
    }
  }, [pickingPool, ordersSource, setOrdersSource]);


  // const [pickingPoolOrders, setPickingPoolsOrders] = useState([])

  const onAdd = (id, pool_id) => {
    addOrder(id, pool_id);
    // setChanged(true);
  }

  const onDelete = (id) => {
    removePickingOrder(id);
    // setChanged(true);
  }

  const onUpdate = (id, { preparation_date_min, preparation_date_max }) => {

    updatePickingOrder(id, {
      preparation_date_min: preparation_date_min ? preparation_date_min.toDate() : null,
      preparation_date_max: preparation_date_max ? preparation_date_max.toDate() : null,
    })
    // setChanged(true);
  }

  const onDragEnd = ({ active, over }) => {
    moveOrder({ active, over });
    // setChanged(true);
  };



  const pickedOrdersIndex = useMemo(() => {
    return indexBy(pickingPoolOrders, 'id')
  }, [pickingPoolOrders]);

  const pickingPoolOrdersExtented = useMemo(() => {
    return pickingPoolOrders.map((order) => {
      const { estimatedDateMax, consumedCapacities } = orderCompletions[order.id] || { estimatedDateMax: null, consumedCapacities: 0 };
      const w = new dayjs(estimatedDateMax).isoWeek()
      return {
        ...order,
        shipment: getShipment(order.shipment_id),
        estimatedDateMax: estimatedDateMax,
        estimatedWeekMax: w,
        consumedCapacities,
      }
    })
  }, [pickingPoolOrders, getShipment, orderCompletions]);



  const statsByWeek = useMemo(() => {
    return Object.values(pickingPoolOrdersExtented.reduce((acc, { estimatedWeekMax, consumedCapacities }) => {
      Object.entries(consumedCapacities).forEach(([id, count]) => {
        const key = `${estimatedWeekMax}-${id}`
        const isCurrentWeek = estimatedWeekMax === new dayjs().isoWeek();
        if (!acc[key]) {
          acc[key] = {
            key, count: 0,
            max: isCurrentWeek ? adjustCapacity((pickingPool.capacities[id] || 0), 'week') : (pickingPool.capacities[id] || 0) * 5,
            week: estimatedWeekMax,
            warehouse: id
          }
        }
        acc[key].count += count
      })
      return acc
    }, {}))
  }, [pickingPoolOrdersExtented, pickingPool])



  const warehousesById = useMemo(() => {
    return indexBy(warehouses, 'id')
  }, [warehouses])


  const displayedPickingOrders = useMemo(() => {
    if (!filterByWeekWarehouse) {
      return pickingPoolOrdersExtented
    }
    if (filterByWeekWarehouse === 'locked') {
      return pickingPoolOrdersExtented.filter(({ locked }) => locked)
    }

    return pickingPoolOrdersExtented.filter(({ estimatedWeekMax, items, locked }) => {
      // if (locked) {
      //   return false
      // }
      for (const { warehouse } of items) {
        if (`${estimatedWeekMax}-wh-${warehouse}` === filterByWeekWarehouse) {
          return true;
        }
      }
      return false
    });
  }, [pickingPoolOrdersExtented, filterByWeekWarehouse])




  const { list: displayedPickingOrdersFiltered, search: searchInPool, setSearch: setSearchInPool, addSearch: addPoolSearch } = useExtendedPersistentSearcher(
    'OrderListPickingPool',
    displayedPickingOrders,
    useCallback((o, { thirdparties, projects, resources }, fullTextSearch) => {
      if (thirdparties && thirdparties.length && !thirdparties.includes(o.customer_code)) return false;
      if (projects && projects.length && !projects.includes(o.project_id)) return false;
      if (resources && resources.length && !resources.includes(o.salesman_code)) return false;
      return fullTextSearch(`${o.order_number} ${o.ref_internal} ${getProject(o.project_id)?.name} ${o.salesman_code}`)
    }, [getProject]),
  );


  const thirdparties = useSearchOptionsMemo(orders, (o) => {
    return {
      id: o.third_party_id || o.customer_code,
      name: o.third_party_name || o.customer_name || `Client ${o.customer_code}`,
    }
  });

  const canViewPickings = can('pickingpool.view') && pickingPool


  return <Flex vertical gap="middle">

    <Flex vertical gap="small" className="box-paddingless">
      <Flex gap={"small"} style={{ padding: 10 }}>
        {pickingPool?.is_main && (
          <Segmented
            size="large"
            options={[
              { label: 'Commandes', value: 'orders' },
              { label: 'Pools', value: 'pools' },
            ]}
            onChange={(v) => setOrdersSource(v)}
            value={ordersSource}
          />)}
        {/* <Input size="large" placeholder='Rechercher...' value={search} onChange={(e) => setSearch(e.target.value)} /> */}
        <Search
          name='OrdersList'
          options={[
            ...searchPoolOptions,
            ...searchWarehouseOptions,
          ]}
          iconAndColorProps={(type) => {
            switch (type) {
              case 'pools':
                return { icon: mdiTrayFull, color: 'blue' }
              case 'warehouses':
                return { icon: mdiWarehouse, color: 'grey' }
              default: return {}
            }
          }}
          resources={salesOptions}
          projects={projects}
          agencies={agencies}
          thirdparties={thirdparties}
          values={search} onChange={setSearch} />

        {ordersSource === 'orders' &&
          <DatePicker.RangePicker
            allowClear={false}
            style={{ minWidth: '250px' }}
            format="DD/MM/YYYY"
            value={[
              dayjs(dateRange[0]), dayjs(dateRange[1])]} disabledDate={disabledDate} onChange={setDateRange} />
        }
        {(selectedOrders.length > 0 && can('order.update') && can('order.archive')) && <Popconfirm
          title={selectedOrders.length > 1
            ? `Archiver les ${selectedOrders.length} commandes sélectionnées ?`
            : "Archiver la commande sélectionnée ?"}
          description="Cette action ne peut pas être annulée."
          onConfirm={() => onArchive()}

          okText="Oui"
          cancelText="Non"
        >
          <Button type="primary" size="large" danger onClick={() => { }} >Archiver</Button>
        </Popconfirm>}
        {pickingPoolId &&
          <Button type="primary" size="large"
            onClick={() => setShowPickingPool(true)}>En préparation <Badge count={pickingPoolOrders?.length} /></Button>}
      </Flex>
      <Table
        // bordered
        dataSource={displayedOrders}
        rowKey="id"
        size='middle'
        rowSelection={{
          type: 'checkbox',
          // getCheckboxProps: (record) => ({
          //   disabled: !!record.ref,
          // }),
          selectedRowKeys: selectedOrders,
          onChange: (selectedRowKeys) => {
            setSelectedOrders(selectedRowKeys)
            // console.log(`selectedRowKeys: ${selectedRowKeys}`);
          }
        }}
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => open(record.id), // click row
        //   };
        // }}
        columns={[
          // {
          //   title: 'Code',
          //   dataIndex: 'code',
          //   key: 'code',
          //   width: 100,
          //   sorter: (a, b) => String(a.code).localeCompare(b.code),
          // },
          ...(ordersSource === 'pools' ? [{
            title: 'Pool',
            dataIndex: 'pool_id',
            key: 'pool_id',
            render: (pool_id, item) => <SearchButton
              type="pools"
              value={pool_id}
              handler={addOrderSearch}>
              {item.pool_name}
            </SearchButton>,
          }] : []),
          {
            title: 'Numéro',
            dataIndex: 'order_number',
            key: 'order_number',
            width: 100,
            render: (order_number, o) => <Link to={`/orders/${o.id}`}>{order_number}</Link>
          },
          {
            title: 'Ref',
            dataIndex: 'ref_internal',
            key: 'ref_internal',
            width: 150,
          },
          {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: 100,
            render: (date) => formatDate(date),
          },
          {
            title: 'N° Client',
            dataIndex: 'customer_code',
            key: 'customer_code',
            width: 100,
            render: (customer_code) => <SearchButton
              type="thirdparties"
              value={customer_code}
              handler={addOrderSearch}>
              {customer_code}
            </SearchButton>,
          },
          {
            title: 'Agence',
            dataIndex: 'agency_id',
            key: 'agency_id',
            width: 150,
            render: (agency_id) => {
              const agency = agenciesById[agency_id];
              return <SearchButton
                type="agencies"
                value={agency_id}
                handler={addOrderSearch}>
                <Link to={`/agencies/${agency_id}`}>{agency?.name}</Link>
              </SearchButton>
            }
          },
          {
            title: 'Commercial',
            dataIndex: 'salesman_code',
            key: 'salesman_code',
            width: 100,
            render: (salesman_code) => <SearchButton
              type="text"
              value={salesman_code}
              handler={addOrderSearch}>
              {salesman_code}
            </SearchButton>,
          },
          {
            title: 'Affaire',
            dataIndex: 'project_id',
            key: 'project_id',
            render: (project_id) => {
              const project = getProject(project_id);
              if (!project) {
                return null;
              }
              return <SearchButton
                type="projects"
                value={project_id}
                handler={addOrderSearch}>
                <ProjectRenderer project={project} />
              </SearchButton>
            }
          },
          {
            title: 'Date de livraison',
            dataIndex: 'shipping_date',
            key: 'shipping_date',
            width: 100,
            render: (date) => formatDate(date),
            sorter: (a, b) => new Date(a.shipping_date) - new Date(b.shipping_date),
            defaultSortOrder: "ascend",
            sortDirections: ['descend', 'ascend'],
          },

          {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status) => <OrderStatus value={status} />,
            filters: orderStatuses.map(({ label, value }) => ({ text: label, value })),
            defaultFilteredValue: orderStatuses.map(({ value }) => value).filter(value => value !== 'archived'),
            onFilter: (value, record) => record.status === value,
          },
          {
            title: 'Type de commande',
            dataIndex: 'type',
            key: 'type',
            width: 100,
            render: (type) => <Tag color={orderTypes[type].color}>{orderTypes[type].label}</Tag>,
            filters: Object.entries(orderTypes).map(([value, { label, color }]) => ({ text: label, value })),
            onFilter: (value, record) => record.type === value,
          },
          {
            title: 'Nb réfs restantes',
            key: 'remaining_items_count',
            dataIndex: 'remaining_items_count',
            width: 80,
            sorter: (a, b) => a.remaining_items_count - b.remaining_items_count,
            render: (quantity, { remaining_items_count_by_warehouse }) => <span>
              {Object.entries(remaining_items_count_by_warehouse).map(([warehouseCode, count]) => {
                const name = warehousesById['wh-' + warehouseCode]?.name || warehouseCode;
                return <Tag key={warehouseCode}>{name}: <b>{count}</b></Tag>
                // return <Tag key={warehouseCode}>{warehouseCode}: {count}</Tag>
              })}
            </span>,
          },
          // {
          //   title: 'Stock virtuel',
          //   key: 'virtualCompletionRate',
          //   dataIndex: 'virtualCompletionRate',
          //   width: 100,
          //   // render: (rate) => <Progress percent={rate * 100} size="small" status={rate < 100 ? 'exception' : 'normal'} />
          // },
          {
            title: '',
            key: 'actions',
            width: 100,
            render: (record) => <Space>
              {/* <Button type="default" onClick={() => open(record.id)}>Voir</Button> */}
              {can('pickingpool.update') && <Button type="primary" disabled={pickedOrdersIndex[record.id]} onClick={() => onAdd(record.id, record.pool_id)}>À préparer</Button>}
            </Space>
          }
        ]}
      />
    </Flex>
    {can('pickingpool.view') && <>
      <Drawer placement='bottom'
        height="80vh"
        closeIcon={null}
        getContainer={false}
        title={<Flex align='center' gap="small">
          <Icon path={mdiPackageVariantClosed} size={1} />
          <span>Commandes en préparation pour <b>{pickingPool?.name}</b></span>
          <Badge count={pickingPoolOrdersExtented.length} />
        </Flex>}
        visible={showPickingPool}
        onClose={() => { setShowPickingPool(false) }}>
        {/* <h3>
        <Flex align='center'>
          <Icon path={mdiPackageVariantClosed} size={1} style={{ marginRight: 10 }} />
          Commandes en préparation
        </Flex>
      </h3> */}
        <Menu
          style={{ flex: 1 }}
          mode="horizontal"
          selectedKeys={[filterByWeekWarehouse]}
          onClick={({ key }) => setFilterByWeekWarehouse(key)}
          items={
            [
              { label: <Flex align="center" style={{ height: 80 }}>Toutes</Flex>, key: "" },
              { label: <Flex align="center" style={{ height: 80 }}>En préparation</Flex>, key: "locked" },
              ...statsByWeek.map(({ key, count, max, week, warehouse }) => ({
                label: <WarehouseCapacity
                  warehouse={pickingPool?.is_main ? null : warehousesById[warehouse]}
                  week={week}
                  count={count}
                  max={max} />,
                key
              }))

              // ...activeResources
              //   .map(r => ({
              //     label: <SegmentResource
              //       resource={r}
              //       selected={displayMode === 'res:' + r.id}
              //       capacity={capacitiesByResource[r.id]}
              //       maxCapacity={maxCapacity} />,
              //     key: 'res:' + r.id
              //   }))
            ]
          }
        />
        <div className="">

          <Flex vertical style={{ padding: 10 }} gap={5}>
            {/* <Flex align='center' justify="space-between">
              <ConfigProvider
                theme={{
                  components: {
                    Segmented: {
                      trackBg: 'transparent'
                    }
                  }
                }}>
                <Segmented
                  size="large"
                  value={filterByWeekWarehouse}
                  options={[
                    { label: <Flex align="center" style={{ height: 80 }}>Toutes</Flex>, value: "" },
                    { label: <Flex align="center" style={{ height: 80 }}>En préparation</Flex>, value: "locked" },
                    ...statsByWeek.map(({ key, count, max, week, warehouse }) => ({
                      label: <WarehouseCapacity
                        warehouse={pickingPool?.is_main ? null : warehousesById[warehouse]}
                        week={week}
                        count={count}
                        max={max} />,
                      value: key
                    }))
                  ]} onChange={(v) => setFilterByWeekWarehouse(v)} />
              </ConfigProvider>


              <div style={{ margin: "0 1em" }}>
                <Button type="text" size='large'
                  disabled={true}
                  loading={saving}
                >{saving ? '' : 'Enregistré'}</Button>
              </div>
            </Flex> */}

            <Flex gap={"small"} >
              <Search
                resources={salesOptions}
                projects={projects}
                agencies={agencies}
                thirdparties={thirdparties}
                values={searchInPool} onChange={setSearchInPool} />
              {/* <Input.Search size="large"
              allowClear
              placeholder='Rechercher...' value={searchInPool}
              onChange={(e) => setSearchInPool(e.target.value)} /> */}
            </Flex>
          </Flex>
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              // rowKey array
              items={pickingPoolOrders.map((i) => i.order_number)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                size='small'
                // bordered
                dataSource={displayedPickingOrdersFiltered}
                rowHoverable={false}
                pagination={
                  {
                    position: ['bottomCenter'],
                  }
                }
                rowKey="order_number"
                components={{
                  body: {
                    row: can('pickingpool.update') ? DraggableRow : undefined,
                  },
                }}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: () => open(record.id), // click row
                //   };
                // }}
                // rowClassName={(record) => {
                //   const { outOfCapacity, percent } = orderCompletions[record.id] || {};
                //   if (outOfCapacity || percent < 90) {
                //     return styles.outOfCapacity;
                //   }
                //   return '';
                // }}
                columns={[
                  {
                    key: 'sort',
                    width: 40,
                  },
                  // {
                  //   title: 'Code',
                  //   dataIndex: 'code',
                  //   key: 'code',
                  //   width: 100,
                  //   sorter: (a, b) => String(a.code).localeCompare(b.code),
                  // },

                  {
                    title: 'Numéro',
                    dataIndex: 'order_number',
                    key: 'order_number',
                    width: 100,
                    render: (order_number, o) => <>
                      <Link to={`/orders/${o.id}`}>{order_number}</Link>
                      {o.from_pool_id ? '*' : ''}
                    </>
                  },
                  {
                    title: 'Ref',
                    dataIndex: 'ref_internal',
                    key: 'ref_internal',
                    width: 200,
                  },
                  {
                    title: 'N° Client',
                    dataIndex: 'customer_code',
                    key: 'customer_code',
                    width: 100,
                    render: (customer_code) => <SearchButton
                      type="thirdparties"
                      value={customer_code}
                      handler={addPoolSearch}>
                      {customer_code}
                    </SearchButton>,
                  },
                  {
                    title: 'Commercial',
                    dataIndex: 'salesman_code',
                    key: 'salesman_code',
                    width: 100,
                    render: (salesman_code) => <SearchButton
                      type="text"
                      value={salesman_code}
                      handler={addPoolSearch}>
                      {salesman_code}
                    </SearchButton>
                  },
                  {
                    title: 'Affaire',
                    dataIndex: 'project_id',
                    key: 'project_id',
                    render: (project_id) => {
                      const project = getProject(project_id);
                      if (!project) {
                        return null;
                      }
                      return <SearchButton
                        type="projects"
                        value={project_id}
                        handler={addPoolSearch}>
                        <ProjectRenderer project={project} />
                      </SearchButton>
                    }
                  },
                  {
                    title: 'Date de livraison souhaitée',
                    dataIndex: 'shipping_date',
                    key: 'shipping_date',
                    width: 100,
                    render: (date) => formatDate(date)
                  },
                  {
                    title: 'Préparation estimée',
                    dataIndex: 'estimatedWeekMax',
                    width: 100,
                    key: 'estimatedWeekMax',
                    render: (date, { id, preparation_date_min, preparation_date_max, shipping_date, estimatedWeekMax, locked }) => {

                      // const minDate = preparation_date_min ? new dayjs(preparation_date_min) : null;
                      const { estimatedDateMax } = orderCompletions[id] || { estimatedDateMax: null };
                      const d = differenceInBusinessDays(estimatedDateMax, shipping_date)
                      const title = <>Date estimée de préparation: <b>{formatDate(estimatedDateMax)}</b></>
                      const onDateChange = ({ date_min, date_max }) => {

                        onUpdate(id, {
                          preparation_date_min: date_min,
                          preparation_date_max: date_max
                        })

                      }

                      const style = {};
                      if (d > 0) {
                        style.color = 'red';
                        style.fontWeight = 600;
                      } else if (d > -2) {
                        style.color = '#d46b08';
                        style.fontWeight = 500;
                      }

                      const content = <span style={style}>S{estimatedWeekMax}</span>


                      if (pickingPool.is_main && !locked && can('pickingpool.update')) {
                        return <OrderSelectDatesPopover title={title}
                          dateMin={preparation_date_min}
                          dateMax={preparation_date_max}
                          onChange={onDateChange}>
                          <>
                            <Flex align="center" gap="small">
                              {content}
                              {(preparation_date_min || preparation_date_max) && <Icon path={preparation_date_min ? mdiProgressClock : mdiClockStarFourPointsOutline}
                                size={0.6} color={style.color} />}
                            </Flex>
                            <span style={{
                              marginTop: '-0.5em',
                              fontWeight: '300',
                              fontSize: '0.8em',
                              color: '#777'
                            }}>{formatDate(estimatedDateMax)}</span>
                          </>
                        </OrderSelectDatesPopover>;
                      } else {
                        return content
                      }
                    },

                  },

                  {
                    title: 'Estimation dispo stock',
                    width: 100,
                    // dataIndex: 'status',
                    key: 'status',
                    render: (order) => {
                      const { percent } = orderCompletions[order.id] || {};
                      switch (true) {
                        case percent === 100:
                          return <Tag color='green' icon={<CheckCircleOutlined />}>100%</Tag>
                        case percent < 90:
                          return <Tag color='red' icon={<CloseCircleOutlined />}>{percent}%</Tag>
                        default:
                          return <Tag color='orange' icon={<ExclamationCircleOutlined />}>{percent}%</Tag>
                      }
                    }
                  },
                  {
                    title: 'Expédition',
                    dataIndex: ['shipment', 'status'],
                    key: 'shipment_status',
                    width: 100,
                    render: (status, rec) => {
                      // return <Popover title="Expédition" content={<div>
                      //   <div><b>Transporteur:</b> {rec.shipment?.carrier}</div>
                      //   <div><b>Statut:</b> <ShipmentStatus value={status} /></div>
                      //   <div><b>Tracking:</b> {rec.shipment?.tracking}</div>
                      // </div>}>
                      // </Popover>
                      return <ShipmentStatus value={status} />
                    }
                  },
                  {
                    title: 'Dépots',
                    key: 'warehouses',
                    width: 150,
                    render: (order) => {
                      const { consumedCapacities } = orderCompletions[order.id] || { consumedCapacities: {} };
                      return <Space>
                        {Object.entries(consumedCapacities).map(([id, count]) => {
                          const name = warehousesById[id]?.name || id;
                          return <Tag key={id}>{name}: <b>{count}</b></Tag>
                        })}
                      </Space>
                    }
                  },

                  {
                    title: '',
                    key: 'actions',
                    width: 100,
                    render: (record) => <Space>
                      {can('pickingpool.update') && <Button type="primary" danger disabled={record.locked} onClick={() => onDelete(record.id)}>Retirer</Button>}
                    </Space>
                  }
                ]}
              />
            </SortableContext>
          </DndContext>


        </div>
      </Drawer>
    </>
    }

  </Flex >
}
import { Alert } from "antd"
import { useMemo } from "react"
import { formatDate } from "../../lib/format"

export function PlanningErrorOverload({ error, users, tasks }) {
  const { detail } = error
  const { resource_id, task_id } = detail

  const user = useMemo(() => users.find(u => u.id === resource_id), [resource_id, users])
  const task = useMemo(() => tasks.find(t => t.id === task_id), [task_id, tasks])
  return <Alert
    message={<>
      <><b>Surchage :</b> </>
      La ressource <b>{user.name}</b> est surchargée pour effectuer la tache <b>{task?.name}</b>
    </>
    }
    type="error" showIcon />
}

export function PlanningErrorConflict({ error, users, tasks }) {
  const { detail } = error
  const [slot1, slot2] = detail.slots
  const { resource_id, task_id: task_id1 } = slot1
  const { task_id: task_id2 } = slot2

  const task1 = useMemo(() => tasks.find(t => t.id === task_id1), [task_id1, tasks])
  const task2 = useMemo(() => tasks.find(t => t.id === task_id2), [task_id2, tasks])
  const user = useMemo(() => users.find(u => u.id === resource_id), [resource_id, users])

  return <Alert
    message={<>
      <><b>Conflit :</b> </>
      Un conflit a été détecté
      concernant <b>{user?.name}</b> entre
      les taches <b>{task1?.name}</b> et <b>{task2?.name}</b> durant la
      période du <b>{formatDate(slot1.start)}</b> au <b>{formatDate(slot1.end)}</b>
    </>
    }
    type="error" showIcon />
}


export function PlanningErrorBadResource({ error }) {
  const { detail } = error
  const {
    resource, task,
    isOutOfAgency,
    isUnderLevel,
    isNotFound } = detail

  const details = []
  if (isOutOfAgency) {
    details.push("elle n'est pas dans l'agence")
  }
  if (isUnderLevel) {
    details.push("son niveau est insuffisant")
  }
  if (isNotFound) {
    details.push("elle ne founit pas cette prestation")
  }

  return <Alert
    message={<>
      <><b>Mauvaise resource :</b> </>
      <>La ressource <b>{resource?.name}</b> n'est pas adaptée à la tache <b>{task?.name}</b> car </>
      {details.slice(0, -1).join(', ')} {details.length > 1 ? 'et ' : ''} {details[details.length - 1]}
    </>
    }
    type="warning" showIcon />
}

export function PlanningErrorItem({ error, users, tasks }) {
  const { type } = error

  let desc;
  switch (type) {
    case 'conflict':
      return <PlanningErrorConflict error={error} users={users} tasks={tasks} />
    case 'bad-resource':
      return <PlanningErrorBadResource error={error} />
    case 'overload':
      return <PlanningErrorOverload error={error} users={users} tasks={tasks} />
    default:
      desc = <>Erreur inconnue</>
  }

  return <Alert message="Erreur"
    description={desc}
    type="error" showIcon />
}
import { ConfigProvider, Timeline } from "antd";

import styles from './MessagesView.module.scss';
import Message from "./Message";
import Avatar from "../avatar/Avatar";
import { useAuth } from "../../hooks/auth";

export default function MessagesList({ messages, showContext, contextObjects }) {
  // const { user } = useAuth();
  return <div className={styles.messages}>
    <ConfigProvider
      theme={{
        components: {
          Timeline: {
            dotBg: 'transparent',
          },
        }
      }}>
      {messages?.length === 0 && <div className={styles.noMessages}>Aucun commentaire</div>}
      <Timeline
        // mode="alternate"
        items={messages?.map((message, index) => (
          {
            children: <Message key={message.id}
              message={message}
              showContext={showContext}
              contextObjects={contextObjects}
            // owner={message.by === user.userId}
            />,
            dot: <Avatar username={message.by_name || message.by} size={24} />,
            // position: message.by === user.userId ? 'right' : 'left',
          }
        ))}

      />
    </ConfigProvider>
  </div>
}

import { useEffect, useState } from "react";
import { Loader } from "../../components/loader/Loader";
import { getCalendarData, setCalendarData } from "../../services/api/calendar";
import { useParams } from "react-router-dom";
import { loaderWrap } from "../../services/loader";
import { AppLoader } from "../../components/app/AppLoader";

import logo from '../../assets/logo.png';
import { Button, Flex, Modal, Radio, Space, Tabs, message } from "antd";
import { ControlBar } from "../../components/controlbar/ControlBar";
import TaskStatusLabel from "../../components/tasks/TaskStatusLabel";
import SlotStatusLabel from "../../components/slots/SlotStatusLabel";
import { slotCancelReasons } from "../../hooks/slots";

import { mdiAccount, mdiClock, mdiMapMarker, mdiPhone, mdiTimeline } from "@mdi/js";
import Icon from "@mdi/react";
import { formatDateTime } from "../../lib/format";
import EventInfo from "./EventInfo";
import EventDocuments from "./EventDocuments";
import EventNotes from "./EventNotes";


import './Event.scss';
import Styles from './Event.module.scss';
import EventHistory from "./EventHistory";


export default function Event({ }) {
  const [data, setData] = useState(null);
  const { accessKey, slotId, token } = useParams();
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    loaderWrap(
      getCalendarData(accessKey, slotId, token)
        .then(setData))
  }, [accessKey, slotId, token, refreshIndex]);

  const refresh = () => {
    setRefreshIndex(refreshIndex + 1)
  }
  const onChange = () => {
    refresh()
  }

  useEffect(() => {
    // Auto refresh every 5 minutes
    const interval = setInterval(() => {
      refresh()
    }, 300000);
    return () => clearInterval(interval);
  }, []);


  const { me } = data || {};
  return (<div className={Styles.page}>
    {/* <meta name="theme-color" content="#000000" /> */}
    <div className={Styles['page-header']}>
      <div className={Styles.title}>
        <Flex gap="small" align="center" justify="space-between">
          <h1>
            <img src={logo} alt="Chronos" />
            <span>Chronos</span>
          </h1>
          <div className={Styles.titleOwnerName}>
            {me?.name}
          </div>
        </Flex>
      </div>
    </div>
    <div className={Styles['page-content']}>
      {data ? <div className={Styles["task"]}>
        <div className={Styles["task-status"]}>
          <SlotStatusLabel status={data?.slot.status} />
        </div>
        <h2>{data?.project.name}</h2>
        <h3>
          {data?.task?.name}
        </h3>
        <Tabs items={[
          {
            key: "infos",
            label: "Infos",
            children: <EventInfo {...data} onChange={onChange} />
          },
          {
            key: "documents",
            label: "Documents",
            children: <EventDocuments documents={data?.attachments} />
          },
          {
            key: "notes",
            label: "Notes",
            children: <EventNotes messages={data?.messages} contextObjects={[
              data?.task,
              data?.project,
              data?.slot,
            ]} />
          },
          // {
          //   key: "history",
          //   label: "Historique",
          //   children: <EventHistory />
          // }
        ]} />
      </div> : null}
    </div>

    <AppLoader />
  </div>)
}
import { useEffect } from "react";



const id = Symbol('DEBUG_OBJ_ID');

var inc = 0;

export function debugGetObjectID(obj) {
  if (!obj) return null;
  if (typeof obj !== 'object' && typeof obj !== 'function') return obj;
  if (!obj[id]) {
    obj[id] = typeof obj + "-" + (inc++);
  }
  return obj[id];
}

export function debugGetCallee() {
  try {
    throw new Error();
  } catch (e) {
    return e.stack.split('\n')[3];
  }
}

export function debugTraceHookChanges(keys = {}, name) {
  Object.entries(keys).forEach(([key, value]) => {
    useEffect(() => {
      console.log('hook key change', name, key, value);
    }, [value]);
  });
}
import { useEffect, useState } from "react";
import MessagesView from "../messages/MessagesView";
import { loaderWrap } from "../../services/loader";
import { addTaskComment, listTaskComments } from "../../services/api/tasks";


export default function TaskMessagesView({ taskId }) {
  const [messages, setMessages] = useState([])
  const [reloadCounter, setReload] = useState(0)

  useEffect(() => {
    if (!taskId) return
    (async () => {
      const messages = await listTaskComments(taskId)
      setMessages(messages)
      // setMessages([
      //   { id: 1, content: 'Hello', date: new Date(), by: "Michel" },
      //   { id: 1, content: 'Hello2', date: new Date(), by: "Paul" },
      // ])
    })()
  }, [reloadCounter, taskId])

  const onSend = async (content) => {
    if (!taskId) return
    await addTaskComment(taskId, content)
    setReload(reloadCounter + 1)
  }

  return <MessagesView messages={messages} onSend={onSend} />
}
import { useCallback, useEffect, useState } from 'react';

import {
  createTask, getTask, invalidateTaskCache, listTasks, removeTask, updateTask,
} from '../services/api/tasks';
import { loaderWrap } from '../services/loader';
import { notifyError } from '../services/notification';
import { useInvalidationService } from './invalidation';

import {
  isRequired, validate, validateAll,
} from '../lib/validation';
import { useSelectedAgencyID } from './agencies';
import { indexBy } from '../lib/utils';


const INVALIDATION_KEY = Symbol('tasks');




export const taskTypes = [
  {
    label: 'Tâche',
    value: 'task',
  },
  {
    label: 'Prestation',
    value: 'prestation',
    color: 'blue'
  },
  {
    label: 'Expédition',
    value: 'shipping',
    color: 'purple'
  },
  {
    label: 'Livraison',
    value: 'delivery',
    color: 'purple'
  },
  {
    label: 'Service après-vente',
    value: 'after-sales',
    color: 'gold'
  },
];



export const taskStatuses = [
  {
    label: 'À planifier',
    value: 'unplanned',
    color: 'default'
  },
  {
    label: "Planification",
    value: 'inplanning',
    color: '#1677ff'
  },
  {
    label: "Planifiée",
    value: 'planned',
    color: '#13C2C2'
  },
  {
    label: "Bloquée",
    value: 'blocked',
    color: 'orange'
  },
  {
    label: "Annulée",
    value: 'canceled',
    color: 'red'
  },
  {
    label: "Terminé",
    value: 'complete',
    color: '#52C41A'
  }
];

export const taskStatusesIdx = indexBy(taskStatuses, 'value');

export const taskUnits = [
  {
    label: 'Jours',
    value: 'd',
  },
  {
    label: 'Heures',
    value: 'h',
  },
  {
    label: 'Forfait',
    value: 'u',
  },
];

export const taskPriorities = [
  {
    label: 'Basse',
    value: 1,
  },
  {
    label: 'Moyenne',
    value: 2,
  },
  {
    label: 'Haute',
    value: 4,
  },
];



function getDefaults() {
  return {
    name: "",
    type: "prestation",
    description: "",
    duration: null,
    unit: "d",
    status: "unplanned",
    cost: null,
    parent_task_id: null,
    previous_task_ids: [],
    minDate: null,
    maxDate: null,
    expected_level: 1,
    priority: 1,
  };
}

async function getItem(id) {
  if (id === '*' || !id) {
    return { item: getDefaults() };
  }
  try {
    const { item, changes } = await loaderWrap(getTask(id));
    return {
      item: {
        ...getDefaults(),
        ...(item),
      },
      changes,
    };
  } catch (e) {
    notifyError(e);
  }
  return {};
}

export function useTasks(filters = {}) {
  const [tasks, setTasks] = useState([]);
  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);
  const [complete, setComplete] = useState(false);
  const [selectedAgencyId] = useSelectedAgencyID();

  const { agency_id, project_id } = filters;

  useEffect(() => {
    (async () => {
      try {
        setComplete(false);


        // const terms = await withCache(async () => loaderWrap(listTasks()));
        const tasks = await loaderWrap(listTasks({
          agency_id: selectedAgencyId,
          ...filters
        }));
        // console.log(tasks)
        tasks.sort((a, b) => a.date_max > b.date_max);
        setTasks(tasks);
        setComplete(true);
      } catch (e) {
        notifyError(e);
      }
    })();
  }, [counter, agency_id, project_id, selectedAgencyId]);

  const refresh = useCallback((taskId) => {
    invalidateTaskCache()
    if (taskId) {
      (async () => {
        const { item } = await loaderWrap(getTask(taskId));
        setTasks((prev) => {
          const idx = prev.findIndex((t) => t.id === taskId);
          if (idx >= 0) {
            prev[idx] = item;
          }
          return [...prev];
        });
      })();
    } else {
      invalidate();
    }
  }, [invalidate]);

  return [tasks, refresh, complete];
}

export function useTask(id, { parent, type } = {}) {
  const [{ task, changes }, setTask] = useState({ item: null, changes: [] });

  const [counter, invalidate] = useInvalidationService(INVALIDATION_KEY);

  useEffect(() => {
    (async () => {
      if (!id) {
        setTask({ item: getDefaults() });
        return;
      }

      const { item, changes } = await getItem(id);
      if (item && !item.id) {
        if (parent) {
          item.parent_task_id = parent;
        }
        if (type) {
          item.type = type;
        }
      }
      setTask({ task: item, changes });
    })();
  }, [id, counter, parent, type]);

  const saveTask = useCallback(async (item) => {
    if (!item) {
      throw new Error('No task');
    }
    let out;
    if (item.id) {
      out = await loaderWrap(updateTask(item));
    } else {
      out = await loaderWrap(createTask(item));
    }
    setTask(out);
    invalidate(id);
    return out;
  }, [id, invalidate]);

  const deleteTask = useCallback(async () => {
    await loaderWrap(removeTask(id));
    invalidate(id);
  }, [id, invalidate]);

  return {
    task,
    changes,
    reloadTask: () => invalidate(id),
    isNewTask: !task?.id,
    setTask,
    saveTask,
    deleteTask,
  };
}

export function taskValidator(values, name = undefined) {
  const rules = {
    name: [isRequired],
    description: [],
  };
  if (name) {
    const err = validate(values[name], ...rules[name] || []);
    return { [name]: err || null };
  }
  return validateAll(values, rules);
}

// export function useTaskForm(id) {
//   const { task, saveTask, deleteTask } = useTask(id);

//   const {
//     register,
//     handleSubmit,
//     errors, isValid,
//     setValues,
//   } = useForm({
//     values: {
//       ...getDefaults(),
//     },
//     validator: taskValidator,
//     // onChange: (name, value, newValues, formState) => {
//     //   if (name === 'prestation_id' && !formState.name) {
//     //     const prestation = formState.prestations.find((p) => p.id === value);
//     //     if (prestation) {
//     //       newValues.name = prestation.name;
//     //       newValues.description = prestation.description;
//     //     }
//     //   }
//     //   return newValues;
//     // }
//     // reValidateMode: "onChange"
//   });

//   useEffect(() => {
//     if (task) setValues(task);
//   }, [task]);

//   return {
//     task,
//     register,
//     errors,
//     isValid,
//     // rules,

//     handleSubmit,
//     saveTask,
//     deleteTask,
//   };
// }



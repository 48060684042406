import clsx from 'clsx';
import { set } from 'date-fns';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

function getTargetValue(target) {
  switch (target.type) {
    case 'checkbox':
      return !!target.checked;
    case 'number':
      return +target.value || null;
    default:
      return target.value;
  }
}

function isValid(errors) {
  return Object.values(errors).findIndex((e) => !!e) === -1;
}

// Flatify object
function flatify(obj, prefix = '') {
  const out = {};
  Object.entries(obj || {}).forEach(([key, value]) => {
    if (typeof value === 'object') {
      out[`${prefix}${key}`] = value;
      Object.assign(out, flatify(value, `${prefix}${key}.`));
    } else {
      out[`${prefix}${key}`] = value;
    }
  });
  return out;
}

function unflatify(obj) {
  const out = {};
  Object.entries(obj || {}).forEach(([key, value]) => {
    const parts = key.split('.');
    let o = out;
    for (let i = 0; i < parts.length - 1; i += 1) {
      if (!o[parts[i]]) {
        o[parts[i]] = {};
      }
      o = o[parts[i]];
    }
    o[parts[parts.length - 1]] = value;
  });
  return out;
}

export function useForm({ values, onChange, validator }) {
  const [formState, setFormState] = useState({
    values: flatify(values),
    errors: {},
    isValid: false,
  });

  // useEffect(() => {
  //   const errors = validator ? validator(values) : {};
  //   // setFormState({
  //   //   values,
  //   //   errors,
  //   //   isValid: isValid(errors),
  //   // });
  //   console.log(values);
  // }, [values]);


  const onChangeHandler = useCallback((name, value) => {
    const { values, errors } = formState;
    let newValues = { ...values, [name]: value };

    if (onChange) {
      const v = onChange(name, value, { ...newValues }, formState);
      if (v) {
        newValues = v;
      }
    }

    const newErrors = validator ? {
      ...errors,
      ...validator(newValues, name),
    } : errors;

    setFormState({
      ...formState,
      isValid: isValid(newErrors),
      errors: newErrors,
      values: newValues,
    });
  }, []);

  return useMemo(() => ({
    // register: (name, { className, dataType } = {}) => useMemo(() => ({
    //   name,
    //   className: clsx(className, formState.errors[name] && 'is-invalid'),
    //   value: formState.values[name],
    //   dataType,
    //   onChange: onChangeHandler,
    // }), [name, className, dataType, formState.errors[name], formState.values[name]]),
    register: (name, { className, getValueHandler } = {}) => {
      return ({
        name,
        className: clsx(className, formState.errors[name] && 'is-invalid'),
        value: formState.values[name],
        onChange: (e) => {
          onChangeHandler(name, getValueHandler ? getValueHandler(e) : getTargetValue(e.target));
        },
      })
    },
    handleSubmit: (handler) => (e) => {
      e.preventDefault();
      handler(unflatify(formState.values));
    },
    setValues: (newValues) => {
      const values = flatify(newValues);
      const errors = validator ? validator(values) : {};
      // console.log('setVaoues');

      setFormState({
        values: flatify(values),
        errors,
        isValid: isValid(errors),
      });
    },
    // getValues: () => unflatify(formState.values),
    values: unflatify(formState.values),
    errors: formState.errors,
    isValid: formState.isValid,
  }), [formState]);
}



export default function TaskDurationUnitLabel({ value, plural }) {
  switch (value) {
    case 'J':
    case 'd':
      return 'jour' + (plural ? 's' : '');
    case 'H':
    case 'h':
      return 'heure' + (plural ? '' : '');
    case 'M':
      return 'minute' + (plural ? 's' : '');
    default:
      return value;
  }
}
import { mdiAccountCancelOutline, mdiMinusBoxOutline, mdiMinusCircleOutline, mdiPlusBoxOutline, mdiPlusCircleOutline, mdiSelectGroup, mdiStoreOutline } from "@mdi/js";
import Icon from "@mdi/react";
import PrestationIcon from "../prestations/PrestationIcon";
import { Badge, Button, Divider, Flex, Popover, Progress, Space, Tag, Tooltip } from "antd";
import ResourceIcon from "../resources/ResourceIcon";
import { formatDate, formatDateTime, formatNumber } from "../../lib/format";
import { useEffect, useMemo } from "react";
import { slotStatusesIndex, useSlot, useSlots } from "../../hooks/slots";
import { useNavigate } from "react-router-dom";
import ProjectMessagesView from "../projects/ProjectMessagesView";
import { useAuth } from "../../hooks/auth";
import { CheckCircleFilled, CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import SlotResourceItemRenderer from "./PlanningSlotResourceItemRenderer";
import SlotTaskItemRenderer from "./PlanningSlotTaskItemRenderer";
import SlotProjectItemRenderer from "./PlanningSlotProjectItemRenderer";




function SlotAgencyItemRenderer({ name, color }) {
  return (
    <div className="slot-cell" style={{ color }}>
      <Icon path={mdiStoreOutline} size={0.8} className="mr-2" />
      <span className="slot-text">{name}</span>
      {/* <CompletionLabel remaining={3} /> */}
    </div>
  );
}

function DateButton({ date, color, onSelectDate }) {
  return <Button type="text"
    onClick={() => onSelectDate(date)} >
    <b style={{ color }}>{formatDate(date)}</b>
  </Button>
}







function ExpanderButton({ onClick, item, color }) {
  if (!item.hasChildren) {
    return null
  }

  return <div style={{ width: 16, cursor: 'pointer' }}>
    <Icon path={item.expanded ? mdiMinusCircleOutline : mdiPlusCircleOutline}
      size={0.6} color={color || '#aaa'} style={{ opacity: item.expanded ? .5 : 1 }} onClick={onClick} />
  </div>


  // if (item.expanded) {
  //   return <MinusSquareOutlined
  //     onClick={onClick} />
  // } else {
  //   return <PlusSquareOutlined
  //     onClick={onClick} />
  // }
}

export default function PlanningSlotHeaderItemRenderer({
  schedulerData,
  item,
  object,
  slotClickedFunc,
  toggleExpandFunc,
  width,
  className,
  // resourcesIdx,

  metrics,

  // remainingIndicator,

  onClick,
  onChange,
  onSelectDate,
  addSearchFilter,

}) {

  const isAgency = item.slotId.startsWith('agcy');
  const isProject = item.slotId.startsWith('prj');
  const isTask = item.slotId.startsWith('task');
  const isResource = item.slotId.startsWith('res');

  const toggle = () => {
    toggleExpandFunc && toggleExpandFunc(schedulerData, item.slotId)
  }

  return (
    <div className={className} style={{ width: width, textAlign: 'left', paddingLeft: (item.indent + 0.2) + 'em', display: 'flex' }}>
      <ExpanderButton onClick={toggle} item={item} />
      {(() => {
        switch (true) {
          case isAgency:
            return <SlotAgencyItemRenderer name={item.slotName} color={item.styles.color} />
          case isProject:
            return <SlotProjectItemRenderer
              name={item.slotName}
              project={object}
              metrics={metrics}
              color={item.styles.color}
              onSelectDate={onSelectDate}
              onChange={onChange}
              // remainingIndicator={remainingIndicator}
              addSearchFilter={addSearchFilter}
              toggleExpandFunc={toggle}
            />
          case isTask:
            return <SlotTaskItemRenderer name={item.slotName}
              task={object}
              metrics={metrics}
              onClick={onClick}
              onSelectDate={onSelectDate}
              // remainingIndicator={remainingIndicator}
              addSearchFilter={addSearchFilter}
              onChange={onChange}
            />
          case isResource:
            return <SlotResourceItemRenderer name={item.slotName}
              resource={object}
              metrics={metrics}
              addSearchFilter={addSearchFilter}
              onChange={onChange}
            />
          default:
            return <div className="slot-cell">
              <Icon path={mdiAccountCancelOutline} size={0.8} className="mr-2" />
              <span className="slot-text">{item.slotName}</span>
            </div>
        }
      })()}
      {/* <div style={{ flex: 1 }}>
      </div> */}
    </div>
  );
}

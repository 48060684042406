import { Badge, Descriptions, Flex, Rate } from "antd";
import SlotStatusLabel from "../slots/SlotStatusLabel";
import { formatDuration } from "../../lib/format";
import { useSlot } from "../../hooks/slots";
import { useEffect, useState } from "react";
import { getSlot, getSlotRaw } from "../../services/api/slots";
import Document from "../documents/Document";
import { CheckCircleTwoTone } from "@ant-design/icons";


export function PlanningSlotComplete({ slot, task, project, resource }) {
  slot = { ...slot }
  // slot.real_duration = slot.real_duration + 2
  const timeDiff = slot.real_duration - slot.duration
  const timeDiffLabel = (timeDiff > 0 ? '+' : '-') + formatDuration(timeDiff * 60)

  const [{ attachments, signature }, setState] = useState({})

  useEffect(() => {
    getSlotRaw(slot.id).then(({ attachments, signature }) => {
      // Fix SVG signature
      signature = (signature || '').replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg" version="1.1"')
      setState({ attachments, signature })
    })
  }, [slot.id])

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Rate disabled value={Math.ceil(task?.expected_level * 3 / 5)} style={{ fontSize: '0.8em' }} count={3} />
        <SlotStatusLabel status={slot?.status} />
      </Flex>
      {task?.description && <div className="form-text" style={{ maxHeight: 200, marginBottom: 10, overflow: 'auto' }}>
        {task.description.split('\n').map((line, i) => <span key={i}>{line}<br /></span>)}
      </div>}
      <Descriptions column={1} size={"small"}>
        <Descriptions.Item label="Durée prévu">{formatDuration(slot.duration * 60)}</Descriptions.Item>
        <Descriptions.Item label="Durée réelle">
          <Flex align="center" gap="small">
            <span>{formatDuration(slot.real_duration * 60)}</span>
            {Math.abs(timeDiff) > 0
              ? <Badge count={timeDiffLabel} style={{ backgroundColor: timeDiff > 0 ? '#f56c6c' : '#87d068', marginLeft: 10 }} />
              : <CheckCircleTwoTone twoToneColor="#52c41a" />
            }
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label="Note">
          {slot.close_details?.note}
        </Descriptions.Item>
        <Descriptions.Item label="Documents">
          <Flex vertical gap="small">
            {attachments?.map((a, i) => <div key={i}>
              <Document doc={a} preview />
            </div>)}
          </Flex>
        </Descriptions.Item>
        <Descriptions.Item label="Signature">
          {signature ? <img src={"data:image/svg+xml;utf8," + (signature)} alt="signature" style={{
            width: '100%',
            maxWidth: 200,
            height: 'auto',
            padding: 10,
            backgroundColor: 'rgba(0,0,0,0.05)',
            borderRadius: 5,
          }} /> : <span>Non signé</span>}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}
import { useEffect, useState } from "react";
import MessagesView from "../messages/MessagesView";
import { } from "../../services/loader";
import { addResourceComment, listResourceComments } from "../../services/api/resources";


export default function ResourceMessagesView({ resourceId }) {
  const [messages, setMessages] = useState([])
  const [reloadCounter, setReload] = useState(0)

  useEffect(() => {
    if (!resourceId) return
    (async () => {
      const messages = await (listResourceComments(resourceId))
      setMessages(messages)
      // setMessages([
      //   { id: 1, content: 'Hello', date: new Date(), by: "Michel" },
      //   { id: 1, content: 'Hello2', date: new Date(), by: "Paul" },
      // ])
    })()
  }, [reloadCounter, resourceId])

  const onSend = async (content) => {
    if (!resourceId) return
    await addResourceComment(resourceId, content)
    setReload(reloadCounter + 1)
  }

  return <MessagesView messages={messages} onSend={onSend} />
}
import { Checkbox, Flex, Form } from "antd";
import Search from "../search/Search";


export default function ResourceNotificationsForm({ form }) {

  return <>
    <Form.Item label="Affaire">
      <Flex vertical>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "project_comment"]}>
          <Checkbox>Un commentaire a été ajouté</Checkbox>
        </Form.Item>
      </Flex>
    </Form.Item>
    <Form.Item label="Tâches">
      <Flex vertical>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "task_complete"]}>
          <Checkbox>Une tâche est terminée</Checkbox>
        </Form.Item>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "task_comment"]}>
          <Checkbox>Un commentaire a été ajouté</Checkbox>
        </Form.Item>
      </Flex>
    </Form.Item>
    <Form.Item label="Créneau">
      <Flex vertical>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "slot_confirmed"]}>
          <Checkbox>Un créneau est confirmé</Checkbox>
        </Form.Item>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "slot_cancelled"]}>
          <Checkbox>Un créneau est annulé</Checkbox>
        </Form.Item>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "slot_complete"]}>
          <Checkbox>Un créneau est terminé</Checkbox>
        </Form.Item>
        <Form.Item noStyle valuePropName="checked" name={["notifications", "slot_comment"]}>
          <Checkbox>Un commentaire a été ajouté</Checkbox>
        </Form.Item>
      </Flex>
    </Form.Item>

    <Form.Item name={["notifications", "filters"]} label="Filtre" >
      <Search />
    </Form.Item>


  </>
}
export default class EventEmitter {
  constructor() {
    this.listeners = [];
  }

  addListener(listener) {
    this.listeners.push(listener);
    return () => this.removeListener(listener);
  }

  removeListener(listener) {
    const idx = this.listeners.indexOf(listener);
    if (idx > -1) {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    }
  }

  emit(message) {
    for (let i = 0; i < this.listeners.length; i++) {
      this.listeners[i](message);
    }
  }
}

import React, { useCallback, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ID } from '../../components/id/id';
import { taskStatuses, taskTypes, useTasks } from '../../hooks/tasks';
import { useExtendedPersistentSearcher, useSearcher } from '../../hooks/search';
import TaskStatusLabel from '../../components/tasks/TaskStatusLabel';
import { useProjectsLoader } from '../../hooks/projects';
import { Button, Flex, Input, Modal, Popconfirm, Progress, Rate, Space, Table, Upload, message } from 'antd';
import { ImportOutlined, LoadingOutlined, PlusOutlined, ReloadOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { indexBy } from '../../lib/utils';
import TaskTypeLabel from '../../components/tasks/TaskTypeLabel';
import Search from '../../components/search/Search';
import { useAgencies, useSelectedAgencyID } from '../../hooks/agencies';
import { usePrestations } from '../../hooks/prestations';
import { createImportTask, removeTask, startImportTask } from '../../services/api/tasks';
import { loaderWrap } from '../../services/loader';
import { useAuth } from '../../hooks/auth';
import SearchButton from '../../components/search/SearchButton';
import Link from '../../components/link/Link';


export default function TasksList() {
  const [tasks, refreshTasks] = useTasks();
  const { projects, getProject } = useProjectsLoader();
  const [agencies, refreshAgencies] = useAgencies();
  const [prestations, refreshPrestations] = usePrestations();
  const [agencyID] = useSelectedAgencyID();
  const { can } = useAuth();

  // const [filters, setFilters] = useState({});

  const [selectedTasks, setSelectedTasks] = useState([]);

  const displayedTasks = useMemo(() => {
    return tasks.map(t => {
      const project = getProject(t.project_id);
      return {
        ...t,
        project_name: project ? project.name : 'Inconnu',
        agency_id: project ? project.agency_id : null
      }
    })
  }, [tasks, getProject])

  // const filteredList = useMemo(() => {
  //   return displayedTasks.filter(task => {
  //     if (filters.tasks && filters.tasks.length && !filters.tasks.includes(task.id)) return false
  //     if (filters.projects && filters.projects.length && !filters.projects.includes(task.project_id)) return false
  //     if (filters.agencies && filters.agencies.length && !filters.agencies.includes(task.agency_id)) return false
  //     if (filters.prestations && filters.prestations.length && !filters.prestations.includes(task.prestation_id)) return false
  //     if (agencyID && task.agency_id !== agencyID) return false
  //     return true
  //   })
  // }, [displayedTasks, filters])


  const { list: filteredList, search, setSearch, addSearch } = useExtendedPersistentSearcher(
    'TaskList',
    displayedTasks,
    useCallback((task, { orders, projects, thirdparties, agencies, pools, resources, prestations }, fulltextSearch) => {
      // if (tasks && tasks.length && !tasks.includes(task.id)) return false
      if (projects && projects.length && !projects.includes(task.project_id)) return false
      if (agencies && agencies.length && !agencies.includes(task.agency_id)) return false
      if (prestations && prestations.length && !prestations.includes(task.prestation_id)) return false
      if (agencyID && task.agency_id !== agencyID) return false
      return fulltextSearch(`${task.order_number} ${task.name} `)
    }, []),
  );


  const treeList = useMemo(() => {
    if (!filteredList) return []
    const list = filteredList.map(item => ({ ...item }));
    const idx = indexBy(list, "id")
    const agencyIdx = indexBy(agencies, "id")
    const out = []
    list.forEach((item) => {
      const agency = agencyIdx[item.agency_id];
      item.agency_name = agency?.name;
      if (item.parent_task_id) {
        const parent = idx[item.parent_task_id]
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(item)
          return
        }
      }
      out.push(item)
    });
    return out;
  }, [filteredList, agencies]);

  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };


  const onDeleteSelected = async () => {
    for (const id of selectedTasks) {
      await loaderWrap(removeTask(id));
    }
    setSelectedTasks([]);
    refreshTasks();
  }


  return (
    <>
      <div className="box">
        <Flex gap="small" align='center' >
          {/* <Input size="large" placeholder="Rechercher une tâche..."
            prefix={<SearchOutlined />}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
          <Search
            projects={projects}
            agencies={agencies}
            prestations={prestations}
            tasks={tasks}
            values={search}
            onChange={values => setSearch(values)} />

          {/* <Button type="primary" icon={<UploadOutlined />} onClick={() => startImport()}  >Importer</Button> */}

          {can('task.create') && <Button type="primary" onClick={() => open("*")} >Créer une tâche</Button>}
          {can('task.delete') && selectedTasks.length > 0 ?

            <Popconfirm
              title={selectedTasks.length > 1
                ? `Supprimer les ${selectedTasks.length} tâches sélectionnées ?`
                : "Supprimer la tâche sélectionnée ?"}
              description="Cette action ne peut pas être annulée."
              onConfirm={() => onDeleteSelected()}

              okText="Oui"
              cancelText="Non"
            >
              <Button type="primary" danger onClick={() => { }} >Supprimer</Button>
            </Popconfirm> : null}
          <Button onClick={() => refreshTasks()} icon={<ReloadOutlined />} />
        </Flex>
      </div >
      <div className="box-paddingless">

        <Table
          dataSource={treeList}
          expandable={{ defaultExpandAllRows: true }}
          rowKey={(record) => record.id}
          pagination={
            {
              position: ['bottomCenter'],
            }
          }
          rowSelection={{
            type: 'checkbox',
            getCheckboxProps: (record) => ({
              disabled: !!record.ref,
            }),
            onChange: (selectedRowKeys) => {
              setSelectedTasks(selectedRowKeys)
              // console.log(`selectedRowKeys: ${selectedRowKeys}`);
            }
          }}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => open(record.id), // click row
          //   };
          // }}
          columns={[
            {
              title: 'Nom',
              dataIndex: 'name',
              key: 'name',
              sorter: (a, b) => String(a.name).localeCompare(b.name),
              render: (text, task) => <Link to={`/tasks/${task.id}`}>{text}</Link>,
            },
            {
              title: 'Affaire',
              dataIndex: 'project_name',
              key: 'project_name',
              sorter: (a, b) => String(a.project_name).localeCompare(b.project_name),
              render: (text, record) => <SearchButton
                type="projects"
                value={record.project_id}
                handler={addSearch}>
                {text}
              </SearchButton>,
            },
            {
              title: 'Commande',
              dataIndex: 'order_number',
              key: 'order_number',
              sorter: (a, b) => String(a.order_number).localeCompare(b.order_number),
              render: (text, record) =>
                <SearchButton
                  type="text"
                  value={record.order_number}
                  handler={addSearch}>
                  <Link to={`/orders/order-${record.order_number}`}>{text}</Link>
                </SearchButton>,
            },
            {
              title: 'Agence',
              dataIndex: 'agency_name',
              key: 'agency_name',
              sorter: (a, b) => String(a.agency_name).localeCompare(b.agency_name),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (text, record) => {
                let progress = 0;
                if (record.status === 'inplanning') {
                  progress = Math.round(record.planned_percent)
                } else if (record.status === 'planned') {
                  progress = Math.round(record.complete_percent)
                }
                return <Space direction='vertical'>
                  <TaskStatusLabel value={record.status} progress={progress != null && record.status === 'inplanning'
                    ? progress : undefined} />
                </Space>
              },
              filters: taskStatuses.map(({ label, value }) => ({ text: label, value })),
              defaultFilteredValue: taskStatuses.map(({ value }) => value).filter(value => value !== 'complete'),
              onFilter: (value, record) => record.status === value,
            },
            {
              title: 'Type',
              dataIndex: 'type',
              key: 'type',
              render: (text, record) => <TaskTypeLabel value={record.type} />,
              filters: taskTypes.map(({ label, value }) => ({ text: label, value })),
              onFilter: (value, record) => record.type === value,
            },
            {
              title: 'Compétences',
              dataIndex: 'expected_level',
              key: 'expected_level',
              sorter: (a, b) => a.expected_level - b.expected_level,
              render: (text, record) => {
                return <Rate value={Math.ceil(record.expected_level * 3 / 5)} count={3} disabled />
              },
            }
          ]}
        />

      </div>
      {/* <Modal title="Importer des tâches"
        centered
        open={!!uploadID}
        onOk={() => { }} onCancel={() => { }}>
        <Space direction="vertical">
          <Upload onChange={onUploadChange } action={}>
            <Button icon={<UploadOutlined />} >Importer un fichier</Button>
          </Upload>
        </Space>
      </Modal> */}
    </>
  );
}


import { useParams } from "react-router-dom";
import PlanningOverview from "../../components/planning/PlanningOverview";







export default function ResourcePlanning() {
  const { resourceId } = useParams();
  return <PlanningOverview resourceId={resourceId} />
}
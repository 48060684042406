import { useParams } from "react-router-dom";
import PlanningOverview from "../../components/planning/PlanningOverview";




export default function ProjectPlanning() {
  const { projectId } = useParams();
  return <PlanningOverview projectId={projectId} />

}
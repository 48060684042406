import { mdiSelectGroup } from "@mdi/js";
import Icon from "@mdi/react";
import { Flex, Tag } from "antd";


export default function ProjectCode({ value }) {
  if (!value) {
    return '';
  }
  const rx = /^([A-Z]+\d{2})(0+)(\d+)(\w+\d+)/
  const [m, toptype, zeros, numbers, subtype] = value.match(rx);
  if (!m) {
    return value
  }
  const space = 2
  return <Tag>
    <Flex align="center" wrap="nowrap" >

      <span style={{ color: 'gray', marginRight: space }}>{toptype}</span>
      <span style={{ color: 'gray', opacity: 0.6, marginRight: space, fontWeight: 100 }}>{zeros}</span>
      <span style={{ color: 'black', marginRight: space, fontWeight: 500 }}>{numbers}</span>
      <span style={{ color: 'gray', fontStyle: 'italic' }}>{subtype}</span>
    </Flex>
  </Tag>
}
import Style from './Loader.module.scss';

export function Loader() {
  return (
    <div className={Style.loader}>
      <div />
      <div />
    </div>
  );
}

import { Button, ConfigProvider, DatePicker, Flex, Form, Input, Menu, Modal, Popconfirm, Popover, Progress, Segmented, Space, Table, Tabs, Tag, TimePicker, Tooltip } from "antd";
import { formatDate, formatDateTime } from "../../lib/format";
import { useProjectsLoader } from "../../hooks/projects";
import { useCallback, useMemo, useState } from "react";
import { indexBy } from "../../lib/utils";
import { usePickingPoolOrders, useSelectedPickingPool, useSelectedPickingPoolID } from "../../hooks/pickingpools";
import Link from "../../components/link/Link";
import { loaderWrap } from "../../services/loader";
import dayjs from "dayjs";
import { useResources } from "../../hooks/resources";
import { PrestationInput } from "../../components/prestations/PrestationInput";
import { useShipments } from "../../hooks/shipments";
import { createShipment, removeShipment, updateShipment } from "../../services/api/shipments";
import { act } from "react";
import ShipmentStatus from "../../components/shipments/ShipmentStatus";
import { mdiAccountPlus, mdiCalendarAlertOutline, mdiCalendarClock, mdiCheckUnderline, mdiPackageVariantRemove, mdiTruckCheckOutline, mdiTruckPlusOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { createDelivery, updateDelivery } from "../../services/api/deliveries";
import { useDeliveries } from "../../hooks/deliveries";
import { del } from "aws-amplify/api";
import DeliveryStatus from "../../components/deliveries/DeliveryStatus";
import { usePrestations } from "../../hooks/prestations";
import { invalidateTaskCache } from "../../services/api/tasks";
import { invalidateSlotCache } from "../../services/api/slots";
import ShipmentMissingProducts from "../../components/shipments/ShipmentMissingProducts";
import { useExtendedPersistentSearcher, useSearchOptionsMemo, useSearcher } from "../../hooks/search";
import ShipmentDeliveryForm from "./ShipmentDeliveryForm";
import ShipmentAddPickingModal from "./ShipmentAddPickingModal";
import { green, blue, yellow, orange, red } from '@ant-design/colors';
import { ReloadOutlined } from "@ant-design/icons";
import Search from "../../components/search/Search";
import { usePersistentState } from "../../hooks/state";
import SearchButton from "../../components/search/SearchButton";
import OrderShippingModeLabel from "../../components/orders/OrderShippingModeLabel";
import { ID } from "../../components/id/id";

function SegmentWeek({ label, week }) {
  if (week) {
    label = `Semaine ${week}`;
  }
  return <div style={{
    // backgroundColor: "green"
  }}>{label}</div>
}

export default function ShipmentsList() {

  const [filterWeek, setFilterWeek] = useState('all');
  // const [pickingPoolId] = useSelectedPickingPoolID();
  // const [pickingPool] = useSelectedPickingPool();
  const [pickingPoolId] = useSelectedPickingPoolID();
  const { pickingPool,
    warehouseId,
    orders: incomingOrders,
    removeOrder: removeOrderFromPool,
    reloadPickingPool
  } = usePickingPoolOrders(pickingPoolId);


  const [shipments, reloadShipments] = useShipments({ warehouseId: warehouseId, onlyActive: true });
  const [deliveries, reloadDeliveries] = useDeliveries({ warehouseId: warehouseId, onlyActive: true });

  const { projects, getProject } = useProjectsLoader();
  const [resources] = useResources();
  const [prestations] = usePrestations();
  const resourcesIdx = useMemo(() => indexBy(resources, 'id'), [resources]);
  const prestationsIdx = useMemo(() => indexBy(prestations, 'id'), [prestations]);


  const [addPickToOrder, setAddPickToOrder] = useState(null);

  // Form to plan a delivery
  const [formPlan] = Form.useForm();
  // const formPlanPrestationId = Form.useWatch("prestation_id", { form: formPlan })
  // const formPlanWhen = Form.useWatch("when", { form: formPlan })
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [modalFormPlanOpen, setModalFormPlanOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const openFormPlan = (shipment, delivery) => {
    setModalFormPlanOpen(true);
    setSelectedPlan({ shipment, delivery });

    const delivery_date = delivery?.delivery_date ? new dayjs(delivery.delivery_date) : new dayjs().add(1, 'day');
    // console.log(ship.delivery_date, new Date(ship.delivery_date));
    formPlan.setFieldsValue({
      when: delivery_date.hour() <= 12 ? 'morning' : 'afternoon',
      delivery_date: delivery_date,
      resource_id: delivery?.resource_id,
      prestation_id: delivery?.prestation_id,
      tracking_number: delivery?.tracking_number
    });
  }
  const onFormPlanOk = () => {
    setSaving(true);
    formPlan.validateFields()
      .then(async ({ tracking_number, resource_id, prestation_id, delivery_date, when, when_custom }) => {
        const { shipment, delivery } = selectedPlan;
        setModalFormPlanOpen(false);
        delivery_date = delivery_date.startOf('day');
        let delivery_date_end = delivery_date.clone()
        switch (when) {
          case 'morning':
            delivery_date = delivery_date.add(8, 'hours');
            delivery_date_end = delivery_date_end.add(12, 'hours');
            break;
          case 'afternoon':
            delivery_date = delivery_date.add(13, 'hours');
            delivery_date_end = delivery_date_end.add(18, 'hours');
            break;
          case 'custom':
            delivery_date = delivery_date.add(when_custom[0].diff(when_custom[1]) / 2, 'milliseconds');
            delivery_date_end = delivery_date_end.add(when_custom[1].diff(when_custom[0]) / 2, 'milliseconds');
            break;
          default:
        }
        // console.log(delivery_date, delivery_date_end);
        // return;
        if (delivery) {
          await loaderWrap(updateDelivery({
            ...delivery,
            tracking_number,
            resource_id,
            prestation_id,
            delivery_date,
            delivery_date_end,
          }));
        } else {
          await loaderWrap(createDelivery({
            tracking_number,
            order_id: shipment.order_id,
            status: 'pending',
            warehouse_id: shipment.warehouse_id,
            shipment_id: shipment.id,
            resource_id,
            prestation_id,
            delivery_date,
            delivery_date_end,

          }));
        }


        reloadShipments();
        reloadDeliveries();
        invalidateTaskCache();
        invalidateSlotCache();
      })
      .finally(() => {
        setSaving(false);
      });
  }

  const onDelete = async (id) => {
    await loaderWrap(removeShipment(id));
    reloadShipments();
    reloadDeliveries();
    reloadPickingPool();
    invalidateTaskCache();
    invalidateSlotCache();
  }

  const onCloseShipment = async (shipment) => {
    await loaderWrap(updateShipment({
      ...shipment,
      status: 'delivered'
    }));
    reloadShipments();
    reloadDeliveries();
    invalidateTaskCache();
    invalidateSlotCache();
  }

  const onCreateShipment = async (order) => {
    const ship = await loaderWrap(createShipment({
      order_id: order.id,
      status: 'pending',
      warehouse_id: warehouseId,
    }))
    invalidateTaskCache();
    reloadShipments();
    reloadPickingPool();
  }

  const onDeleteOrderFromPool = async (orderId) => {
    removeOrderFromPool(orderId, true)
  }


  const shipmentsWithUnpickedOrders = useMemo(() => {
    const out = [...shipments]
    const orderWithShipments = indexBy(out, "order_id")


    incomingOrders.forEach(o => {
      if (!orderWithShipments[o.id]) {
        // Cretate an empty shipment
        out.push({ id: 'noship-' + o.id, order_id: o.id, order: o });
      }
    })

    return out;

  }, [shipments, incomingOrders]);



  const orders = useSearchOptionsMemo(shipmentsWithUnpickedOrders, (s) => {
    return { id: s.order_id, name: s.order?.ref_internal || `Commande n°${s.order?.order_number}` }
  });

  const thirdparties = useSearchOptionsMemo(shipmentsWithUnpickedOrders, (s) => {
    const id = s.order?.third_party_code || s.order?.customer_code
    return {
      id, name: s.order?.third_party_name || s.order?.customer_name || 'Tiers n°' + id
    }
  });

  const usedProjects = useSearchOptionsMemo(shipmentsWithUnpickedOrders, (s) => {
    const id = s.order?.project_id;
    return {
      id, name: getProject(id)?.name || 'Affaire n°' + id
    }
  }, [getProject]);


  const { list, search, setSearch, addSearch } = useExtendedPersistentSearcher(
    "shipments",
    useMemo(() => {
      return shipmentsWithUnpickedOrders.map(s => ({
        ...s,
        third_party_code: s.order?.third_party_code || s.order?.customer_code,
        deliveries: deliveries.filter(d => d.shipment_id === s.id),
        inPicking: s.picking_orders?.filter(po => po.status !== 'finished').length > 0,
      }))
    }, [shipmentsWithUnpickedOrders, deliveries]),
    useCallback(({ order }, { orders, thirdparties, projects }, fulltextSearch) => {
      if (orders && orders.length && !orders.includes(order.id)) return false;
      if (thirdparties && thirdparties.length && !thirdparties.includes(order.third_party_code || order.customer_code)) return false;
      if (projects && projects.length && !projects.includes(order.project_id)) return false;
      return fulltextSearch(`${order.order_number} ${getProject(order.project_id)?.name} ${order.salesman_code}`)
    }, [getProject]),
  );

  const weeks = useMemo(() => {
    const weeks = {};
    list.forEach(s => {
      s.deliveries.forEach(d => {
        const week = dayjs(d.delivery_date).isoWeek();
        if (!weeks[week]) weeks[week] = 0;
        weeks[week]++;
      });

    });
    return weeks;
  }, [list]);



  const finalList = useMemo(() => {
    if (filterWeek === 'all') {
      return list;
    }
    if (filterWeek === 'pending') {
      return list.filter(s => !s.status);
    }
    if (filterWeek === 'unplanned') {
      return list.filter(s => s.deliveries.length === 0);
    }
    if (filterWeek === 'shipped') {
      return list.filter(s => s.status === 'shipped');
    }
    const week = +filterWeek.replace(/^week:/, '');
    return list.filter(s => s.deliveries.find(d => dayjs(d.delivery_date).isoWeek() === week));
  }, [list, filterWeek]);


  const reload = useCallback(() => {
    reloadShipments();
    reloadDeliveries();
    reloadPickingPool();
  }, [reloadShipments, reloadDeliveries, reloadPickingPool]);

  return (
    <>

      <div className="box-paddingless">
        <Flex gap={"small"} style={{ padding: 10 }}>
          {/* <Input size="large" placeholder='Rechercher...' value={search} onChange={(e) => setSearch(e.target.value)} /> */}
          <Search
            name="ShipmentsList"
            orders={orders}
            thirdparties={thirdparties}
            projects={usedProjects}
            values={search} onChange={setSearch} />
          <div>
            <Button title="Rafraichir" onClick={reload} shape="rounded" icon={<ReloadOutlined />} />
          </div>
        </Flex>
        <Flex gap={"small"} justify="space-between" align="center" style={{ padding: 10 }}>
          {/* <ConfigProvider
            theme={{
              components: {
                Segmented: {
                  // trackBg: 'transparent',
                  // itemSelectedBg: blue.primary,
                  // itemSelectedColor: 'white',
                  // trackPadding: -10,
                  // itemColor: 'white',
                }
              }
            }}>
            <Segmented
              size="large"
              value={filterWeek}
              options={[
                { label: <SegmentWeek label="Toutes" />, value: 'all' },
                { label: <SegmentWeek label="Non affectées" />, value: 'pending' },
                { label: <SegmentWeek label="Non plannifiées" />, value: 'unplanned' },
                // { label: <SegmentWeek label="En cours" />, value: 'started' },
                ...Object.entries(weeks)
                  .map(([week, count]) => ({
                    label: <SegmentWeek week={week} count={count} />,
                    value: 'week:' + week
                  }))
              ]} onChange={(v) => setFilterWeek(v)} />


          </ConfigProvider> */}


        </Flex>
        <Menu
          style={{ flex: 1 }}
          mode="horizontal"
          selectedKeys={[filterWeek]}
          onClick={({ key }) => setFilterWeek(key)}
          items={
            [
              { label: <SegmentWeek label="Toutes" />, key: 'all' },
              { label: <SegmentWeek label="Non affectées" />, key: 'pending' },
              { label: <SegmentWeek label="Non plannifiées" />, key: 'unplanned' },
              { label: <SegmentWeek label="Expédiés" />, key: 'shipped' },

              // { label: <SegmentWeek label="En cours" />, key: 'started' },
              ...Object.entries(weeks)
                .map(([week, count]) => ({
                  label: <SegmentWeek week={week} count={count} />,
                  key: 'week:' + week
                }))
            ]
          }
        />
        <Table
          // bordered
          dataSource={finalList}
          rowKey="id"
          size='middle'
          pagination={
            {
              position: ['bottomCenter'],
            }
          }
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: () => openSetResource(record.id), // click row
          //   };
          // }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              width: 100,
              render: (id, { status }) => status && <ID value={id} /> // <Link to={`/picking/${id}`}>{id.replace(/^pickingorder-.{4}/, '')}</Link>
              // sorter: (a, b) => String(a.code).localeCompare(b.code),
            },
            {
              title: 'Commande',
              dataIndex: ['order', 'order_number'],
              key: 'order_number',
              width: 80,
              render: (order_number, o) => <SearchButton
                type="orders" value={o.order.id} handler={addSearch}>
                <Link to={`/orders/${order_number}`}>{order_number}</Link>
              </SearchButton>
            },
            {
              title: 'Affaire',
              dataIndex: ['order', 'project_id'],
              key: 'project_id',
              render: (project_id) => {
                const project = getProject(project_id);
                return <SearchButton type="projects" value={project_id} handler={addSearch}>
                  <div>{project && project.name}</div>

                </SearchButton>
              }
            },
            {
              title: 'Tiers',
              dataIndex: 'third_party_code',
              key: 'third_party_code',
              render: (third_party_code, s) => {
                return <SearchButton type="thirdparties" value={third_party_code} handler={addSearch} >
                  {third_party_code}
                </SearchButton>
              }
            },
            {
              title: 'Date de livraison souhaitée',
              dataIndex: ['order', 'shipping_date'],
              key: 'shipping_date',
              width: 120,
              render: (date) => formatDate(date),
              defaultSortOrder: "ascend",
              sorter: (a, b) => new Date(a.order?.shipping_date) - new Date(b.order?.shipping_date),
            },
            {
              title: 'État',
              dataIndex: 'completion_rate',
              width: 100,
              key: 'completion_rate',
              render: (completion_rate, item) => {
                if (completion_rate === 1) {
                  return <Tag color="green">Complète</Tag>
                }
                return <Popover content={<ShipmentMissingProducts items={item.items} order={item.order} />}>
                  <Progress
                    percent={Math.floor(100 * completion_rate)}
                    strokeColor={completion_rate < 1 ? 'orange' : null}
                    status="normal" />
                </Popover>
              }
            },
            {
              title: 'Date de livraison',
              dataIndex: 'deliveries',
              key: 'deliveries',
              width: 120,
              render: (deliveries, ship) => {
                return <Space direction="vertical">
                  {deliveries?.map(d => <Tooltip
                    title={<div>
                      <div>Livraison prévue le <b>{formatDate(d.delivery_date)}</b></div>
                      <div><b>{prestationsIdx[d.prestation_id]?.name}</b> par <b>{resourcesIdx[d.resource_id]?.name}</b></div>

                      <Flex justify="space-between">
                        <DeliveryStatus value={d.status} />
                        <Button type="primary" size="small" onClick={() => openFormPlan(ship, d)}>Modifier</Button>
                      </Flex>

                    </div>}

                    key={d.id}
                  >
                    <div style={{ whiteSpace: 'nowrap', cursor: "pointer" }} onClick={() => openFormPlan(ship, d)}>
                      {formatDateTime(d.delivery_date)}
                    </div></Tooltip>)}
                </Space>
              }
            },
            {
              title: 'Mode d\'expédition',
              dataIndex: ['order', 'shipping_mode'],
              key: 'shipping_mode',
              width: 120,
              render: (mode) => <OrderShippingModeLabel value={mode} />
            },
            // {
            //   title: 'Livré par',
            //   dataIndex: 'delivery_resource_id',
            //   key: 'delivery_resource_id',
            //   width: 120,
            //   render: (resource_id) => resourcesIdx[resource_id]?.name
            // },
            // {
            //   title: 'Début de préparation',
            //   dataIndex: 'started_at',
            //   key: 'started_at',
            //   render: (date) => formatDateTime(date)
            // },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (status) => <ShipmentStatus value={status} />
            },
            {
              title: 'Refs restantes',
              key: 'items_count',
              dataIndex: ['order', 'remaining_items_count'],
            },


            {
              key: 'actions',
              align: "right",
              width: 100,
              render: (record) => {
                if (!record.status) {
                  return <Space.Compact>
                    <Button type="primary" onClick={() => onCreateShipment(record.order)}>Créer l'expédition</Button>
                    <Popconfirm title="Vous êtes sur le point de retirer cette commande du pool de préparation. Continuer ?"
                      okButtonProps={{ danger: true }}
                      okText="Oui, supprimer" onConfirm={() => onDeleteOrderFromPool(record.order.id)}>
                      <Button danger>
                        <Icon path={mdiPackageVariantRemove} size={0.8} />
                      </Button>
                    </Popconfirm>
                  </Space.Compact>
                }
                return <Space.Compact>

                  {record.deliveries?.length
                    ?
                    <Tooltip title="Ajouter une livraison">
                      <Button onClick={() => openFormPlan(record, null)}>
                        <Icon path={mdiTruckPlusOutline} size={0.8} />
                      </Button>
                    </Tooltip>
                    :
                    <>
                      <Tooltip title="Planifier">
                        <Button type="primary" onClick={() => openFormPlan(record, null)}>
                          <Icon path={mdiCalendarClock} size={0.8} />
                        </Button>
                      </Tooltip>
                      {!record.inPicking && record.picking_orders?.length > 0 &&
                        <ConfigProvider
                          theme={{
                            components: {
                              Button: {
                                defaultBg: green.primary, // green
                                defaultColor: 'white',
                                defaultBorderColor: green.primary,
                                defaultActiveBg: green.primary,
                                defaultActiveColor: 'white',
                                defaultHoverBg: green[4],
                                defaultHoverColor: 'white',
                                defaultHoverBorderColor: green[5],
                              },
                            }
                          }}
                        >
                          <Tooltip title="Marquer comme terminé">
                            <Button onClick={() => onCloseShipment(record)}>
                              <Icon path={mdiCheckUnderline} size={0.8} />
                            </Button>
                          </Tooltip>
                        </ConfigProvider>
                      }
                    </>}
                  <Tooltip title="Ajouter un préparateur">
                    <Button><Icon path={mdiAccountPlus} size={0.8} onClick={() => setAddPickToOrder({ ...record.order, shipment_id: record.id })} /></Button>
                  </Tooltip>
                  <Popconfirm title="Êtes-vous sûr de vouloir annuler cette expédition ?"
                    description={<div>
                      Cette action: <ul>
                        <li>annulera la planification de la livraison </li>
                        <li>supprimera les préparations</li>
                        <li>marquera la commande comme non en attente de préparation</li>
                      </ul>
                    </div>}
                    okButtonProps={{ danger: true }}
                    okText="Oui, supprimer" onConfirm={() => onDelete(record.id)}>
                    <Button type="primary" danger>
                      <Icon path={mdiPackageVariantRemove} size={0.8} />
                    </Button>
                  </Popconfirm>
                </Space.Compact>
              }
            }

          ]}
        />

        <ShipmentAddPickingModal
          warehouseId={warehouseId}
          order={addPickToOrder}
          onFinished={() => {
            setAddPickToOrder(null);
            reloadShipments();
          }} />

        <Modal title="Planification de la livraison"
          open={modalFormPlanOpen}
          maskClosable={false}
          onOk={onFormPlanOk} onCancel={() => setModalFormPlanOpen(false)}>
          <p>
            Planification de la livraison de la commande <b>{selectedPlan?.shipment?.order?.order_number}</b>
          </p>
          <ShipmentDeliveryForm form={formPlan} delivery={selectedPlan?.delivery} />
        </Modal>

      </div>
    </>);
}
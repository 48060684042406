import { useCallback, useEffect, useMemo, useState } from "react";
import { useTasks } from "../../hooks/tasks";
import { useResources } from "../../hooks/resources";
import { useProjectsLoader } from "../../hooks/projects";
import { usePrestations } from "../../hooks/prestations";
import { useAgencies, useSelectedAgencyID } from "../../hooks/agencies";
import { indexBy, indexMultipleBy } from "../../lib/utils";
import { slotStatuses, useSlot, useSlotsCollection, useSlotsValidator } from "../../hooks/slots";
import { roundToEndWorkTimeIfNeeded, roundToStartWorkTimeIfNeeded } from "../../components/planning/utils";
import { DATETIME_FORMAT } from "../../components/scheduler/Scheduler";
import dayjs from "dayjs";
import { useResourcePrestations } from "../../hooks/resource-prestations";
import { sanitizeString } from "../../hooks/search";
import { duration } from "moment";
import ProjectShortCode from "../../components/projects/ProjectShortCode";
import { debugTraceHookChanges } from "../../lib/debug";


function applyEventLocks(events) {
  return events.map(event => {
    const lock = !!event.milestone || event.slot?.status === 'canceled' || event.slot?.status === 'done' || !!event.slot?.ref;
    return {
      ...event,
      movable: !lock,
      startResizable: !lock,
      endResizable: !lock,
    }
  });
}


export function usePlanningDatastore() {
  const [tasks, refreshTasks, tasksComplete] = useTasks()
  const [users, refreshResouces, resourcesComplete] = useResources()
  const { projects, getProject, refresh: refreshProjects, complete: projectsComplete } = useProjectsLoader()
  const [prestations, refreshPrestations, prestationsComplete] = usePrestations()
  const [resourcePrestations, refreshResourcePrestations, resourcePrestationsComplete] = useResourcePrestations()
  const [agencies, refreshAgencies, agenciesComplete] = useAgencies()
  // const agencies = useMemo(() => [], []), agenciesComplete = true;


  const { slots, addSlot, deleteSlot, updateSlot, loadSlots, lastSlotChange } = useSlotsCollection()
  // const { saveSlot } = useSlot()


  const usersIdx = useMemo(() => {
    return indexBy(users || [], "id") || {};
  }, [users])

  // const projectsIdx = useMemo(() => {
  //   return indexBy(projects || [], "id") || {};
  // }, [projects])

  const prestationsIdx = useMemo(() => {
    return indexBy(prestations || [], "id") || {};
  }, [prestations])

  const slotsIdx = useMemo(() => {
    return indexBy(slots || [], "id") || {};
  }, [slots])

  const resourcesPrestationsIdx = useMemo(() => {
    return indexBy(resourcePrestations || [], ({ resource_id, prestation_id }) => [resource_id, prestation_id]) || {};
  }, [resourcePrestations])

  const tasksEnriched = useMemo(() => {
    return tasks.map(task => ({
      ...task,
      project: getProject(task.project_id, false),
      prestation: prestationsIdx[task.prestation_id],
    }))
  }, [tasks, getProject, prestationsIdx])

  const tasksIdx = useMemo(() => {
    return indexBy(tasksEnriched || [], "id") || {};
  }, [tasksEnriched])


  useEffect(() => {
    console.log('refreshTasks', lastSlotChange)
    if (!lastSlotChange) return;
    refreshTasks(lastSlotChange.slot.task_id)
  }, [refreshTasks, lastSlotChange])



  return {
    complete: tasksComplete && resourcesComplete && projectsComplete && prestationsComplete && resourcePrestationsComplete && agenciesComplete,

    tasks: tasksEnriched,
    tasksIdx,
    getTask: useCallback((id) => tasksIdx[id], [tasksIdx]),
    users,
    usersIdx,
    getUser: useCallback((id) => usersIdx[id], [usersIdx]),
    projects,
    // projectsIdx,
    getProject,
    prestations,
    prestationsIdx,
    getPrestation: useCallback((id) => prestationsIdx[id], [prestationsIdx]),
    resourcePrestations,
    getResourcePrestation: (resource_id, prestation_id) => resourcesPrestationsIdx[[resource_id, prestation_id]],

    agencies,
    slots,
    slotsIdx,

    getSlot: useCallback((id) => slotsIdx[id], [slotsIdx]),

    addSlot,
    deleteSlot,
    updateSlot,
    loadSlots,

    refreshTasks: () => {
      refreshTasks()
    },
    refreshDatastore: () => {
      refreshTasks()
      refreshResouces()
      refreshProjects()
      refreshPrestations()
      refreshResourcePrestations()
    },

    // saveSlot,
  }

}

export function useFilteredDatastore(datastore, filters) {
  const { complete, tasks, projects, prestations, resourcePrestations, agencies, slots, users, getTask } = datastore;

  const [agencyId] = useSelectedAgencyID()

  // const projectTypeByProjectID = useMemo(() => {
  //   return projects.reduce((acc, project) => {
  //     acc[project.id] = 'projectType-' + project.code.slice(1, 4)
  //     return acc;
  //   }, {})
  // }, [projects])

  // const projectInvoiceModeByProjectID = useMemo(() => {
  //   return projects.reduce((acc, project) => {
  //     acc[project.id] = 'projectInvoicingMode-' + project.code.substr(-5, 3)
  //     return acc;
  //   }, {})
  // }, [projects])

  const filteredProjects = useMemo(() => {
    if (!complete) {
      return [];
    }
    const archives = false; //filters.projectState === 'archive';
    const deadline = filters.deadline ? dayjs().add(+filters.deadline, 'd') : null;
    return projects.filter(project => {
      if (!archives && project.status === 'closed') return false;
      if (archives && project.status !== 'closed') return false;
      if (deadline && !project.expected_end_date) return false;
      if (deadline && project.expected_end_date && dayjs(project.expected_end_date).isAfter(deadline)) return false;
      if (filters.agencies && filters.agencies.length && project.agency_id && !filters.agencies.includes(project.agency_id)) return false;
      if (filters.projects && filters.projects.length && !filters.projects.includes(project.id)) return false;
      if (filters.projectTypes && filters.projectTypes.length && !filters.projectTypes.includes('projectType-' + project.project_type)) return false;
      if (filters.projectInvoicingModes && filters.projectInvoicingModes.length && !filters.projectInvoicingModes.includes('projectInvoicingMode-' + project.project_invoicing_mode)) return false;
      return true;
    });
  }, [complete, projects, filters])

  const filteredProjectsIdx = useMemo(() => {
    return indexBy(filteredProjects, "id") || {};
  }, [filteredProjects])

  const fulltextSearch = useMemo(() => {
    if (!filters.text || !filters.text.length) return () => true;

    const search = sanitizeString(filters.text.join(' ')).split(/\s+/)

    return (item) => {
      const text = sanitizeString((item.name || '') + ' ' + (item.description || ''));
      for (let i = 0; i < search.length; i++)
        if (!text.includes(search[i])) return false;
      return true;
    }
  }, [filters.text])

  return useMemo(() => {
    if (!complete) {
      return { complete: false };
    }
    // const archives = filters.projectState === 'archive';
    // const deadline = filters.deadline ? dayjs().add(+filters.deadline, 'd') : null;

    const filteredDatastore = {
      complete: true,
      tasks: tasks.filter(task => {
        if (!filteredProjectsIdx[task.project_id]) return false;

        if (filters.displayTasks === 'toPlan' && (task.status !== 'unplanned' && task.status !== 'inplanning')) return false;
        if (filters.displayTasks === 'active' && task.status === 'complete') return false;
        // if (filters.projects && filters.projects.length && !filters.projects.includes(task.project_id)) return false;

        if (filters.tasks && filters.tasks.length && !filters.tasks.includes(task.id)) return false;
        if (filters.prestations?.length && !filters.prestations.includes(task.prestation_id)) return false;
        return fulltextSearch(task);
      }),
      projects: filteredProjects,
      prestations: prestations.filter(prestation => {
        if (filters.prestations && filters.prestations.length && !filters.prestations.includes(prestation.id)) return false;
        return true;
      }),
      resourcePrestations: resourcePrestations.filter(resourcePrestation => {
        if (filters.resources && filters.resources.length && !filters.resources.includes(resourcePrestation.resource_id)) return false;
        return true;
      }),
      agencies: agencies.filter(agency => {
        if (agencyId && agencyId !== agency.id) return false;
        if (filters.agencies && filters.agencies.length && !filters.agencies.includes(agency.id)) return false;
        return true;
      }),
      slots: slots.filter(slot => {
        if (slot.project_id && !filteredProjectsIdx[slot.project_id]) return false;
        if (filters.resources && filters.resources.length && !filters.resources.includes(slot.resource_id)) return false;
        // if (filters.projects && filters.projects.length && !filters.projects.includes(slot.project_id)) return false;
        if (filters.tasks && filters.tasks.length && !filters.tasks.includes(slot.task_id)) return false;
        if (filters.prestations?.length && slot.task_id !== 'unavailable' && !filters.prestations.includes(getTask(slot.task_id)?.prestation_id)) return false;
        return true;
      }),
      users: users.filter(user => {
        if (user.hide_in_planning) return false;
        if (filters.agencies && filters.agencies.length && !filters.agencies.includes(user.agency_id)) return false;
        if (filters.resources && filters.resources.length && !filters.resources.includes(user.id)) return false;
        return true;
      }),

      getTask: datastore.getTask,
      getUser: datastore.getUser,
      getProject: datastore.getProject,
      getPrestation: datastore.getPrestation,
      getResourcePrestation: datastore.getResourcePrestation,

    }

    return filteredDatastore;
  }, [
    complete,
    filteredProjects,
    tasks, projects, prestations, resourcePrestations, agencies, slots, agencyId, filters])
}

export function useMetrics(datastore, filters = {}) {

  const {
    tasks, projects, slots,
    getTask, getPrestation, getResourcePrestation
  } = useFilteredDatastore(datastore, filters)

  return useMemo(() => {
    const metrics = {};
    if (!projects || !tasks) return metrics;

    (slots || []).forEach(slot => {
      // if (filters.resources && filters.resources.length && !filters.resources.includes(slot.resource_id)) return;

      const task = getTask(slot.task_id) || { id: slot.task_id };
      const presRes = getResourcePrestation(slot.resource_id, task.prestation_id);
      const pres = getPrestation(task.prestation_id);

      let duration = slot.duration || 0;
      if (task.unit === 'd') {
        duration = Math.round(10 * duration / 8) / 10;
      }
      const price = (presRes?.price || pres?.price || 0);

      const price_total = duration * price;


      metrics[slot.id] = {
        duration,
        price,
        price_total,
      }
    })

    projects.forEach(project => {
      metrics[project.id] = {
        cost: 0,
        duration: 0,

        planned_duration: 0, // in hours
        planned_duration_percent: 0,
        planned_cost: 0,
        complete_cost_percent: 0,

        complete_duration: 0,
        complete_duration_percent: 0,
        complete_cost: 0,
        planned_cost_percent: 0,

        tasks_count: 0,
        tasks_inplanning_count: 0,
        tasks_complete_count: 0,
        tasks_planned_count: 0,
      };
    });

    tasks.forEach(task => {
      metrics[task.id] = task.metrics || {
        duration: task.duration || 0,
        cost: task.cost || 0,

        planned_duration: 0, // in hours
        planned_duration_percent: 0,
        planned_cost: 0,
        complete_cost_percent: 0,

        complete_duration: 0,
        complete_duration_percent: 0,
        complete_cost: 0,
        planned_cost_percent: 0,
      };

      const taskMetrics = metrics[task.id];
      const projectMetrics = metrics[task.project_id];
      projectMetrics.duration += taskMetrics.duration;
      projectMetrics.cost += taskMetrics.cost;
      projectMetrics.planned_duration += taskMetrics.planned_duration;
      projectMetrics.planned_cost += taskMetrics.planned_cost;
      projectMetrics.complete_duration += taskMetrics.complete_duration;
      projectMetrics.complete_cost += taskMetrics.complete_cost;

      projectMetrics.tasks_count++;
      if (task.status === 'complete') {
        projectMetrics.tasks_complete_count++;
      } else if (task.status === 'planned') {
        projectMetrics.tasks_planned_count++;
      } else if (task.status === 'inplanning') {
        projectMetrics.tasks_inplanning_count++;
      }

      metrics[task.project_id] = projectMetrics;
    });


    slots?.forEach(slot => {
      if (slot.status !== 'canceled') {


        metrics[slot.resource_id] = metrics[slot.resource_id] || { duration: 0, price: 0 };
        metrics[slot.resource_id].duration += new dayjs(slot.end).diff(slot.start, 'days') + 1;
      }
    });



    return metrics;

  }, [slots, tasks, projects, getTask, getPrestation, getResourcePrestation])
}


export function useByProjects(datastore, filters = {}) {
  const {
    complete, tasks, projects, slots, agencies,
    getUser, getTask, getPrestation, getResourcePrestation } = useFilteredDatastore(datastore, filters)




  const resources = useMemo(() => {
    if (!complete || !tasks || !projects || !slots || !agencies) {
      return [];
    }

    const resources = [];
    const parentsTaskIds = new Set();

    (tasks || []).forEach(task => {
      if (projects.findIndex(p => p.id === task.project_id) === -1) return;
      const parent_task_id = task.parent_task_id || task.project_id;
      resources.push({
        id: task.id,
        name: task.name,
        parentId: task.parent_task_id || task.project_id,
        expanded: false,
        task,
        // styles: {
        //   backgroundColor: ['#fff', '#333'],
        // }
        // styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
      })
      parentsTaskIds.add(parent_task_id)
    });

    projects.forEach(project => {
      if (!parentsTaskIds.has(project.id)) return;

      resources.push({
        id: project.id,
        name: project.name,
        groupOnly: true,
        parentId: project.agency_id,
        project,
        expanded: false,
        styles: {
          color: '#389e0d',
          backgroundColor: ['#f6ffed', '#FAFFF5'],
        }
        // styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
      })
      parentsTaskIds.add(project.agency_id)
    });

    (agencies || []).forEach(agency => {
      // if (filters.agencies && filters.agencies.length && !filters.agencies.includes(agency.id)) return;
      // if (agencyId && agencyId !== agency.id) return;
      if (!parentsTaskIds.has(agency.id)) return;

      resources.push({
        id: agency.id,
        name: agency.name,
        groupOnly: true,
        expanded: true,
        agency,
        styles: {
          color: '#0958d9',
          backgroundColor: ['#e6f4ff', '#ebf8ff'],
        }
      });
    });

    resources.push({
      id: "unavailable",
      name: "Indisponible",
    })
    return resources;

  }, [tasks, projects, agencies, slots, complete])

  const { events } = useMemo(() => {
    const events = [];
    // const slotMetrics = {};

    if (!complete || !tasks || !projects || !slots || !agencies) {
      return { events };
    }


    const slotStatusIdx = indexBy(slotStatuses, "value");

    // const usersIdx = indexBy(users || [], "id") || {};
    // const tasksIdx = indexBy(tasks || [], "id") || {};

    (projects || []).forEach(project => {
      if (project.expected_start_date) {
        events.push({
          id: project.id + '-start',
          movable: false,
          startResizable: false,
          endResizable: false,
          title: "Début de l'affaire",
          start: roundToStartWorkTimeIfNeeded(project.expected_start_date).format(DATETIME_FORMAT),
          end: roundToStartWorkTimeIfNeeded(project.expected_start_date).format(DATETIME_FORMAT),
          resourceId: project.id,
          milestone: 'start',
        })
      }

      if (project.expected_end_date) {
        events.push({
          id: project.id + '-end',
          movable: false,
          startResizable: false,
          endResizable: false,
          title: "Fin de l'affaire",
          start: roundToEndWorkTimeIfNeeded(project.expected_end_date).format(DATETIME_FORMAT),
          end: roundToEndWorkTimeIfNeeded(project.expected_end_date).format(DATETIME_FORMAT),
          resourceId: project.id,
          milestone: 'end',
        })
      }
    });


    (slots || []).forEach(slot => {
      // if (filters.resources && filters.resources.length && !filters.resources.includes(slot.resource_id)) return;

      const task = getTask(slot.task_id) || { id: slot.task_id };
      const user = getUser(slot.resource_id) || { id: slot.resource_id };
      const presRes = getResourcePrestation(slot.resource_id, task.prestation_id);
      const pres = getPrestation(task.prestation_id);

      const status = slotStatusIdx[slot.status] || {};

      let duration = slot.duration || 0;
      if (task.unit === 'd') {
        duration = Math.round(10 * duration / 8) / 10;
      }
      const price = (presRes?.price || pres?.price || 0);
      const price_total = duration * price;


      events.push({
        id: slot.id,
        start: roundToStartWorkTimeIfNeeded(slot.start).format(DATETIME_FORMAT),
        end: roundToEndWorkTimeIfNeeded(slot.end).format(DATETIME_FORMAT),
        title: (user.name || task?.name) || '',
        resourceId: task.id,
        slot,
        task,
        metrics: {
          duration,
          price,
          price_total,
        },
        bgColor: slot.task_id === 'unavailable' ? '#95a5a6' : status.color,
      })
    })



    return { events: applyEventLocks(events) };
  }, [complete, slots, projects, tasks])


  return { resources, events }
}


export function useByResources(datastore, filters = {}) {

  const {
    complete, slots, agencies, users,
    getUser, getTask, getPrestation, getProject, getResourcePrestation } = useFilteredDatastore(datastore, filters)


  // const [agencyId] = useSelectedAgencyID()

  const resources = useMemo(() => {
    if (!complete) {
      return [];
    }
    const resources = [];

    const usedAgencies = new Set();

    users?.forEach(user => {
      // if (filters.agencies && filters.agencies.length && !filters.agencies.includes(user.agency_id)) return;
      // if (filters.resources && filters.resources.length && !filters.resources.includes(user.id)) return;
      resources.push({
        id: user.id,
        name: user.name,
        parentId: user.agency_id,
        resource: user,
      })
      usedAgencies.add(user.agency_id)
    });

    agencies?.forEach(agency => {
      // if (filters.agencies && filters.agencies.length && !filters.agencies.includes(agency.id)) return;
      // if (agencyId && agencyId !== agency.id) return;
      if (!usedAgencies.has(agency.id)) return;
      resources.push({
        id: agency.id,
        name: agency.name,
        groupOnly: true,
        agency,
      });
    });

    return resources;

  }, [complete, users, agencies])


  const { events } = useMemo(() => {
    const events = [];
    // const metrics = {};

    if (!complete) {
      return { events };
    }

    const slotStatusIdx = indexBy(slotStatuses, "value")


    slots?.forEach(slot => {
      let task = getTask(slot.task_id);
      if (!task && slot.task_id === 'unavailable') {
        task = { id: 'unavailable', name: 'Indisponible' };
      };
      // if (filters.resources && filters.resources.length && !filters.resources.includes(slot.resource_id)) return;
      // if (task && filters.projects && filters.projects.length && !filters.projects.includes(task.project_id)) return;
      // if (filters.tasks && filters.tasks.length && !filters.tasks.includes(slot.task_id)) return;
      // if (task && filters.prestations && filters.prestations.length && !filters.prestations.includes(task.prestation_id)) return;
      const status = slotStatusIdx[slot.status] || {};

      let duration = slot.duration || 0;
      if (task?.unit === 'd') {
        duration = Math.round(10 * duration / 8) / 10;
      }

      const presRes = task ? getResourcePrestation(slot.resource_id, task.prestation_id) : null;
      const pres = task ? getPrestation(task.prestation_id) : null;
      const proj = task ? getProject(task.project_id) : null;

      const price = (presRes?.price || pres?.price || 0);
      const price_total = duration * price;

      events.push({
        id: slot.id,
        start: roundToStartWorkTimeIfNeeded(slot.start).format(DATETIME_FORMAT),
        end: roundToEndWorkTimeIfNeeded(slot.end).format(DATETIME_FORMAT),
        title: <>{proj ? <><ProjectShortCode value={proj.code} /> - {proj?.name}  -  </> : null}{task?.name || ''}</>,
        resourceId: slot.resource_id,
        slot,
        task,
        // movable: !slot.ref,
        // startResizable: !slot.ref,
        // endResizable: !slot.ref,
        metrics: {
          duration,
          price,
          price_total,
        },
        // movable: status.movable && new dayjs(slot.end).isAfter(new dayjs()),
        bgColor: status.color,
      })

    })
    return { events: applyEventLocks(events) };
  }, [complete, slots])

  return { resources, events }

}
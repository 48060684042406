import { mdiAccountHardHat, mdiCar, mdiCart, mdiCubeOutline, mdiExcavator, mdiHardHat, mdiPackageVariantClosed, mdiProgressWrench, mdiTruckDeliveryOutline, mdiWrenchClockOutline } from "@mdi/js";
import Icon from "@mdi/react";


export default function PrestationIcon({ type, size = 1 }) {
  switch (type) {
    case 'service':
      return <Icon path={mdiWrenchClockOutline} size={size} />
    case 'product':
      return <Icon path={mdiCubeOutline} size={size} />
    case 'rental':
      return <Icon path={mdiExcavator} size={size} />
    case 'delivery':
      return <Icon path={mdiTruckDeliveryOutline} size={size} />
    case 'shipping':
      return <Icon path={mdiPackageVariantClosed} size={size} />
    default:
      return <Icon path={mdiProgressWrench} size={size} />
  }

}
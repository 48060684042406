import React, { useCallback, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ID } from '../../components/id/id';
import { resourceTypes, useResources } from '../../hooks/resources';
import { useSearcher } from '../../hooks/search';
import ResourceIcon from '../../components/resources/ResourceIcon';
import ResourceTypeLabel from '../../components/resources/ResourceTypeLabel';
import { Button, Flex, Input, Space, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useAgencies, useSelectedAgencyID } from '../../hooks/agencies';
import { indexBy } from '../../lib/utils';

export default function ResourcesList() {
  const [resources] = useResources();
  const [agencies] = useAgencies();
  const { list, search, setSearch } = useSearcher(
    resources,
    useCallback((o, search, sanitizer) => sanitizer(`${o.name} ${o.code} ${o.commercial_code}`).includes(search), []),
  );
  const navigate = useNavigate();
  const open = (id) => {
    navigate(`./${id}`);
  };

  const [agencyId] = useSelectedAgencyID();

  const items = useMemo(() => {
    const agencyIdx = indexBy(agencies, "id")
    return list
      .filter(({ agency_id }) => !agencyId || agencyId === agency_id)
      .map((item) => {

        const agency = agencyIdx[item.agency_id];
        return {
          ...item,
          agency_name: agency?.name,
        };
      });
  }, [list, agencyId, agencies]);

  return (<>
    <div className="box">
      <Flex gap="small" align='center' >
        <Input size="large" placeholder="Rechercher une ressource..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button type="primary" onClick={() => open("*")}>Créer une ressource</Button>
      </Flex>
    </div>
    <div className="box-paddingless">

      <Table
        dataSource={items}
        rowKey={(record) => record.id}
        pagination={
          {
            position: ['bottomCenter'],
          }
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: () => open(record.id), // click row
          };
        }}
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 100,
            sorter: (a, b) => String(a.code).localeCompare(b.code),
            // render: (text, record) => <ResourceIcon type={record.type} />,
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render: (text, record) => <ResourceTypeLabel type={record.type} />,
            filters: resourceTypes.map(({ label, value }) => ({ text: label, value })),
            onFilter: (value, record) => record.type === value,
          },
          {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => String(a.name).localeCompare(b.name),
          },
          {
            title: 'Actif',
            dataIndex: 'enabled',
            key: 'enabled',
            sorter: (a, b) => String(a.enabled).localeCompare(b.enabled),
            render: (text, record) => record.enabled ? 'Oui' : 'Non',
          },
          {
            title: 'Agence',
            dataIndex: 'agency_name',
            key: 'agency_name',
            sorter: (a, b) => String(a.agency_name).localeCompare(b.agency_name),
          }
        ]} />


    </div>
  </>);
}


import MultiSelectInput, { SELECTED, SUGGESTION, SUGGESTION_ACTIVE } from "../multiselect/MultiSelect";

import clsx from "clsx"
import Style from "./ProjectsInput.module.scss"
import { useEffect, useMemo, useState } from "react";
import { indexBy } from "../../lib/utils";
import { useProjects } from "../../hooks/projects";
import { Select } from "antd";


export function ProjectInput({ value, placeholder, disabled, onChange }) {
  const [items] = useProjects()

  return <Select
    showSearch
    placeholder={placeholder || 'Sélectionner une affaire'}
    optionFilterProp="children"
    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
    filterSort={(optionA, optionB) =>
      (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
    }
    fieldNames={{
      label: "name",
      value: "id",
    }}
    value={value}
    disabled={disabled}
    onChange={onChange}
    options={items}
  />
}
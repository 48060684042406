import EventEmitter from '../lib/event-emitter';

const loaders = new Set();
const emitter = new EventEmitter();

export const CLOSE = 'close';
export const OPEN = 'open';

export function isLoading() {
  return loaders.size > 0;
}

function getLoader() {
  const loader = () => {
    loaders.delete(loader);
    emitter.emit({
      action: CLOSE,
      loader,
    });
  };
  loaders.add(loader);
  emitter.emit({
    action: OPEN,
    loader,
  });
  return loader;
}

export async function loaderWrap(h) {
  const close = getLoader();
  return h
    .finally(() => close());
}

export function addLoaderListener(handler) {
  return emitter.addListener(handler);
}
